import { redirectIfCheckAuthorityForPathUnauthorized } from './routeUtils';

export function getVehiclePageRoutes(store, getRedirectRoute) {
  return {
    path: store.get('fpgConfiguration/isTileMode') ? '/' : '/vehicles',
    name: 'vehicles',
    component: () =>
      import(/* webpackChunkName: "vehicles" */ '@/views/VehiclesView.vue'),
    beforeEnter(to, _from, next) {
      redirectIfCheckAuthorityForPathUnauthorized(
        to,
        next,
        store,
        getRedirectRoute,
        store.get('fpgConfiguration/isTileMode'),
        'vehicles'
      );
    },
    children: [
      {
        path: '',
        name: 'myFleet',
        component: () => import('@/views/vehicles/MyFleet.vue'),
        meta: { authorityPath: 'auth/canViewVehicles' },
        beforeEnter(to, _from, next) {
          redirectIfCheckAuthorityForPathUnauthorized(
            to,
            next,
            store,
            getRedirectRoute,
            store.get('fpgConfiguration/isTileMode')
          );
        },
      },
      {
        path: 'add',
        name: 'addVehicles',
        component: () =>
          import(
            /* webpackChunkName: "addVehicles" */ '@/views/vehicles/AddVehicles.vue'
          ),
        meta: { authorityPath: 'auth/canViewAddVehicles' },
        beforeEnter(to, from, next) {
          redirectIfCheckAuthorityForPathUnauthorized(
            to,
            next,
            store,
            getRedirectRoute,
            store.get('fpgConfiguration/isTileMode')
          );
        },
      },
      {
        path: 'trash',
        name: 'trash',
        component: () =>
          import(
            /* webpackChunkName: "trash" */ '@/views/vehicles/TrashView.vue'
          ),
        meta: { authorityPath: 'auth/canViewTrashCanForOwnerConsumer' },
        beforeEnter(to, from, next) {
          redirectIfCheckAuthorityForPathUnauthorized(
            to,
            next,
            store,
            getRedirectRoute,
            store.get('fpgConfiguration/isTileMode')
          );
        },
      },
      {
        path: store.get('fpgConfiguration/isTileMode')
          ? 'service-providers'
          : 'consent',
        name: 'vehicleConsentComponent',
        component: () =>
          import(
            /* webpackChunkName: "VehicleConsentComponent" */ '@/components/VehicleConsentComponent.vue'
          ),
        meta: { authorityPath: 'auth/canViewOwnerConsumerConsent' },
        beforeEnter(to, from, next) {
          redirectIfCheckAuthorityForPathUnauthorized(
            to,
            next,
            store,
            getRedirectRoute,
            store.get('fpgConfiguration/isTileMode')
          );
        },
      },
    ],
  };
}
