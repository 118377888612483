import Vue from 'vue';

const mutations = {
  SET_PAGINATION_QUERY: (state, payload) => {
    Vue.set(state, 'paginationQuery', payload.data);
  },
  SET_DATE_TO_VIEW_INVOICE: (state, payload) => {
    Vue.set(state, 'dateToViewInvoice', payload);
  },
  SET_STATEMENT_NUMBER: (state, payload) => {
    Vue.set(state, 'statementNumber', payload);
  },
  SET_BILLING_ACTION_IN_PROGRESS: (state, payload) => {
    Vue.set(state, 'billingActionInProgress', payload);
  },
  SET_PAYMENT_CLIENT_SECRET: (state, payload) => {
    Vue.set(state, 'paymentClientSecret', payload);
  },
  SET_PAYMENT_CLIENT: (state, payload) => {
    Vue.set(state, 'paymentClient', payload);
  },
  SET_PAYMENT_ELEMENT_LOADED: (state, payload) => {
    Vue.set(state, 'paymentElementLoaded', payload);
  },
  SET_PAYMENT_ELEMENT: (state, payload) => {
    Vue.set(state, 'paymentElement', payload);
  },
  SET_PAYMENT_ENABLED_CURRENCIES: (state, payload) => {
    Vue.set(state, 'paymentEnabledCurrencies', payload);
  },
};

export { mutations };
