<template>
  <v-layout column>
    <v-flex data-qa="terms-and-conditions-form">
      <h2 class="headline primary--text stepper-header">
        {{ $t('productUpgradeStepper.stepTwoTitle') }}</h2
      >
      <div v-if="products.length > 1">
        <div
          class="products"
          v-for="productFamily in products"
          :key="productFamily.productFamilyId"
        >
          <v-layout row justify-space-between align-center>
            <h3
              class="primary--text font-weight-regular title"
              :data-qa="
                'product-title-' + dashify(productFamily.productFamilyId)
              "
            >
              <strong class="font-weight-bold">{{
                productFamily.productFamilyName
              }}</strong
              >{{ ' '
              }}{{ $t('products.complimentaryProducts.termsAndConditions') }}
            </h3>
            <v-flex class="shrink">
              <v-btn
                :data-qa="
                  'download-terms-and-conditions-' +
                  dashify(productFamily.productFamilyId)
                "
                block
                flat
                medium
                outline
                class="primary--text text-none buttonSize"
                @click="
                  downloadTermsAndConditions(
                    productFamily.productFamilyId,
                    productFamily.productFamilyName
                  )
                "
              >
                {{ $t('onboard.download') }}
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout row align-end>
            <h3
              class="primary--text product-information"
              :data-qa="
                'product-rate-' + dashify(productFamily.productFamilyId)
              "
            >
              {{
                isComplimentary(productFamily)
                  ? $t('products.complimentaryProducts.threeYearRate')
                  : isUpgrade
                  ? defaultPrice
                  : formatPrice(productFamily)
              }}
            </h3>
            <div
              class="expense-rate-note pl-2"
              :data-qa="
                'expense-rate-note-' + dashify(productFamily.productFamilyId)
              "
            >
              {{
                isComplimentary(productFamily)
                  ? $t('products.complimentaryProducts.priceExplanation', {
                      price: formatPrice(productFamily),
                    })
                  : $t('productUpgradeStepper.rateNote')
              }}
            </div>
          </v-layout>
          <v-divider class="terms-and-conditions-form-divider" />
        </div>
      </div>
      <div v-else>
        <h3 class="primary--text product-information" data-qa="product-name">
          {{ getProductHeading(products[0].productFamilyName) }}
        </h3>
        <div>
          <h3 class="primary--text product-information" data-qa="product-rate">
            {{ formatPrice(products[0]) }}
          </h3>
        </div>
        <div class="expense-rate-note">
          {{ $t('productUpgradeStepper.rateNote') }}
        </div>
        <div class="expense-note" style="padding-bottom: 1%">
          {{ $t('productUpgradeStepper.expenseNote') }}
        </div>
      </div>
    </v-flex>
    <div v-if="products.length === 1">
      <div class="product-description" data-qa="product-description">
        {{ $t('products.descriptions.' + products[0].productFamilyId) }}
      </div>
      <v-divider class="terms-and-conditions-form-divider" />
      <section class="content">
        <h3
          class="primary--text product-information"
          data-qa="terms-and-conditions-product-family-name"
        >
          {{ getProductHeading(products[0].productFamilyName) }}
        </h3>
        <p
          class="content-font terms-and-conditions-content"
          data-qa="legal-language"
          ref="termsAndConditionsElement"
          v-html="legalLanguage"
        >
        </p>
      </section>
    </div>
    <v-layout align-self-center justify-center>
      <v-checkbox
        :label="$t('productUpgradeStepper.agreeTermsAndConditionsText')"
        class="content-font agree-checkbox"
        color="primary"
        data-qa="agree-product-terms-and-conditions-checkbox"
        :input-value="checkedAgreeTermsAndConditions"
        @change="setCheckedAgreeTermsAndConditions(!!$event)"
      />
    </v-layout>
    <ErrorDialog
      :error="this.error"
      :show-dialog="showErrorDialog"
      @close-dialog="showErrorDialog = false"
    />
  </v-layout>
</template>

<script>
import coinify from 'coinify';
import dashify from 'dashify';
import ProductService from '@/services/ProductService';
import { mapActions, mapGetters } from 'vuex';
import ErrorDialog from '@/components/utility-components/dialogs/ErrorDialog';
import ErrorMessageService from '@/services/ErrorMessageService';
import { handleTermsAndConditionsDownload } from '@/services/Downloads';

export default {
  name: 'ProductTermsAndConditions',
  components: {
    ErrorDialog,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    isUpgrade: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      legalLanguage: '',
      legalLanguageObjectMap: {},
      productFamilyLegalLanguageMap: {},
      error: {},
      showErrorDialog: false,
    };
  },
  mounted() {
    this.getProductTermsAndConditions(this.products);
    this.setCheckedAgreeTermsAndConditions(false);
  },
  methods: {
    ...mapActions('termsAndConditions', [
      'setCheckedAgreeTermsAndConditions',
      'setFetchedTermsAndConditions',
      'setProductTermsAndConditionsMap',
    ]),
    dashify,
    coinify(currencyCode) {
      if (currencyCode !== null) {
        return coinify.symbol(currencyCode);
      }
    },
    handleError(error) {
      this.error = ErrorMessageService.getErrorMessage(error);
      this.showErrorDialog = true;
    },
    getProductTermsAndConditions(products) {
      this.setFetchedTermsAndConditions(false);
      const promiseArray = products.map((product) =>
        ProductService.getProductTermsAndConditions(
          product.productFamilyId
        ).then((result) => {
          this.legalLanguageObjectMap[
            product.productFamilyId.toLocaleLowerCase()
          ] = result.data;
          if (products.length === 1) {
            this.legalLanguage = result.data.legalLanguage;
          } else {
            this.productFamilyLegalLanguageMap[product.productFamilyId] =
              result.data.legalLanguageByLlid;
          }
        })
      );
      Promise.all(promiseArray)
        .then(() => {
          this.setFetchedTermsAndConditions(true);
          this.setProductTermsAndConditionsMap(this.legalLanguageObjectMap);
        })
        .catch((error) => {
          console.log(error);
          this.handleError(error);
        });
    },
    downloadTermsAndConditions(productFamilyId, productFamilyName) {
      handleTermsAndConditionsDownload(
        this.productFamilyLegalLanguageMap[productFamilyId],
        productFamilyName
      );
    },
    getProductHeading(productFamilyName) {
      return this.$t('signedTermsAndConditions.productTermsAndConditions', {
        productFamilyName: this.$tf(
          'productFamily.' + productFamilyName,
          productFamilyName
        ),
      });
    },
    formatPrice(product) {
      if (this.isComplimentary(product)) {
        return this.$t('products.complimentaryPrice');
      }
      return this.coinify(product.currencyCode) + product.price.toFixed(2);
    },
    isComplimentary(product) {
      return (
        product.currencyCode === undefined ||
        product.currencyCode === null ||
        product.price === undefined ||
        product.price === null
      );
    },
  },
  computed: {
    ...mapGetters('termsAndConditions', ['checkedAgreeTermsAndConditions']),
  },
};
</script>

<style lang="scss" scoped>
.content {
  overflow: auto;
  height: 350px;
}

.terms-and-conditions-form-divider {
  margin-top: 1%;
  margin-bottom: 1%;
}

.stepper-header {
  text-align: center;
  padding-bottom: 2%;
  font-size: 22px !important;
  color: #292e35 !important;
}
</style>
