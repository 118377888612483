import Vue from 'vue';
import VueI18n from 'vue-i18n';
import AccountStaticService from '@/services/AccountStaticService';
import TranslationService from '@/services/TranslationService';
import LocalityService from '@/services/LocalityService';
import { AccountConstants } from './constants';

Vue.use(VueI18n);

let defaultLoaded = false;

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || AccountConstants.DEFAULT_LANGUAGE,
  fallbackLocale:
    process.env.VUE_APP_I18N_FALLBACK_LOCALE ||
    AccountConstants.DEFAULT_LANGUAGE,
  silentTranslationWarn: true,
});

export const supportedLanguages = [];
export const loadedLanguages = [];

export function setI18nLanguage(lang) {
  i18n.locale = lang;
  AccountStaticService.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

export function loadLanguageAsync(lang) {
  if (!defaultLoaded || i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return TranslationService.getTranslations(lang)
        .then((msgs) => {
          i18n.setLocaleMessage(lang, msgs.data);
          loadedLanguages.push(lang);
          defaultLoaded = true;
          return setI18nLanguage(lang);
        })
        .catch((err) => {
          setI18nLanguage(AccountConstants.DEFAULT_LANGUAGE);
          return Promise.reject(
            Error('language not found, falling back to default' + err)
          );
        });
    }
  }
  return Promise.resolve(setI18nLanguage(lang));
}

export async function getSupportedLanguages() {
  if (!supportedLanguages.length) {
    supportedLanguages.push(
      ...(await LocalityService.getLocals()).data.languageList
    );
  }
  return supportedLanguages;
}

export function loadLanguageBasedOnBrowserPreference() {
  const promiseArray = [];
  promiseArray.push(getSupportedLanguages());
  return Promise.all(promiseArray).then((_) => {
    const languageCodes = supportedLanguages.map((lang) => lang.code);
    loadLanguageAsync(getBrowserPreferredLanguage(languageCodes)).catch(
      (err) => {
        console.log(err);
      }
    );
  });
}

export function getBrowserPreferredLanguage(supportedLanguages) {
  let language = 'en-US';
  if (supportedLanguages.includes(window.navigator.language)) {
    language = window.navigator.language;
  } else {
    for (const lang of window.navigator.languages) {
      if (supportedLanguages.includes(lang)) {
        language = lang;
        break;
      }
    }
  }
  return language;
}

const i18nFallbackTranslation = {
  translateOrFallback: function (str, fallbackStr) {
    return i18n.te(str) ? i18n.t(str) : fallbackStr || str;
  },
  install(vue, options) {
    vue.prototype.$tf = (str, fallbackStr) => {
      return i18n.t && i18n.te
        ? this.translateOrFallback(str, fallbackStr)
        : fallbackStr || str;
    };
  },
};
Vue.use(i18nFallbackTranslation);
