<template>
  <div>
    <p data-qa="error-code">
      {{
        $t('error.code', {
          errorCode: this.errorCode,
        })
      }}
    </p>
    <p v-if="errorBucket.errorMessageKey" data-qa="error-message">
      {{ $t(errorBucket.errorMessageKey) }}
    </p>
    <ConfirmationDialog
      :cancel-and-continue="true"
      :cancellation-text="errorBucket.popupDialogActionButtonKey"
      :display-confirm="false"
      :display-divider="false"
      :display-footer="true"
      :enable-dialog="showDialog"
      :message="$t('error.static.commonReasonDialog.description')"
      :title="$t(errorBucket.popupDialogTitleKey)"
      data-qa="error-resolution-dialog"
      data-qa-key="errorBucket"
      height="100px"
      persistent
      @cancelAndContinue="showDialog = false"
    >
      <template v-slot:content>
        <div
          data-qa="confirmation-dialog-content"
          v-html="$t(errorBucket.popupDialogBodyKey)"
        />
      </template>
      <template v-slot:footer>
        <i18n
          class="mb-0 contact-text"
          path="error.static.commonReasonDialog.footer"
          tag="p"
        >
          <a
            slot="email"
            :href="$t('signupSupport.popup.contactDetails[1].href')"
            data-qa="contact-email-hyperlink"
            style="color: #2861a4"
          >
            {{ $t('products.complimentaryProducts.contactEmailText') }}
          </a>
          <a
            slot="phoneNumber"
            :href="
              $tf(
                'tel:' + 'signupSupport.popup.contactDetails[0].contactNumber'
              )
            "
            data-qa="contact-phone-number"
            style="color: #2c64a5"
          >
            {{ $t('signupSupport.popup.contactDetails[0].contactNumber') }}
          </a>
        </i18n>
      </template>
    </ConfirmationDialog>
    <ContactUsDialog
      :show-dialog="displayContactUsDialog"
      data-qa="contact-us"
      @close-dialog="displayContactUsDialog = false"
    />
    <link-style-button
      v-if="isDialog"
      data-qa="error-resolution-dialog-link"
      @click="toggleDialog"
    >
      {{ $t(errorBucket.errorResolutionKey) }}
    </link-style-button>
    <a
      v-else-if="isUrl"
      :href="getLocalizedUrl"
      data-qa="error-resolution-new-tab-url"
      target="_blank"
    >
      {{ $t(errorBucket.errorResolutionKey) }}
    </a>
    <div v-else-if="isFlatText" data-qa="error-resolution-text">
      {{ $t(errorBucket.errorResolutionKey) }}
    </div>
    <link-style-button
      v-else
      data-qa="contact-business-center-button"
      @click="toggleContactUsDialog"
    >
      {{ $t('error.bucket.enrollment.unknown.resolution') }}
    </link-style-button>
  </div>
</template>

<script>
import ConfirmationDialog from '@/components/utility-components/dialogs/ConfirmationDialog';
import ContactUsDialog from '@/components/ContactUsDialog';
import { AccountConstants } from '@/constants';
import LinkStyleButton from '@/components/utility-components/LinkStyleButton';
import { verifyStaticAssetUrl } from '@/urlUtils';

export default {
  name: 'ErrorBucket',
  components: { LinkStyleButton, ContactUsDialog, ConfirmationDialog },
  props: {
    errorBucket: {
      type: Object,
      required: true,
    },
    errorCode: String,
  },
  data() {
    return {
      showDialog: false,
      displayContactUsDialog: false,
      resolutionType: '',
      localizedUrlLink: '',
    };
  },
  beforeMount() {
    this.determineResolutionType();
  },
  computed: {
    isUnknownError() {
      return (
        this.resolutionType ===
        AccountConstants.RESOLUTION_CATEGORY_TYPE.UNKNOWN
      );
    },
    isFlatText() {
      return (
        this.resolutionType ===
        AccountConstants.RESOLUTION_CATEGORY_TYPE.FLAT_TEXT
      );
    },
    isDialog() {
      return (
        this.resolutionType === AccountConstants.RESOLUTION_CATEGORY_TYPE.POP_UP
      );
    },
    isUrl() {
      return (
        this.resolutionType === AccountConstants.RESOLUTION_CATEGORY_TYPE.URL &&
        this.localizedUrlLink
      );
    },
    getLocalizedUrl() {
      return this.localizedUrlLink;
    },
  },
  methods: {
    toggleDialog() {
      this.showDialog = !this.showDialog;
    },
    toggleContactUsDialog() {
      this.displayContactUsDialog = true;
    },
    async determineResolutionType() {
      let errorBucket = this.$props.errorBucket;
      let resolutionCategoryType = errorBucket.resolutionCategoryType;

      switch (resolutionCategoryType) {
        case AccountConstants.RESOLUTION_CATEGORY_TYPE.POP_UP:
          if (
            errorBucket.popupDialogTitleKey &&
            errorBucket.popupDialogBodyKey
          ) {
            this.resolutionType =
              AccountConstants.RESOLUTION_CATEGORY_TYPE.POP_UP;
          } else {
            this.resolutionType =
              AccountConstants.RESOLUTION_CATEGORY_TYPE.UNKNOWN;
          }
          break;
        case AccountConstants.RESOLUTION_CATEGORY_TYPE.FLAT_TEXT:
          this.resolutionType =
            AccountConstants.RESOLUTION_CATEGORY_TYPE.FLAT_TEXT;
          break;
        case AccountConstants.RESOLUTION_CATEGORY_TYPE.URL:
          if (errorBucket.newTabUrl) {
            this.localizedUrlLink = await verifyStaticAssetUrl(
              errorBucket.newTabUrl + `${this.$i18n.locale}.pdf`
            );
            this.resolutionType = AccountConstants.RESOLUTION_CATEGORY_TYPE.URL;
          } else {
            this.resolutionType =
              AccountConstants.RESOLUTION_CATEGORY_TYPE.UNKNOWN;
          }
          break;
        default:
          this.resolutionType =
            AccountConstants.RESOLUTION_CATEGORY_TYPE.UNKNOWN;
          break;
      }
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}

a {
  color: #2c64a5;
  text-decoration-line: underline;
}
</style>
