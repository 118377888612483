const actions = {
  setRFIDFilter: ({ commit }, payload) => {
    commit('SET_RFID_FILTER', payload);
  },
  setSearchQuery: ({ commit }, payload) => {
    commit('SET_SEARCH_QUERY', payload);
  },
  clearFilterAndSearch: ({ commit }) => {
    commit('CLEAR_FILTER_AND_SEARCH');
  },
  setTotalRFIDs: ({ commit }, payload) => {
    commit('SET_TOTAL_RFIDS', payload);
  },
  setTotalFilteredRFIDs: ({ commit }, payload) => {
    commit('SET_TOTAL_FILTERED_RFIDS', payload);
  },
  toggleRFIDFilter: ({ commit }, payload) => {
    commit('TOGGLE_RFID_FILTER', payload);
  },
  setPaginationQuery({ commit }, payload) {
    commit('SET_PAGINATION_QUERY', payload);
  },
  refreshTable: ({ commit }) => {
    commit('REFRESH_TABLE');
  },
};

export { actions };
