import axios from 'axios';
import { gatewayInterceptor } from '@/services/interceptors/TranslationGatewayInterceptor';
import configuration from '@/store/configuration';

const TranslationApiProxyService = axios.create({});
TranslationApiProxyService.interceptors.request.use(gatewayInterceptor);
TranslationApiProxyService.interceptors.request.use((config) => {
  config.baseURL = configuration.state.translationBaseUrl;
  config.headers = configuration.state.translationHeaders;
  config.withCredentials =
    configuration.state.translationApiProxyWithConfiguration;
  return config;
});
export default TranslationApiProxyService;
