const state = {
  isLoggedIn: false,
  language: 'en-US',
  companyName: 'My Company Name',
  companyBillingId: '',
  companyCountryCode: '',
  companyId: '',
  companyType: '',
  companyProducts: {},
  companyProductFamilies: {},
  companyAccountLevelProductFamilies: [],
  companyVehicleLevelProductFamilies: [],
  applications: [],
  authorities: [],
  expires_in: 0,
  userLanguages: [],
  iso2to3countryCodeMap: {},
  iso3to2countryCodeMap: {},
  userFirstName: '',
  userLastName: '',
  userId: '',
  userEmail: '',
  primaryPhone: '',
  employeeId: '',
  hasSignedTermsAndConditions: true,
  getStartedPdfUrl: '',
  isFirstTimeLogin: '',
  impersonate: false,
};

export { state };
