import Vue from 'vue';

const mutations = {
  SET_RFID_FILTER: (state, payload) => {
    if (payload.selected.length === 0) {
      Vue.delete(state.rfidFilter, payload.name);
    } else {
      Vue.set(state.rfidFilter, payload.name, payload.selected);
    }
  },
  SET_SEARCH_QUERY: (state, payload) => {
    Vue.set(state, 'searchQuery', payload.data);
  },
  CLEAR_FILTER_AND_SEARCH: (state) => {
    Vue.set(state, 'rfidFilter', {});
    Vue.set(state, 'searchQuery', '');
  },
  SET_TOTAL_RFIDS: (state, payload) => {
    Vue.set(state, 'totalRFIDs', payload);
  },
  SET_TOTAL_FILTERED_RFIDS: (state, payload) => {
    Vue.set(state, 'totalFilteredRFIDs', payload);
  },
  TOGGLE_RFID_FILTER: (state, payload) => {
    let rfidFilter = state.rfidFilter[payload.name];
    if (rfidFilter) {
      Vue.delete(state.rfidFilter, payload.name);
    } else {
      Vue.set(state.rfidFilter, payload.name, !rfidFilter);
    }
  },
  SET_PAGINATION_QUERY: (state, payload) => {
    Vue.set(state, 'paginationQuery', payload.data);
  },
  REFRESH_TABLE: (state) => {
    return state;
  },
};

export { mutations };
