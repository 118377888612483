import { render, staticRenderFns } from "./DataGridLayout.vue?vue&type=template&id=1490ee43&scoped=true&"
import script from "./DataGridLayout.vue?vue&type=script&lang=js&"
export * from "./DataGridLayout.vue?vue&type=script&lang=js&"
import style0 from "./DataGridLayout.vue?vue&type=style&index=0&id=1490ee43&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1490ee43",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VFlex,VLayout})
