import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';
import selection from './selection';
import user from './user';
import vehicle from './vehicle';
import notification from './notification';
import group from './group';
import equipment from './equipment';
import decommissioned from './decommissioned';
import product from './product';
import contact from './contact';
import billing from './billing';
import auth from './auth';
import rfid from './rfid';
import ota from './ota';
import configuration from './configuration';
import fpgConfiguration from './fpgConfiguration';
import termsAndConditions from './termsAndConditions';
import companyBillingInfo from './companyBillingInfo';
import userEditDetails from './userEditDetails';
import multiProductSelection from './multiProductSelection';
import autoSubscribe from './autoSubscribe';
import cache from './cache';
import breadcrumb from '@/store/breadcrumb';
import linkAssetEquipment from '@/store/linkAssetEquipment';
import linkAssetVehicle from '@/store/linkAssetVehicle';
import productUpgrade from '@/store/productUpgrade';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [pathify.plugin],
  modules: {
    selection,
    user,
    vehicle,
    notification,
    group,
    equipment,
    decommissioned,
    product,
    contact,
    billing,
    auth,
    rfid,
    ota,
    termsAndConditions,
    companyBillingInfo,
    userEditDetails,
    configuration,
    fpgConfiguration,
    multiProductSelection,
    autoSubscribe,
    cache,
    breadcrumb,
    linkAssetEquipment,
    linkAssetVehicle,
    productUpgrade,
  },
});
