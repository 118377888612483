import { state, mockState } from '@/store/selection/state';
import { getters } from '@/store/selection/getters';
import { actions } from '@/store/selection/actions';
import { mutations } from '@/store/selection/mutations';

// DEPRECATED: please use individual modules to handle selection logic instead

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export { state, getters, mutations, actions, mockState };
