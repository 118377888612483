import Vue from 'vue';
import { state as auth } from './state';
import { make } from 'vuex-pathify';
import { cloneDeep } from 'lodash';
import { AccountConstants } from '@/constants';

const mutations = {
  ...make.mutations(auth),
  SET_FIRST_TIME_LOGIN_FALSE: (state) => {
    state.isFirstTimeLogin = false;
  },
  SET_USER_INFO: (state, payload) => {
    state.language = payload.language;
    state.userFirstName = payload.firstName;
    state.userLastName = payload.lastName;
    state.userId = payload.userId;
    if (state.isFirstTimeLogin === '') {
      state.isFirstTimeLogin = payload.lastLoginTime === payload.firstLoginTime;
    }
    if (payload.companyInformationResponse) {
      state.companyId = payload.companyInformationResponse.id;
      state.companyName = payload.companyInformationResponse.name;
      state.companyBillingId = payload.companyInformationResponse.billingId;
      state.companyCountryCode = payload.companyInformationResponse.countryCode;
      state.companyType = payload.companyInformationResponse.companyType;
    }
    state.applications = payload.applications;
    state.authorities = payload.authorities;
    state.userEmail = payload.email;
    state.primaryPhone = payload.primaryPhone || '';
    state.employeeId = payload.employeeId;
    state.impersonate = payload.impersonate;
  },
  SET_TOKEN_EXPIRY: (state, payload) => {
    state.expires_in = payload;
  },
  SET_USER_LANGUAGE: (state, payload) => {
    Vue.set(state, 'language', payload);
  },
  SET_USER_LANGUAGES: (state, payload) => {
    Vue.set(state, 'userLanguages', payload.userLanguages);
  },
  SET_USER_COUNTRIES: (state, payload) => {
    let iso2to3countryCodeMap = {};
    let iso3to2countryCodeMap = {};
    let iso2Codes = [];
    payload.userCountries.forEach((country) => {
      iso2Codes.push(country.iso2code);
      iso2to3countryCodeMap[country.iso2code] = country.code;
      iso3to2countryCodeMap[country.code] = country.iso2code;
    });
    Vue.set(state, 'userCountries', iso2Codes);
    Vue.set(state, 'iso2to3countryCodeMap', iso2to3countryCodeMap);
    Vue.set(state, 'iso3to2countryCodeMap', iso3to2countryCodeMap);
  },
  SET_USER_COMPANY_PRODUCTS: (state, payload) => {
    Vue.set(
      state,
      'companyProducts',
      payload
        .flatMap((productFamily) => productFamily.products)
        .reduce((productHash, product) => {
          productHash[product.sku] = product;
          return productHash;
        }, {})
    );
  },
  SET_USER_COMPANY_PRODUCT_FAMILIES: (state, payload) => {
    let tempObj = {};
    payload.forEach((productFamily) => {
      let productFamilyId = productFamily.id.toUpperCase();
      tempObj[productFamilyId] = productFamily.name;
    });
    Vue.set(state, 'companyProductFamilies', tempObj);
  },
  SET_USER_COMPANY_PRODUCT_FAMILY_BY_LEVEL: (state, payload) => {
    const filterByLevel = (level, productFamilies) => {
      return cloneDeep(productFamilies).reduce((acc, productFamily) => {
        productFamily.products = productFamily.products.filter(
          (p) => p.productLevel === level
        );
        if (productFamily.productFamilyLevelImageMap[level]) {
          productFamily.imageUrl =
            productFamily.productFamilyLevelImageMap[level];
        }
        delete productFamily.productFamilyLevelImageMap;
        return productFamily.products.length > 0
          ? [...acc, productFamily]
          : acc;
      }, []);
    };

    Vue.set(
      state,
      'companyAccountLevelProductFamilies',
      filterByLevel(AccountConstants.ACCOUNT_PRODUCT_LEVEL, payload)
    );
    Vue.set(
      state,
      'companyVehicleLevelProductFamilies',
      filterByLevel(AccountConstants.VEHICLE_PRODUCT_LEVEL, payload)
    );
  },
};

export { mutations };
