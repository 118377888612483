import Vue from 'vue';
import AccountApiProxyService from '@/services/AccountApiProxyService';
import { AccountConstants } from '@/constants';

export default new Vue({
  methods: {
    getProductTermsAndConditions(productFamilyId) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/v1/product/upgrade/legalLanguage/' +
          productFamilyId
      );
    },
    getDefaultPricesForProduct(productSku) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/v1/product/defaultPricing/' +
          productSku
      );
    },
    getDefaultProductsForAdd(productFamilyId) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/v1/product/default/' +
          productFamilyId +
          '/add'
      );
    },
    getDefaultProductsForUpgrade(productFamilyId) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/v1/product/default/' +
          productFamilyId +
          '/upgrade'
      );
    },
    requestDemo(request, productFamilyId) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH +
          '/v1/product/lead-generation/request-demo/' +
          productFamilyId,
        request
      );
    },
  },
});
