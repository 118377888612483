import { state, mockState } from '@/store/notification/state';
import { actions } from '@/store/notification/actions';
import { mutations } from '@/store/notification/mutations';
import { getters } from '@/store/notification/getters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export { state, mutations, actions, getters, mockState };
