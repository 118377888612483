const state = {
  vehicleFilter: {},
  totalVehicles: 0,
  totalFilteredVehicles: 0,
  searchQuery: '',
  paginationQuery: {},
  selected: {},
  initialSelected: {},
  vehicleGridColumns: [],
  ownerVehicleGridColumns: [],
  filteredVehicleSecurityCapabilitiesGridColumns: null,
  ccsSettings: [],
};

export { state };
