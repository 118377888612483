import Vue from 'vue';
import VueCookies from 'vue-cookies';
import { AccountConstants } from '@/constants';

Vue.use(VueCookies);

Vue.$cookies.config(
  AccountConstants.ONE_TRUST.EXPIRATION_IN_MINUTES,
  '/',
  window.location.hostname
);
