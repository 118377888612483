<template>
  <SpinnerWrapper :is-loading="isLoading">
    <v-container pa-0>
      <h2
        class="headline primary--text multi-product-selection-header text-uppercase"
      >
        {{ $t('productUpgradeStepper.productSelectionTitle') }}</h2
      >
      <p class="subtitle">
        {{ $t('productUpgradeStepper.productSelectionSubtitle') }}</p
      >
      <v-layout row wrap>
        <v-flex
          v-for="productFamily in getProductFamilies"
          :key="productFamily.id"
          xl6
          lg6
          md12
          sm12
          xs12
        >
          <v-card
            :class="[
              'product-item card-height',
              'ma-2',
              getSelected[productFamily.id] && 'selected-product',
            ]"
          >
            <v-layout row wrap class="card-height pa-2 ma-1">
              <v-flex xs7 l7>
                <v-layout column fill-height>
                  <div>
                    <v-checkbox
                      class="ml-1"
                      v-model="getSelected[productFamily.id]"
                      color="primary"
                      :label="
                        $tf(
                          'productFamily.' + productFamily.id,
                          productFamily.name
                        )
                      "
                      :disabled="
                        productFamilyHasUnSelectedDependencies(
                          productFamily.dependencyProductFamilyIds
                        )
                      "
                      @change="
                        handleProductFamilySelection($event, productFamily)
                      "
                      :data-qa="dashify(`product-checkbox-${productFamily.id}`)"
                    ></v-checkbox>
                  </div>
                  <div
                    class="ml-1 mb-2"
                    v-if="productFamily.dependencyProductFamilyIds.length > 0"
                  >
                    <span
                      class="caption text--secondary price-align mb-0"
                      :data-qa="
                        dashify(
                          `product-required-dependency-${productFamily.id}`
                        )
                      "
                    >
                      {{ retrieveProductFamilyDependencyText(productFamily) }}
                    </span>
                  </div>
                  <div class="ml-1">
                    <div
                      v-if="
                        productFamily.currencyCode == null ||
                        productFamily.price == null
                      "
                    >
                      <span
                        class="subheading text--primary price-align mb-0"
                        :data-qa="
                          dashify(`price-complimentary-${productFamily.id}`)
                        "
                      >
                        {{ $t('products.complimentaryPrice') }}
                      </span>
                    </div>
                    <div v-else>
                      <span
                        class="subheading text--primary price-align mb-0"
                        :data-qa="dashify(`price-${productFamily.id}`)"
                      >
                        {{ coinify(productFamily.currencyCode)
                        }}{{ productFamily.price }}
                      </span>
                      <br />
                      <span
                        class="caption text--secondary price-align mb-0"
                        :data-qa="
                          dashify(`price-description-${productFamily.id}`)
                        "
                      >
                        {{
                          `${$t('products.priceDescription1')} ${$t(
                            'products.priceDescription2'
                          )}`
                        }}
                      </span>
                    </div>
                  </div>
                </v-layout>
              </v-flex>
              <v-flex xs5 l5>
                <v-img
                  :src="getImageUrl(productFamily.imageUrl)"
                  :alt="
                    $t(`products.images.${dashify(productFamily.imageUrl)}.alt`)
                  "
                  class="product-image"
                  :data-qa="`image-${dashify(productFamily.imageUrl)}`"
                />
                <link-style-button
                  class="ml-1 caption description-container"
                  :data-qa="
                    dashify(`view-product-description-${productFamily.id}`)
                  "
                  @click="handleReadMoreClick(productFamily)"
                >
                  {{ $t('products.viewDescription') }}
                </link-style-button>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout
        v-if="selectedLength > 0"
        row
        wrap
        align-center
        justify-center
        class="mt-4"
      >
        <v-flex align-self-center shrink>
          <div data-qa="selected-count" class="mr-2" id="selected-count">
            <v-chip
              label
              color="primary"
              text-color="white"
              small
              data-qa="v-chip-text"
            >
              {{ selectedLength }}
            </v-chip>
            <span class="selected-count-text">{{
              $tc('products.selected', selectedLength)
            }}</span>
          </div>
        </v-flex>
      </v-layout>
      <ConfirmationDialog
        :title="`${$t('products.description', {
          productFamilyName: productDisplayed.name,
        })}`"
        :message="$t(`products.descriptions.${productDisplayed.id}`)"
        cancellation-text="Cancel"
        :display-confirm="false"
        :enable-dialog="showReadMoreDialog"
        data-qa-key="view-product-description"
        @cancel="showReadMoreDialog = false"
        @keydown.esc="showReadMoreDialog = false"
        hide-content
        persistent
      />
    </v-container>
    <ErrorDialog
      :error="this.error"
      :show-dialog="showErrorDialog"
      @close-dialog="showErrorDialog = false"
    />
  </SpinnerWrapper>
</template>

<script>
import '@/styles/modules/_product-grid-item.scss';
import CompanyService from '@/services/CompanyService';
import { mapActions, mapGetters } from 'vuex';
import dashify from 'dashify';
import coinify from 'coinify';
import ConfirmationDialog from '@/components/utility-components/dialogs/ConfirmationDialog';
import ErrorDialog from '@/components/utility-components/dialogs/ErrorDialog';
import SpinnerWrapper from '@/components/utility-components/SpinnerWrapper';
import ErrorMessageService from '@/services/ErrorMessageService';
import { get } from 'vuex-pathify';
import { getStaticAssetsBaseUrl } from '@/urlUtils';
import LinkStyleButton from '@/components/utility-components/LinkStyleButton';

export default {
  name: 'MultiProductSelection',
  components: {
    LinkStyleButton,
    SpinnerWrapper,
    ConfirmationDialog,
    ErrorDialog,
  },
  props: {
    preSelectedProducts: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  computed: {
    ...mapGetters('multiProductSelection', [
      'getSelected',
      'getProductFamilies',
      'selectedLength',
    ]),
    ...mapGetters('auth', ['getLanguage']),
    ...get('configuration', [
      'fordTelematicsEssentialsProductFamilyId',
      'fordTelematicsProductFamilyId',
    ]),
    preSelectedProductFamilies() {
      return this.preSelectedProducts;
    },
  },
  data() {
    return {
      characterLimit: 200,
      showReadMoreDialog: false,
      productDisplayed: {},
      isLoading: true,
      showErrorDialog: false,
      error: {},
    };
  },
  mounted() {
    this.getMarketableProductFamilies();
  },
  methods: {
    dashify,
    coinify(currencyCode) {
      if (currencyCode !== null) {
        return coinify.symbol(currencyCode);
      }
    },
    ...mapActions('multiProductSelection', [
      'setProductFamilies',
      'addSelected',
      'removeSelected',
    ]),
    retrieveProductFamilyDependencyText(product) {
      let productDependencies = this.getProductFamilies
        .filter((pf) => product.dependencyProductFamilyIds.includes(pf.id))
        .map((pf) => this.$tf('productFamily.' + pf.id, pf.name));
      return this.$tc(
        'productUpgradeStepper.requiresDependency',
        productDependencies.length,
        { productFamilies: productDependencies.join(',') }
      );
    },
    handleReadMoreClick(product) {
      this.productDisplayed = product;
      this.showReadMoreDialog = true;
    },
    getMarketableProductFamilies() {
      this.isLoading = true;
      CompanyService.getMarketableProductFamilies(this.getLanguage)
        .then((res) => {
          this.setProductFamilies(res);
          this.setDefaultSelectedProductFamilies(res.data);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleError(error) {
      this.error = ErrorMessageService.getErrorMessage(error);
      this.showErrorDialog = true;
    },
    setDefaultSelectedProductFamilies(productFamilyResponse) {
      if (this.preSelectedProductFamilies.length > 0) {
        let selectedProductsMap = {};
        this.preSelectedProductFamilies.forEach((productFamily) => {
          selectedProductsMap[productFamily.id] = productFamily;
        });
        productFamilyResponse
          .filter((productFamily) => !!selectedProductsMap[productFamily.id])
          .forEach((productFamily) => {
            this.addSelected(productFamily);
          });
      } else {
        productFamilyResponse
          .filter(
            (productFamily) =>
              productFamily.id === this.fordTelematicsEssentialsProductFamilyId
          )
          .forEach((productFamily) => {
            this.addSelected(productFamily);
          });
      }
    },
    productFamilyHasUnSelectedDependencies(productDependencies) {
      for (let dependency of productDependencies) {
        if (Object.keys(this.getSelected).includes(dependency)) {
          return false;
        }
      }
      return productDependencies.length > 0;
    },
    handleProductFamilySelection(selected, productFamily) {
      if (selected) {
        this.addSelected(productFamily);
        productFamily.exclusionProductFamilyIds.map((exclusionFamilyId) => {
          this.removeSelected(exclusionFamilyId);
          this.removeDeselectedProductDependencies(exclusionFamilyId);
        });
      } else {
        this.removeSelected(productFamily.id);
        this.removeDeselectedProductDependencies(productFamily.id);
      }
    },
    removeDeselectedProductDependencies(deselectedProductFamilyId) {
      Object.values(this.getSelected).map((value) => {
        if (
          value.dependencyProductFamilyIds.length > 0 &&
          value.dependencyProductFamilyIds.includes(deselectedProductFamilyId)
        ) {
          this.removeSelected(value.id);
        }
      });
    },
    getImageUrl(url) {
      return getStaticAssetsBaseUrl() + url;
    },
  },
};
</script>

<style scoped lang="scss">
.v-input--selection-controls {
  margin: 0;

  ::v-deep .v-label {
    color: var(--v-primary-base);
    font-size: 16px;
  }
}

::v-deep .v-input__control .v-messages.theme--light {
  min-height: 0;
}

.card-height {
  height: 170px;
}

@media only screen and (max-width: 1264px) {
  .card-height {
    height: fit-content;
  }
}

.subtitle {
  text-align: center;
  font-size: 16px;
}

.product-item {
  border-radius: 4px;
  border: 1px solid #f7f7f7;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.selected-product {
  border: 1px solid #2861a4;
}

.read-more {
  text-decoration: underline;
}

.description-container {
  line-height: 1;
  text-decoration: underline #2861a4;
  color: #2861a4;
  cursor: pointer;
  text-align: center;
  margin-top: 4px;
}

.multi-product-selection-header {
  text-align: center;
  padding-bottom: 2%;
  font-size: 22px !important;
  color: #292e35 !important;
}

.product-dependency-section {
  margin-bottom: 8px;
}
</style>
