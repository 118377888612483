import { AccountConstants } from '@/constants';
import Vue from 'vue';

/**
 * Utility class for handling cookies
 */
export default new Vue({
  methods: {
    /**
     * Utility to set cookie
     * @param name
     * @param value
     * @param domain
     * @param durationMinutes
     */
    setCookie(name, value) {
      this.$cookies.set(name, `${value}`);
    },

    /**
     * Utility to get cookie based on Cookie name
     * @param name
     */
    getCookie(name) {
      return this.$cookies.get(name);
    },

    /**
     * Store cookie preferences in cookie name `cookiePreferences`
     * @param performance
     * @param functionality
     * @param targeting
     */
    storeCookiePreferences(performance, functionality, targeting) {
      const preferences = {};
      preferences[
        AccountConstants.ONE_TRUST.COOKIE_GROUPS.STRICTLY_NECESSARY
      ] = true;
      preferences[AccountConstants.ONE_TRUST.COOKIE_GROUPS.FUNCTIONALITY] =
        functionality;
      preferences[AccountConstants.ONE_TRUST.COOKIE_GROUPS.PERFORMANCE] =
        performance;
      preferences[AccountConstants.ONE_TRUST.COOKIE_GROUPS.TARGETING] =
        targeting;

      this.setCookie(
        AccountConstants.ONE_TRUST.COOKIE_KEYS.COOKIE_PREFERENCES,
        JSON.stringify(preferences)
      );
    },

    /**
     * Set banner interacted cookie
     */
    createBannnerInteractedCookie() {
      this.setCookie(
        AccountConstants.ONE_TRUST.COOKIE_KEYS.COOKIE_BANNER_INTERACTED,
        'true'
      );
    },

    /**
     * Check if bannerInteracted cookie exists
     */
    cookieInteractedExists() {
      return !!this.getCookie(
        AccountConstants.ONE_TRUST.COOKIE_KEYS.COOKIE_BANNER_INTERACTED
      );
    },
  },
});
