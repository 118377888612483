const state = {
  searchQuery: '',
  paginationQuery: {},
  selected: {},
  initialSelected: {},
  userGridColumns: [],
  totalUsers: 0,
  showReimbursableAddressDialog: false,
  showAddressVerificationDialog: false,
  reimbursableAddress: {},
  viewAddress: false,
  notificationPreferences: {},
  userFilter: {},
  showChargingStationDialog: false,
  chargeStationErrors: [],
  chargeStationRule: [],
};

export { state };
