<template>
  <div>
    <h2
      v-if="!hideTitle"
      class="headline text-xs-center pb-2"
      data-qa="billing-form-title"
    >
      {{ $t('billing.companyInfo.title') }}</h2
    >
    <v-layout align-center class="pt-2">
      <v-flex>
        <h4
          v-if="isComplimentary"
          class="subheading text-xs-center pr-3 pl-3"
          data-qa="billing-form-explanation"
        >
          {{ $t('billing.explanationQuestion') }}
          <v-menu
            nudge-bottom="24pt"
            nudge-left="16pt"
            :close-on-content-click="false"
            max-width="275px"
            data-qa="product-tooltip"
          >
            <template v-slot:activator="{ on }">
              <v-icon
                :data-qa="'explanation-tooltip-activator'"
                class="vertical-align-sub"
                color="secondary"
                :id="'explanation-tooltip-activator'"
                v-on="on"
                small
                >ic-info-circle
              </v-icon>
            </template>
            <v-card class="">
              <v-card-title class="pb-1">
                <v-icon color="secondary" small>ic-info-circle</v-icon>
              </v-card-title>
              <v-card-text
                class="pt-1 subheading"
                v-html="$t('billing.explanationAnswer')"
              >
              </v-card-text>
            </v-card>
          </v-menu>
        </h4>
      </v-flex>
    </v-layout>
    <v-container v-if="isFetchingData">
      <v-layout justify-center row>
        <v-flex shrink>
          <loading-spinner :size="80" :width="5" color="secondary" />
        </v-flex>
      </v-layout>
    </v-container>
    <v-form
      :class="[
        'billing-information-form',
        $vuetify.breakpoint.smAndUp ? 'smAndUp' : '',
      ]"
      data-qa="billing-information-form"
      v-bind:value="value"
      v-else
      v-on:input="$emit('input', $event)"
      v-on:submit="$emit('submit', $event)"
    >
      <v-container grid-list-xs text-xs-center>
        <v-layout row wrap>
          <v-flex class="form-field" xs12>
            <v-text-field
              :label="$t('billing.companyInfo.companyName')"
              :rules="nullRuleCheck"
              data="companyName"
              :value="companyName"
              @input="addCompanyName"
              data-qa="company-name"
              outline
              data-qm-encrypt="true"
            ></v-text-field>
          </v-flex>

          <v-flex class="form-field" xs12>
            <v-text-field
              :label="$t('billing.companyInfo.addressLine1')"
              :rules="nullRuleCheck"
              :value="address1"
              @input="addAddressLine1"
              data-qa="address-line-1"
              outline
              data-qm-encrypt="true"
            ></v-text-field>
          </v-flex>

          <v-flex class="form-field" xs12>
            <v-text-field
              :label="$t('billing.companyInfo.addressLine2')"
              :value="address2"
              @input="addAddressLine2"
              class="address-line-2"
              data-qa="address-line-2"
              outline
              data-qm-encrypt="true"
            ></v-text-field>
          </v-flex>

          <v-flex :class="columnStyle" data-qa="city-container">
            <v-text-field
              :label="$t('billing.companyInfo.city')"
              :rules="nullRuleCheck"
              :value="city"
              @input="addCity"
              :class="getRightMargin"
              data-qa="city"
              outline
              data-qm-encrypt="true"
            ></v-text-field>
          </v-flex>

          <v-flex :class="columnStyle" data-qa="country-container">
            <v-autocomplete
              :items="countries"
              :label="$t('billing.companyInfo.country')"
              :rules="nullRuleCheck"
              :value="countryCode"
              @change="onSelectCountry"
              :class="getLeftMargin"
              item-text="name"
              item-value="iso3"
              outline
              ref="countries"
              required
              data-qm-encrypt="true"
            >
              <template v-slot:item="data">
                <span :data-qa="'country-iso3-' + data.item.iso3">{{
                  $t('country.' + data.item.name)
                }}</span>
              </template>
              <template v-slot:selection="data">
                <div data-qa="selected-country">{{
                  $t('country.' + data.item.name)
                }}</div>
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex :class="columnStyle" data-qa="state-container">
            <v-autocomplete
              :class="getRightMargin"
              :items="states"
              :label="$t('billing.companyInfo.state')"
              :rules="nullRuleCheck"
              :value="state"
              @change="(event) => addStateToStore(event)"
              data-qa="state"
              item-text="name"
              item-value="name"
              outline
              ref="states"
              v-if="useStateSelection"
              data-qm-encrypt="true"
            >
              <template v-slot:item="data">
                <span :data-qa="'state-code-' + data.item.state_code">{{
                  $t('state.' + data.item.name)
                }}</span>
              </template>
              <template v-slot:selection="data">
                <div data-qa="selected-state">{{
                  $t('state.' + data.item.name)
                }}</div>
              </template>
            </v-autocomplete>
            <v-text-field
              v-else
              :label="$t('billing.companyInfo.state')"
              :value="state"
              data-qa="state-province-region"
              outline
              @input="(event) => addState({ name: event })"
              data-qm-encrypt="true"
            ></v-text-field>
          </v-flex>

          <v-flex :class="columnStyle" data-qa="zipcode-container">
            <v-text-field
              :label="$t('billing.companyInfo.zipCode')"
              :rules="nullRuleCheck"
              :value="zipCode"
              @input="addZipCode"
              :class="getLeftMargin"
              data-qa="postal-code"
              outline
              data-qm-encrypt="true"
            ></v-text-field>
          </v-flex>

          <v-flex :class="columnStyle" data-qa="tax-id-container">
            <v-text-field
              :label="$t(getBillingInfoLabelText)"
              :rules="taxIdRule"
              :value="taxId"
              @input="addTaxID"
              :class="getRightMargin"
              data-qa="tax-id"
              outline
              data-qm-encrypt="true"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <slot />
      </v-container>
    </v-form>
  </div>
</template>

<script>
import LocalityService from '@/services/LocalityService';
import { mapActions, mapGetters } from 'vuex';
import { AccountConstants } from '@/constants';
import csc from 'countries-states-cities';
import LoadingSpinner from '@/components/utility-components/LoadingSpinner';

export default {
  name: 'BillingInformationForm',
  components: { LoadingSpinner },
  mounted() {
    LocalityService.getLocals().then((response) => {
      let countryCodes = response.data.countryList.map(
        (country) => country.code
      );
      this.countries = csc
        .getAllCountries()
        .filter((country) => countryCodes.includes(country.iso3));
      this.countries.forEach((item, index) => {
        this.countries[index].name = this.countries[index].name.replace(
          AccountConstants.REMOVE_SPECIAL_CHARACTERS_REGEX,
          ''
        );
      });
      this.determineStateOptions();
      this.setIntialStateInStore(this.state);
    });
  },
  methods: {
    addStateToStore(stateName) {
      this.addState(this.states.filter((state) => state.name === stateName)[0]);
    },
    setIntialStateInStore(stateName) {
      let selectableState = this.states.filter(
        (state) => state.name === stateName
      )[0];
      let finalState = selectableState || { name: stateName };
      this.setInitialState(finalState);
    },
    onSelectCountry(countryCode) {
      this.addCountryCode(countryCode);
      this.determineStateOptions();
    },
    determineStateOptions() {
      const country = this.countries.find((c) => c.iso3 === this.countryCode);

      if (!country || country.region === 'Europe') {
        this.useStateSelection = false;
        return;
      }
      this.useStateSelection = true;
      this.states = csc.getStatesOfCountry(country.id);
    },
    ...mapActions('companyBillingInfo', [
      'addCompanyName',
      'addAddressLine1',
      'addAddressLine2',
      'addCity',
      'addCountryCode',
      'addState',
      'setInitialState',
      'addZipCode',
      'addTaxID',
    ]),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    singleColumnView: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    isComplimentary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      useStateSelection: false,
      countries: [],
      states: [],
      nullRuleCheck: [
        (v) => !!v || this.$t('billing.companyInfo.rules.required'),
      ],
    };
  },
  computed: {
    columnStyle() {
      return this.singleColumnView ? 'form-field xs12' : 'form-field sm6 xs12';
    },
    getRightMargin() {
      return this.singleColumnView ? '' : 'right-margin';
    },
    getLeftMargin() {
      return this.singleColumnView ? '' : 'left-margin';
    },
    taxIdRule() {
      return [
        (v) =>
          v.length <= AccountConstants.MAX_TAX_ID_LENGTH ||
          this.$t('taxId.rules.length'),
      ];
    },
    ...mapGetters('companyBillingInfo', [
      'companyName',
      'address1',
      'address2',
      'city',
      'countryCode',
      'state',
      'zipCode',
      'taxId',
      'isFetchingData',
      'getBillingInfoLabelText',
    ]),
  },
  watch: {
    isFetchingData(val) {
      if (val) {
        this.$emit('input', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.billing-information-form {
  &.smAndUp {
    .form-field {
      .right-margin {
        margin-right: 0.5em;
      }
      .left-margin {
        margin-left: 0.5em;
      }
    }
  }
}
.vertical-align-sub {
  vertical-align: sub;
}
</style>
