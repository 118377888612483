export function checkAuthorityForPath(
  authorityPath,
  next,
  store,
  throws = false,
  fpgLaunchedSidebarItem = undefined
) {
  const isNotTileModeOrRouteLaunchedInFpg =
    !store.get('fpgConfiguration/isTileMode') ||
    !fpgLaunchedSidebarItem ||
    store
      .get('configuration/fpgLaunchedSidebarItems')
      .includes(fpgLaunchedSidebarItem);
  if (store.getters[authorityPath]() && isNotTileModeOrRouteLaunchedInFpg) {
    next();
  } else {
    if (throws) {
      throw new Error('Unauthorized');
    } else {
      next('/unauthorized');
    }
  }
}

export function redirectIfCheckAuthorityForPathUnauthorized(
  to,
  next,
  store,
  getRedirectRoute,
  throws = false,
  fpgLaunchedSidebarItem = undefined
) {
  try {
    checkAuthorityForPath(
      to.meta.authorityPath,
      next,
      store,
      throws,
      fpgLaunchedSidebarItem
    );
  } catch (e) {
    if (e.message === 'Unauthorized') {
      // This covers the use case when the user (e.g. an external owner) is not able to access the default page
      next(getRedirectRoute());
    }
  }
}
