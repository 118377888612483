import { make } from 'vuex-pathify';
import { state as initialState } from '@/store/fpgConfiguration';
import { i18n } from '@/i18n';

const getters = {
  ...make.getters(initialState),
  shouldFordProGatewayRedirect(state, _, rootState, rootGetters) {
    const {
      fpgSupportedCountries,
      fpgSupportedLocales,
      fpgSupportedAuthorities,
    } = rootState.configuration;
    const { authorities } = rootState.auth;

    const hasProperAuthorities = fpgSupportedAuthorities.some((a) =>
      authorities.includes(a)
    );

    const companyCountry = rootGetters['auth/getCompanyCountryCode'];

    return (
      fpgSupportedLocales.includes(i18n.locale) &&
      fpgSupportedCountries.includes(companyCountry) &&
      hasProperAuthorities
    );
  },
};

export { getters };
