<template>
  <v-layout v-if="linkedAssets.length === 0">
    <v-flex
      xs12
      :class="getLinkedAssetClass()"
      data-qa="no-linked-asset-found-message"
      >{{ getLinkedAssetText() }}
    </v-flex>
  </v-layout>
  <v-layout v-else>
    <v-flex
      xs12
      :style="{
        maxHeight: height - 120 + 'px',
        overflow: 'auto',
      }"
    >
      <v-card
        flat
        v-for="asset in linkedAssets"
        :key="asset.id"
        class="mb-3"
        :data-qa="`linked-asset-card-${asset.id}`"
      >
        <v-layout>
          <v-flex xs8>
            <v-flex
              mb-1
              px-0
              style="font-weight: 500"
              v-if="isVehicleAndNoName(asset)"
              :data-qa="`linked-asset-vehicle-no-name-${asset.id}`"
            >
              <div :data-qa="`linked-asset-identifier-hyperlink-${asset.id}`">
                <!--                    TODO: fix the vehicle link-->
                <span>
                  {{
                    `${getVehicleName(asset.name, asset.brand, asset.model)}`
                  }}
                </span>
              </div>
            </v-flex>
            <v-flex
              mb-1
              px-0
              style="font-weight: 500"
              v-else-if="isVehicle(asset)"
              :data-qa="`linked-asset-vehicle-no-name-${asset.id}`"
            >
              <div :data-qa="`linked-asset-identifier-hyperlink-${asset.id}`">
                <span>
                  {{ asset.name }}
                </span>
              </div>
            </v-flex>
            <v-flex
              mb-1
              px-0
              v-else
              style="font-weight: 500"
              :data-qa="`linked-asset-identifier-${asset.id}`"
            >
              <div>
                <router-link
                  :to="{ path: '/equipment/' + asset.id }"
                  class="asset-link"
                  :data-qa="`linked-asset-name-hyperlink-${asset.id}`"
                >
                  {{ asset.name }}
                </router-link>
              </div>
            </v-flex>
            <v-flex
              mb-1
              px-0
              class="linked-asset-identifier"
              :data-qa="`linked-asset-identifier-${asset.id}`"
            >
              <div>{{ asset.identifier }}</div>
            </v-flex>
            <v-layout style="font-weight: 500" px-0>
              <v-flex :data-qa="`linked-asset-type-${asset.id}`">
                <div>{{ asset.type }}</div>
              </v-flex>
              <v-flex>
                <div>{{ '·' }}</div>
              </v-flex>
              <v-flex :data-qa="`linked-asset-year-${asset.id}`">
                <div>{{ asset.year }}</div>
              </v-flex>
              <v-flex>
                <div>{{ '·' }}</div>
              </v-flex>
              <v-flex :data-qa="`linked-asset-brand-${asset.id}`">
                <div>{{ asset.brand }}</div>
              </v-flex>
              <v-flex>
                <div>{{ '·' }}</div>
              </v-flex>
              <v-flex :data-qa="`linked-asset-model-${asset.id}`">
                <div>{{ asset.model }}</div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs4>
            <v-layout align-center justify-end fill-height>
              <v-flex shrink mr-3 data-qa="unlink-asset">
                <div data-qa="unlink-asset-identifier">
                  <v-btn
                    small
                    block
                    flat
                    class="error--text text-none"
                    :data-qa="`unlink-asset-button-${asset.id}`"
                    @click="setParamsAndShowUnlinkDialog(asset)"
                  >
                    {{ $t('generic.unlink') }}
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
      <LinkAssetUnlinkDialog
        :current-entity="currentEntity"
        :linked-entity="linkedEntity"
        :association-id="associationId"
        :enable-dialog="showUnlinkDialog"
        @unlink-confirmed="removeAssociation([associationId], $event)"
        @unlink-cancelled="showUnlinkDialog = false"
      >
      </LinkAssetUnlinkDialog>
    </v-flex>
  </v-layout>
</template>

<script>
import ResolveVehicleName from '@/mixins/ResolveVehicleName';
import LinkAssetUnlinkDialog from '@/components/equipment/LinkAssetUnlinkDialog';
import EquipmentService from '@/services/EquipmentService';
import { mapActions } from 'vuex';
import { EquipmentConstants } from '@/constants';

export default {
  name: 'LinkAssetInfo',
  components: {
    LinkAssetUnlinkDialog,
  },
  mixins: [ResolveVehicleName],
  mounted() {
    this.height = this.$parent.$el.clientHeight;
  },
  props: {
    linkedAssets: Array,
    currentEntity: Object,
    equipmentTypes: Array,
  },
  data: () => {
    return {
      associations: [],
      showUnlinkDialog: false,
      unlinkAssetDialogData: {},
      linkedEntity: {},
      associationId: '',
      constants: {
        EQUIPMENT_CUSTOM_EQUIPMENT_TYPE:
          EquipmentConstants.EQUIPMENT_CUSTOM_EQUIPMENT_TYPE,
      },
      height: 0,
    };
  },
  methods: {
    isVehicleAndNoName(asset) {
      return this.isVehicle(asset) && (!asset.name || asset.name === '');
    },
    isVehicle(asset) {
      return asset.type === 'Vehicle';
    },
    setParamsAndShowUnlinkDialog(asset) {
      this.associationId = asset.associationId;
      this.linkedEntity = asset;
      this.showUnlinkDialog = true;
    },
    removeAssociation(associationIds, linkedEntityName) {
      return EquipmentService.removeLinkAssetByAssociationId(associationIds)
        .then((_response) => {
          const messageSuccess = this.$t(
            'equipment.unlinkAssociationPopup.snackbarSuccess',
            {
              linkedEntityName: linkedEntityName,
            }
          );
          this.addSuccessNotification(messageSuccess);
          this.refreshEquipmentDetails();
        })
        .catch((err) => {
          const messageFailure = this.$t(
            'equipment.unlinkAssociationPopup.snackbarFailure',
            {
              linkedEntityName: linkedEntityName,
            }
          );
          this.addErrorNotification(messageFailure);
          console.log(err);
        })
        .finally(() => {
          this.showUnlinkDialog = false;
        });
    },
    getLinkedAssetClass() {
      let typeWithLimits = this.getTypeWithLimits();

      if (typeWithLimits.equipmentAssociationLimit === 0) {
        return {
          contentValue: false,
          contentValueDashCam: true,
        };
      } else {
        return {
          contentValue: true,
          contentValueDashCam: false,
        };
      }
    },
    getLinkedAssetText() {
      let typeWithLimits = this.getTypeWithLimits();

      if (typeWithLimits.equipmentAssociationLimit === 0) {
        return this.$t('equipment.noLinkedVehicle');
      } else {
        return this.$t('equipment.noAssetsLinked');
      }
    },
    getTypeWithLimits() {
      let typeWithLimits = this.equipmentTypes.find(
        (et) => et.type === this.currentEntity.type
      );
      if (!typeWithLimits) {
        typeWithLimits = this.equipmentTypes.find(
          (et) => et.type === this.constants.EQUIPMENT_CUSTOM_EQUIPMENT_TYPE
        );
      }
      return typeWithLimits;
    },
    ...mapActions({ refreshEquipmentDetails: 'equipment/refreshEquipment' }),
    ...mapActions('notification', [
      'addErrorNotification',
      'addSuccessNotification',
    ]),
  },
};
</script>

<style scoped lang="scss">
.contentValue {
  color: var(--v-info-darken3);
  font-size: 16px;
}

.contentValueDashCam {
  color: var(--v-error-base);
  font-size: 16px;
}

.linked-asset-identifier {
  color: var(--v-primary-base);
}

.asset-link {
  color: #2861a4;
}
</style>
