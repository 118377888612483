import { AccountConstants } from '@/constants';

const adobeDTMService = {
  generateHeaderScript() {
    const script = document.createElement('script');
    script.id = AccountConstants.ADOBE_DTM.HEADER_ID;
    if (
      AccountConstants.PRODUCTION_HOST_NAMES.includes(window.location.hostname)
    ) {
      script.src = AccountConstants.ADOBE_DTM.URL_PROD;
    } else {
      script.src = AccountConstants.ADOBE_DTM.URL_STAGE;
    }
    document.head.appendChild(script);
  },
  generateFooterScript() {
    const footerScript = document.createElement('script');
    footerScript.id = AccountConstants.ADOBE_DTM.FOOTER_ID;
    footerScript.type = 'text/javascript';
    footerScript.innerText = '_satellite.pageBottom();';
    const footer = document.createElement('footer');
    footer.appendChild(footerScript);
    document.body.appendChild(footer);
  },
  generateRadUIVersionScript() {
    const radUIScript = document.createElement('script');
    radUIScript.id = AccountConstants.ADOBE_DTM.RAD_UI_SCRIPT_ID;
    radUIScript.type = 'text/javascript';
    radUIScript.innerText =
      'var radUIVersion = getRadUIVersion();' +
      'function getRadUIVersion() {' +
      ' var x = window.innerWidth;' +
      ' var y = undefined;' +
      ' if (x < 768) {' +
      '  y = "mobile"' +
      ' } else {' +
      '  if (x < 992) {' +
      '   y = "tablet"' +
      '  } else {' +
      '   y = "pc"' +
      '  }' +
      ' }' +
      ' return "ui:rad:" + y' +
      '}';
    document.head.appendChild(radUIScript);
  },
  async isLoaded(timeout = 1000) {
    let count = 0;
    while (!window._satellite && count < 3) {
      count++;
      await new Promise((resolve) => setTimeout(resolve, timeout));
    }
    return !!window._satellite;
  },
  async trackSignUp(isThirdParty, userLanguage) {
    if (await this.isLoaded()) {
      const pageName = isThirdParty
        ? 'fpro:intelligence:customer signup:third party'
        : 'fpro:intelligence:customer signup';

      window.digitaldata = {
        page: {
          pageName,
          siteSection: 'intelligence',
          hierarchy: 'fpro:intelligence:fleet marketplace',
          userLanguage,
          pageNameNoVehicle: pageName,
          radUIVersion: window.radUIVersion,
        },
        user: {
          loginStatus: 'logged out',
        },
      };
      window._satellite.track('signUp');
    }
  },
  async trackSignUpComplete(isThirdParty) {
    if (await this.isLoaded()) {
      const onclickLinkName = isThirdParty
        ? 'fpro:intelligence:customer signup:third party:complete'
        : 'fpro:intelligence:customer signup:complete';
      const onclick = isThirdParty
        ? 'fpi:customer signup:third party:complete'
        : 'fpi:customer signup:complete';
      window.digitaldata = {
        onclick: {
          onclickLinkName,
          onclick,
        },
        page: {
          tool: 'event:lead submitted',
          toolDescriptor: 'lead submitted:signup',
          radUIVersion: window.radUIVersion,
        },
        user: {
          leadType: 'registered',
          leadOptIn: 'fpi signup',
          loginStatus: 'logged out',
          registeredStatus: 'registered',
        },
        event: {
          action: 'lead submitted|updates opt-In|registered|tools',
        },
      };
      window._satellite.track('signUpComplete');
    }
  },
  async generateAdobeDTMScripts() {
    this.generateHeaderScript();
    this.generateRadUIVersionScript();
    if (await this.isLoaded()) {
      this.generateFooterScript();
    }
  },
};

export default adobeDTMService;
