<template>
  <v-btn
    icon
    small
    v-on="$listeners"
    v-bind="$attrs"
    :aria-label="ariaLabel"
    :data-qa="dataQa"
  >
    <v-icon :color="iconColor" :class="iconClassNames" small>
      {{ iconTag }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    dataQa: { type: String, required: false },
    iconTag: { type: String, required: true },
    ariaLabel: { type: String, required: true },
    iconColor: { type: String, default: 'secondary' },
    iconClassNames: { type: String, required: false },
    id: { type: String, required: false },
  },
};
</script>
