import AccountApiProxyService from '@/services/AccountApiProxyService';
import { AccountConstants } from '@/constants';

const queryString = require('query-string');

const policyService = {
  getPrivacyPolicy(country, language) {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH +
        '/policy/privacy?' +
        queryString.stringify({
          countryCode: country,
          language: language,
        })
    );
  },
  getTermsAndConditionsPolicy(country, language) {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH +
        '/policy/termsAndConditions?' +
        queryString.stringify({
          countryCode: country,
          language: language,
        })
    );
  },
  getLatestTermsAndConditions() {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH + '/policy/termsAndConditions/latest'
    );
  },
  remindTermsAndConditionsLater() {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH +
        '/policy/termsAndConditions/remindLater'
    );
  },
};

export default policyService;
