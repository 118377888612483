import { state } from '@/store/group/state';
import { actions } from '@/store/group/actions';
import { mutations } from '@/store/group/mutations';
import { getters } from '@/store/group/getters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export { state, mutations, actions, getters };
