<template>
  <v-navigation-drawer
    v-model="dialog"
    :data-qa="`${dashify(dataQaKey)}-edit-dialog`"
    @input="hideDialogOnClick($event)"
    absolute
    right
    hide-overlay
    class="elevation-5"
    width="500px"
    disable-resize-watcher
    :style="{ marginTop: headerHeight + 'px' }"
    role="dialog"
  >
    <v-layout column fill-height>
      <v-container class="pb-0">
        <v-layout class="heading-text">
          <h2 class="headline pb-3" ref="heading" tabindex="-1">
            {{ header }}
          </h2>
        </v-layout>
        <slot name="staticContent" />
        <br />
        <v-divider v-if="hasStaticContent" class="mb-4" />
        <div v-if="hasScrollableContent" class="scrollable">
          <slot name="scrollableContent" />
        </div>
        <v-divider v-if="hasScrollableContent" class="mb-4" />
        <slot name="bottomContent" />
        <v-layout class="pb-5">
          <v-container align-end class="pt-0">
            <v-flex class="bottom-padding">
              <v-layout row>
                <v-flex xs12>
                  <slot name="action" />
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs12 sm6>
                  <v-btn
                    block
                    :data-qa="`${dashify(dataQaKey)}-cancel`"
                    flat
                    large
                    class="primary--text text-none"
                    @click="hideDialog"
                  >
                    {{ cancelingText }}
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-btn
                    block
                    :data-qa="`${dashify(dataQaKey)}-edit`"
                    :loading="updateLoading"
                    large
                    color="primary"
                    class="text-none"
                    @click="saveChanges"
                    :disabled="!valueChanged"
                  >
                    {{ updatingText }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-container>
        </v-layout>
      </v-container>
    </v-layout>
  </v-navigation-drawer>
</template>
<script>
import dashify from 'dashify';
import { HeaderHeight } from '@/mixins/HeaderHeight';

export default {
  name: 'DataGridDrawer',
  props: {
    showDialog: Boolean,
    header: String,
    valueChanged: Boolean,
    updateButtonText: String,
    updateLoading: {
      required: false,
      default: false,
      type: Boolean,
    },
    cancelButtonText: String,
    dataQaKey: String,
  },
  mixins: [HeaderHeight],
  computed: {
    updatingText: function () {
      if (
        this.updateButtonText === undefined ||
        this.updateButtonText === null ||
        this.updateButtonText === ''
      ) {
        return this.$t('grid.drawer.update');
      }
      return this.updateButtonText;
    },
    cancelingText: function () {
      if (
        this.cancelButtonText === undefined ||
        this.cancelButtonText === null ||
        this.cancelButtonText === ''
      ) {
        return this.$t('grid.drawer.cancel');
      }
      return this.cancelButtonText;
    },
    hasScrollableContent() {
      return !!this.$slots.scrollableContent;
    },
    hasStaticContent() {
      return !!this.$slots.staticContent;
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    dashify,
    runShowDialog() {
      this.dialog = this.showDialog;
    },
    hideDialogOnClick(payload) {
      if ((payload === undefined || !payload) && this.dialog) {
        this.hideDialog();
      }
    },
    hideDialog() {
      this.dialog = false;
      this.$emit('close-dialog');
    },
    saveChanges() {
      this.$emit('drawer-update-action');
    },
    isModified() {
      return true;
    },
    keyUpHandler(event) {
      if (event.code === 'Escape') {
        this.hideDialog();
      }
    },
    focusInDialog() {
      if (this.showDialog) {
        this.$nextTick(() => {
          this.$refs.heading.focus();
        });
      }
    },
  },
  watch: {
    showDialog() {
      this.runShowDialog();
      this.focusInDialog();
    },
  },
  mounted() {
    window.addEventListener('keyup', this.keyUpHandler);
  },
  destroyed() {
    window.removeEventListener('keyup', this.keyUpHandler);
  },
};
</script>
<style lang="scss" scoped>
.edit-dialog {
  position: fixed;
  z-index: 5;
}

.scrollable {
  height: 135px;
  max-height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 12px;
}

.bottom-padding {
  padding-bottom: 99px !important;
  height: 150px;
}
</style>
