const actions = {
  setLinkAssetFilter: ({ commit }, payload) => {
    commit('SET_LINK_ASSET_FILTER', payload);
  },
  setTotalLinkAsset: ({ commit }, payload) => {
    commit('SET_TOTAL_LINK_ASSET', payload);
  },
  setSearchQuery: ({ commit }, payload) => {
    commit('SET_SEARCH_QUERY', payload);
  },
  setPaginationQuery({ commit }, payload) {
    commit('SET_PAGINATION_QUERY', payload);
  },
  clearQueries({ commit }) {
    commit('SET_PAGINATION_QUERY', { data: {} });
  },
  refreshTable: ({ commit }) => {
    commit('REFRESH_TABLE');
  },
  setTotalFilteredLinkAsset: ({ commit }, payload) => {
    commit('SET_TOTAL_FILTERED_LINK_ASSET', payload);
  },
  clearFilterAndSearch: ({ commit }) => {
    commit('CLEAR_FILTER_AND_SEARCH');
  },
  addSelected: ({ commit }, payload) => {
    let newSelected = {};

    payload.data.forEach((vehicle) => {
      newSelected[vehicle[payload.key]] = {
        id: vehicle.vehicleId,
        vin:
          vehicle.displayName !== undefined &&
          vehicle.displayName !== '' &&
          vehicle.displayName !== null
            ? vehicle.displayName
            : vehicle.name !== undefined &&
              vehicle.name !== '' &&
              vehicle.name !== null
            ? vehicle.name
            : vehicle.vin,
        vinNumber: vehicle.vin,
        consentedConsumers:
          vehicle.consentedConsumers !== undefined
            ? vehicle.consentedConsumers
            : [],
        enrollmentStatus: vehicle.enrollmentStatus,
        name: vehicle.name,
        make: vehicle.make,
        model: vehicle.model,
        year: vehicle.year,
      };
    });

    commit('ADD_SELECTED', { data: newSelected });
  },
  removeSelected: ({ commit }, payload) => {
    commit('REMOVE_SELECTED', payload);
  },
  clearSelected: ({ commit }, payload) => {
    commit('CLEAR_SELECTED', payload);
  },
  closeLinkedAssetsDialog: ({ commit }, payload) => {
    commit('CLOSE_LINKED_ASSETS_DIALOG', payload);
  },
  resetState: ({ commit }) => {
    commit('RESET_STATE');
  },
  setDefaultFilterFlag: ({ commit }, payload) => {
    commit('SET_DEFAULT_FILTER_FLAG', payload);
  },
  setExistingVehicleLinks: ({ commit }, payload) => {
    commit('SET_EXISTING_VEHICLE_LINKS', payload);
  },
};

export { actions };
