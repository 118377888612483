const state = {
  checkedAgreeTermsAndConditions: false,
  hasFetchedTermsAndConditions: false,
  hasSignedFFMTermsAndConditions: false,
  hasSignedProductTermsAndConditions: false,
  canViewOnboardPage: false,
  productTermsAndConditionsMap: {},
  latestTermsAndConditions: {
    hasSigned: false,
    legalLanguage: '',
    llid: '',
    legalLanguageByLlidId: '',
    effectiveStartDate: '',
    impersonate: false,
  },
};

export { state };
