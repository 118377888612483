const state = {
  rfidFilter: {},
  totalRFIDs: 0,
  totalFilteredRFIDs: 0,
  searchQuery: '',
  paginationQuery: {},
  blockAction: false,
};

export { state };
