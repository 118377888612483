const getters = {
  getIsLoggedIn: (state) => state.isLoggedIn,
  getUserInfo: (state) => {
    return {
      language: state.language,
      companyInformationResponse: {
        id: state.companyId,
        name: state.companyName,
        billingId: state.companyBillingId,
        countryCode: state.companyCountryCode,
        companyType: state.companyType,
      },
      applications: state.applications,
      authorities: state.authorities,
      firstName: state.userFirstName,
      lastName: state.userLastName,
      userId: state.userId,
      email: state.userEmail,
      primaryPhone: state.primaryPhone,
      employeeId: state.employeeId,
      impersonate: state.impersonate,
    };
  },
  getExpiryTime: (state) => state.expires_in,
  getLanguage: (state) => state.language,
  getContactUsUrl: (state) => {
    let domain = '';
    let languageCode = '';
    switch (state.language) {
      case 'en-CA':
      case 'fr-CA':
        domain = 'ca';
        languageCode = state.language.toLowerCase();
        break;
      default:
        domain = 'com';
        languageCode = 'en-us';
    }
    return `https://www.fordpro.${domain}/${languageCode}/contact-us/`;
  },
  getUserInitials: (state) => {
    let firstName = state.userFirstName == null ? '' : state.userFirstName;
    let lastName = state.userLastName == null ? '' : state.userLastName;
    return firstName.substr(0, 1).concat(lastName.substr(0, 1));
  },
  getUserId: (state) => state.userId,
  getUserEmail: (state) => state.userEmail,
  getCompanyId: (state) => state.companyId,
  getCompanyName: (state) => state.companyName,
  getCompanyCountryCode: (state) => state.companyCountryCode,
  getCompanyType: (state) => state.companyType,
  // use fetchCompanyAccountLevelProductFamilies directly which uses cache
  getCompanyAccountLevelProductFamilies: (state) =>
    state.companyAccountLevelProductFamilies,
  // use fetchCompanyVehicleLevelProductFamilies directly which uses cache
  getCompanyVehicleLevelProductFamilies: (state) =>
    state.companyVehicleLevelProductFamilies,
  getAuthorities: (state) => state.authorities,
  getUserLanguages: (state) => state.userLanguages,
  getUserCountries: (state) => state.userCountries,
  getIso2to3countryCodeMap: (state) => state.iso2to3countryCodeMap,
  getIso3to2countryCodeMap: (state) => state.iso3to2countryCodeMap,
  getBillingId: (state) => state.companyBillingId,
  getApplications: (state) => state.applications,
  isFirstTimeLogin: (state) => state.isFirstTimeLogin,
  getUserCountryToIso2Code: (state) => {
    if (Object.keys(state.iso3to2countryCodeMap).length > 0) {
      let countryCode = state.iso3to2countryCodeMap[state.companyCountryCode];
      if (countryCode !== null && countryCode !== undefined) {
        return countryCode;
      }
    }
    return 'US';
  },
  getGetStartedPdfUrl: (state) => state.getStartedPdfUrl,
  canViewProducts: (state) => () => {
    return hasAuthority(state, 'PRODUCTS_SIDEBAR_VIEW');
  },
  canViewVehicles: (state) => () => {
    return hasAuthority(state, 'VEHICLES_SIDEBAR_VIEW');
  },
  canViewEquipment: (state) => () => {
    return hasAuthority(state, 'EQUIPMENT_SIDEBAR_VIEW');
  },
  canViewDecommission: (state) => () => {
    return hasAuthority(state, 'EQUIPMENT_DELETE');
  },
  canViewAddVehicles: (state) => () => {
    return hasAuthority(state, 'ADD_VEHICLES_SIDEBAR_VIEW');
  },
  canViewUsers: (state) => () => {
    return hasAuthority(state, 'USERS_SIDEBAR_VIEW');
  },
  canViewGroups: (state) => () => {
    return hasAuthority(state, 'SEGMENT_SIDEBAR_VIEW');
  },
  canViewBilling: (state) => () => {
    return hasAuthority(state, 'BILLING_SIDEBAR_VIEW');
  },
  canViewVehicleConsent: (state) => () => {
    return hasAuthority(state, 'VEHICLE_CONSENT_SIDEBAR_VIEW');
  },
  canViewAddVehicleConsent: (state) => () => {
    return hasAuthority(state, 'ADD_VEHICLE_CONSENT_SIDEBAR_VIEW');
  },
  canViewDashboard: (state) => () => {
    return hasAuthority(state, 'OVERVIEW_SIDEBAR_VIEW');
  },
  canViewProductPurchasePage: (state) => () => {
    return hasAuthority(state, 'PRODUCT_ONE_TIME_PURCHASE_VIEW');
  },
  canViewTrashCanForOwnerConsumer: (state) => () => {
    return hasAuthority(state, 'TRASH_CAN_SIDEBAR_VIEW');
  },
  canViewTrashCanForOwner: (state) => () => {
    return hasAuthority(state, 'OWNER_TRASH_CAN_SIDEBAR_VIEW');
  },
  canViewAutoConsent: (state) => () => {
    return hasAuthority(state, 'AUTO_CONSENT_VIEW');
  },
  canAddVehicle: (state) => () => {
    return hasAuthority(state, 'VEHICLE_CREATE');
  },
  canLinkEquipment: (state) => () => {
    return hasAuthority(state, 'EQUIPMENT_LINK_ASSET');
  },
  canViewSwagger: (state) => () => {
    return hasAuthority(state, 'SWAGGER_READ');
  },
  canViewDriverAppEnabledSetting: (state) => () => {
    return hasAuthority(state, 'SETTINGS_KEY_driver_app_enabled_READ');
  },
  canUpdateDriverAppEnabledSetting: (state) => () => {
    return hasAuthority(state, 'SETTINGS_KEY_driver_app_enabled_UPDATE');
  },
  canViewBillingInvoice: (state) => () => {
    return hasAuthority(state, 'BILLING_INVOICE_READ');
  },
  canViewBillingVinDetails: (state) => () => {
    return hasAuthority(state, 'BILLING_VIN_DETAILS_READ');
  },
  canViewBillingFile: (state) => () => {
    return hasAuthority(state, 'BILLING_FILE_READ');
  },
  canViewOwnerConsumerConsent: (state) => () => {
    return hasAuthority(state, 'OWNER_CONSUMER_CONSENT_SIDEBAR_VIEW');
  },
  canViewMarkForDelete: (state) => () => {
    return hasAuthority(state, 'VEHICLE_DELETE');
  },
  canViewDriverFlag: (state) => () => {
    return hasAuthority(state, 'DRIVER_FLAG_VIEW');
  },
  needsToSignTermsAndConditions: (state) => {
    return hasAuthority(state, 'TERMS_AND_CONDITIONS_SIGN');
  },
  canBillingPayMyBill: (state) => hasAuthority(state, 'BILLING_PAY_MY_BILL'),
  canViewSignedContracts: (state) => () => {
    return hasAuthority(state, 'SIGNED_CONTRACTS_READ');
  },
  canViewFtUpgrade: (state) => () => {
    return hasAuthority(state, 'FT_UPGRADE_PRODUCT');
  },
  canReadBillingAddress: (state) => () => {
    return hasAuthority(state, 'BILLING_ADDRESS_READ');
  },
  canConfirmVehicleOwnership: (state) => {
    return hasAuthority(state, 'OWNERSHIP_CONFIRMATION_UPDATE');
  },
  canViewHomeChargingDetails: (state) => () => {
    return hasAuthority(state, 'HOME_CHARGING_DETAILS_VIEW');
  },
  canReadAgreement: (state) => (type) => {
    type = type.toUpperCase().replaceAll(' ', '_');
    return hasAuthority(state, `CONSENT_${type}_READ`);
  },
  canReadUserActionLog: (state) => () => {
    return hasAuthority(state, 'USER_ACTION_LOG_READ');
  },

  canViewBillingInformationSettings: (state, _getters) => () => {
    return (
      _getters.canReadBillingAddress() &&
      _getters.getBillingId !== undefined &&
      _getters.getBillingId !== '' &&
      _getters.getBillingId !== null
    );
  },
  canViewCompanySettings: (state, _getters) => () => {
    return _getters.canViewBillingInformationSettings();
  },
  canViewDriverIdSettings: (state) => () => {
    return hasAuthority(state, 'DRIVER_ID_POLICY');
  },
  canViewComplementaryProduct: (state) => () => {
    return hasAuthority(state, 'COMPLEMENTARY_PRODUCT_READ');
  },
  canSignTermsAndConditions: (state) => {
    return hasAuthority(state, 'CAN_SIGN_T_AND_C');
  },
  canReadLegalLanguage: (state) => () => {
    return hasAuthority(state, 'LEGAL_LANGUAGE_READ');
  },
  getCompanyProducts: (state) => {
    return state.companyProducts;
  },
  canViewFordPayStripeElements: (state) => {
    return hasAuthority(state, 'FORD_PAY_STRIPE_PAYMENT_FEATURE');
  },
  canViewPendingPayments: (state) => {
    return hasAuthority(state, 'VIEW_PENDING_PAYMENTS');
  },
  // use fetchHasProduct directly which uses cache
  hasProduct: (state) => (productSku) => {
    return state.companyProducts.hasOwnProperty(productSku);
  },
  // use fetchHasProductInList directly which uses cache
  hasProductInList: (state) => (productSkuList) => {
    return productSkuList.some((productSku) =>
      state.companyProducts.hasOwnProperty(productSku)
    );
  },
  // use fetchHasProductFamily directly which uses cache
  hasProductFamily: (state) => (productFamily) => {
    return productFamily.some((item) =>
      state.companyProductFamilies.hasOwnProperty(item)
    );
  },
  canViewAutoSubscribeSettings: (state) => () => {
    return hasAllAuthorities(state, [
      'SETTINGS_KEY_auto_subscribe_products_READ',
      'SETTINGS_KEY_auto_subscribe_products_CREATE',
      'SETTINGS_KEY_auto_subscribe_enabled_READ',
      'SETTINGS_KEY_auto_subscribe_enabled_CREATE',
    ]);
  },
  companyCountryCode: (state) => {
    return state.companyCountryCode;
  },
};

function hasAuthority(state, authorityToCheck) {
  return state.authorities.some((authority) => authority === authorityToCheck);
}

function hasAllAuthorities(state, authoritiesToCheck) {
  return authoritiesToCheck.every((authority) =>
    state.authorities.includes(authority)
  );
}

export { getters };
