var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SpinnerWrapper',{attrs:{"is-loading":_vm.isLoading}},[_c('v-container',{attrs:{"pa-0":""}},[_c('h2',{staticClass:"headline primary--text multi-product-selection-header text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('productUpgradeStepper.productSelectionTitle')))]),_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t('productUpgradeStepper.productSelectionSubtitle')))]),_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.getProductFamilies),function(productFamily){return _c('v-flex',{key:productFamily.id,attrs:{"xl6":"","lg6":"","md12":"","sm12":"","xs12":""}},[_c('v-card',{class:[
            'product-item card-height',
            'ma-2',
            _vm.getSelected[productFamily.id] && 'selected-product' ]},[_c('v-layout',{staticClass:"card-height pa-2 ma-1",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs7":"","l7":""}},[_c('v-layout',{attrs:{"column":"","fill-height":""}},[_c('div',[_c('v-checkbox',{staticClass:"ml-1",attrs:{"color":"primary","label":_vm.$tf(
                        'productFamily.' + productFamily.id,
                        productFamily.name
                      ),"disabled":_vm.productFamilyHasUnSelectedDependencies(
                        productFamily.dependencyProductFamilyIds
                      ),"data-qa":_vm.dashify(("product-checkbox-" + (productFamily.id)))},on:{"change":function($event){return _vm.handleProductFamilySelection($event, productFamily)}},model:{value:(_vm.getSelected[productFamily.id]),callback:function ($$v) {_vm.$set(_vm.getSelected, productFamily.id, $$v)},expression:"getSelected[productFamily.id]"}})],1),(productFamily.dependencyProductFamilyIds.length > 0)?_c('div',{staticClass:"ml-1 mb-2"},[_c('span',{staticClass:"caption text--secondary price-align mb-0",attrs:{"data-qa":_vm.dashify(
                        ("product-required-dependency-" + (productFamily.id))
                      )}},[_vm._v(" "+_vm._s(_vm.retrieveProductFamilyDependencyText(productFamily))+" ")])]):_vm._e(),_c('div',{staticClass:"ml-1"},[(
                      productFamily.currencyCode == null ||
                      productFamily.price == null
                    )?_c('div',[_c('span',{staticClass:"subheading text--primary price-align mb-0",attrs:{"data-qa":_vm.dashify(("price-complimentary-" + (productFamily.id)))}},[_vm._v(" "+_vm._s(_vm.$t('products.complimentaryPrice'))+" ")])]):_c('div',[_c('span',{staticClass:"subheading text--primary price-align mb-0",attrs:{"data-qa":_vm.dashify(("price-" + (productFamily.id)))}},[_vm._v(" "+_vm._s(_vm.coinify(productFamily.currencyCode))+_vm._s(productFamily.price)+" ")]),_c('br'),_c('span',{staticClass:"caption text--secondary price-align mb-0",attrs:{"data-qa":_vm.dashify(("price-description-" + (productFamily.id)))}},[_vm._v(" "+_vm._s(((_vm.$t('products.priceDescription1')) + " " + (_vm.$t( 'products.priceDescription2' ))))+" ")])])])])],1),_c('v-flex',{attrs:{"xs5":"","l5":""}},[_c('v-img',{staticClass:"product-image",attrs:{"src":_vm.getImageUrl(productFamily.imageUrl),"alt":_vm.$t(("products.images." + (_vm.dashify(productFamily.imageUrl)) + ".alt")),"data-qa":("image-" + (_vm.dashify(productFamily.imageUrl)))}}),_c('link-style-button',{staticClass:"ml-1 caption description-container",attrs:{"data-qa":_vm.dashify(("view-product-description-" + (productFamily.id)))},on:{"click":function($event){return _vm.handleReadMoreClick(productFamily)}}},[_vm._v(" "+_vm._s(_vm.$t('products.viewDescription'))+" ")])],1)],1)],1)],1)}),1),(_vm.selectedLength > 0)?_c('v-layout',{staticClass:"mt-4",attrs:{"row":"","wrap":"","align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"align-self-center":"","shrink":""}},[_c('div',{staticClass:"mr-2",attrs:{"data-qa":"selected-count","id":"selected-count"}},[_c('v-chip',{attrs:{"label":"","color":"primary","text-color":"white","small":"","data-qa":"v-chip-text"}},[_vm._v(" "+_vm._s(_vm.selectedLength)+" ")]),_c('span',{staticClass:"selected-count-text"},[_vm._v(_vm._s(_vm.$tc('products.selected', _vm.selectedLength)))])],1)])],1):_vm._e(),_c('ConfirmationDialog',{attrs:{"title":("" + (_vm.$t('products.description', {
        productFamilyName: _vm.productDisplayed.name,
      }))),"message":_vm.$t(("products.descriptions." + (_vm.productDisplayed.id))),"cancellation-text":"Cancel","display-confirm":false,"enable-dialog":_vm.showReadMoreDialog,"data-qa-key":"view-product-description","hide-content":"","persistent":""},on:{"cancel":function($event){_vm.showReadMoreDialog = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.showReadMoreDialog = false}}})],1),_c('ErrorDialog',{attrs:{"error":this.error,"show-dialog":_vm.showErrorDialog},on:{"close-dialog":function($event){_vm.showErrorDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }