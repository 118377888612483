<template>
  <div>
    <v-icon
      data-qa="vehicle-status-icon-success"
      v-if="['ENROLLED', 'SUBSCRIBED'].includes(status)"
      :color="color ? color : 'success'"
      >ic-circle-check</v-icon
    >
    <v-icon
      data-qa="vehicle-status-icon-warning"
      v-else-if="
        [
          'PROCESSING_UNENROLLMENT',
          'PROCESSING_ENROLLMENT',
          'PROCESSING',
        ].includes(status)
      "
      :color="color ? color : 'warning'"
      >ic-clock
    </v-icon>
    <v-icon
      data-qa="vehicle-status-icon-error"
      v-else-if="
        ['UNENROLLMENT_ALERT', 'ENROLLMENT_ALERT', 'ACTION'].includes(status)
      "
      :color="color ? color : 'error'"
      >ic-alert
    </v-icon>
    <span v-if="displayStatusText" data-qa="status-text" class="statusText">
      {{ statusText }}
    </span>
  </div>
</template>

<script>
import { AccountConstants } from '@/constants';

export default {
  name: 'VehicleStatusIcon',
  props: {
    status: String,
    color: String,
    displayStatusText: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    t(m) {
      return this.$t(m);
    },
  },
  computed: {
    statusText() {
      if (this.status === AccountConstants.ENROLLMENT_STATUS_PROCESSING) {
        return this.t('trash.grid.enrollmentStatus.processingUnsubscribe');
      } else if (this.status === AccountConstants.ENROLLMENT_STATUS_ACTION) {
        return this.t('trash.grid.enrollmentStatus.unableToUnsubscribe');
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.statusText {
  opacity: 0.5;
}
</style>
