const state = {
  selectedVehicles: {},
  selectedVehiclesCount: 0,
};

const mockState = function () {
  return {
    selectedVehicles: {},
    selectedVehiclesCount: 0,
  };
};

export { state, mockState };
