<template>
  <v-select
    :data-qa="`${name}`"
    @click:clear="showChips = false"
    v-model="selectedItems"
    :disabled="items.length === 0 || userPrimary"
    :items="filteredItems"
    :item-value="itemValue"
    item-text="name"
    :label="label"
    :hint="hint"
    persistent-hint
    clearable
    multiple
    chips
    @change="$emit('dropdown-value-changed', selectedItems)"
  >
    <template v-slot:prepend-item>
      <v-list-tile>
        <v-list-tile-content>
          <v-text-field
            :data-qa="`${name}-search`"
            label="Search"
            v-model="searchInput"
            outlined
          ></v-text-field>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-btn
            icon
            :data-qa="`${name}-search-clear-icon`"
            @click="clearSearch"
          >
            <v-icon color="primary">ic-close</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
      <v-list-tile
        :data-qa="`${name}-select-all`"
        @click="toggleSelection"
        ripple
      >
        <v-list-tile-action>
          <v-icon color="primary">{{ selectAllItemsIcon }}</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ selectAllLabel }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-slot:selection="{ item, index }">
      <v-chip v-if="index <= chipCount - 1 || showChips" :color="color">
        <span :data-qa="`${name}-all-selected`" :class="textColor">
          {{ trimName(item.name) }}
        </span>
      </v-chip>
      <span
        :data-qa="`${name}-x-others`"
        v-if="index === chipCount && !showChips"
        class="text--secondary caption"
        @click.stop="showChips = true"
        tabindex="0"
        @keypress.enter="showChips = true"
      >
        (+{{ selectedItems.length - chipCount }} {{ $t('dropdown.others') }})
      </span>
    </template>
    <template v-slot:item="items">
      <v-list-tile
        :data-qa="`${name}-item-${items.item[itemValue]}`"
        @click="toggleItem(items.item)"
        ripple
      >
        <v-list-tile-action>
          <v-checkbox
            :data-qa="`${name}-checkbox-item-${items.item[itemValue]}`"
            color="primary"
            :input-value="isSelected(items.item)"
          />
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ items.item.name }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </template>
  </v-select>
</template>

<script>
import Vue from 'vue';
import { get } from 'vuex-pathify';

export default {
  name: 'MultiSelectDropdown',
  props: {
    name: String,
    chipCount: Number,
    itemValue: {
      type: String,
      default: 'id',
    },
    model: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectAllLabel: String,
    hint: String,
    label: String,
    color: String,
    textColor: String,
  },
  data: function () {
    return {
      searchInput: '',
      showChips: false,
      selectedItems: [],
    };
  },
  watch: {
    model: {
      handler: function () {
        this.selectedItems = Vue.util.extend([], this.model);
      },
      immediate: true,
    },
    selectedItems() {
      this.clearSearch();
    },
  },
  methods: {
    clearSearch() {
      // clearing the search causes a side effect
      // of selecting another item in the list (an item that was not selected).
      // I found that delaying to clear the search fixes the issue.
      setTimeout(() => {
        this.searchInput = '';
      }, 10);
    },
    toggleSelection() {
      this.$nextTick(() => {
        if (this.allItemsSelected) {
          this.selectedItems = [];
          this.showChips = false;
        } else {
          this.selectedItems = this.items.map((v) => {
            return v[this.itemValue];
          });
        }
        this.$emit('dropdown-value-changed', this.selectedItems);
      });
    },
    trimName(name) {
      if (name.length > 12 && !this.showChips) {
        return name.slice(0, 12) + '…';
      } else {
        return name;
      }
    },
    isSelected(item) {
      return this.selectedItems.includes(item[this.itemValue]);
    },
    toggleItem(item) {
      if (this.isSelected(item)) {
        this.selectedItems = this.selectedItems.filter(
          (id) => id !== item[this.itemValue]
        );
      } else {
        this.selectedItems.push(item[this.itemValue]);
      }
      this.$emit('dropdown-value-changed', this.selectedItems);
    },
  },
  computed: {
    filteredItems() {
      if (!this.searchInput) {
        return this.items;
      }
      return this.items.filter((item) =>
        item.name.toLowerCase().includes(this.searchInput.toLowerCase())
      );
    },
    allItemsSelected() {
      return this.selectedItems.length === this.items.length;
    },
    someItemsSelected() {
      return this.selectedItems.length > 0 && !this.allItemsSelected;
    },
    selectAllItemsIcon() {
      if (this.allItemsSelected) return 'check_box';
      if (this.someItemsSelected) return 'indeterminate_check_box';
      return 'check_box_outline_blank';
    },
    ...get('userEditDetails', {
      userPrimary: 'userDetails@primary',
    }),
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field .v-input__slot {
  height: initial !important;
  border: initial !important;
}
</style>
