const actions = {
  setPaginationQuery: ({ commit }, payload) => {
    commit('SET_PAGINATION_QUERY', payload);
  },
  setDateToViewInvoice: ({ commit }, payload) => {
    commit('SET_DATE_TO_VIEW_INVOICE', payload);
  },
  setStatementNumber: ({ commit }, payload) => {
    commit('SET_STATEMENT_NUMBER', payload);
  },
  setBillingActionInProgress: ({ commit }, payload) => {
    commit('SET_BILLING_ACTION_IN_PROGRESS', payload);
  },
  setPaymentClientSecret: ({ commit }, payload) => {
    commit('SET_PAYMENT_CLIENT_SECRET', payload);
  },
  setPaymentClient: ({ commit }, payload) => {
    commit('SET_PAYMENT_CLIENT', payload);
  },
  setPaymentElementLoaded: ({ commit }, payload) => {
    commit('SET_PAYMENT_ELEMENT_LOADED', payload);
  },
  setPaymentElement: ({ commit }, payload) => {
    commit('SET_PAYMENT_ELEMENT', payload);
  },
  setPaymentEnabledCurrencies: ({ commit }, payload) => {
    commit('SET_PAYMENT_ENABLED_CURRENCIES', payload);
  },
};

export { actions };
