const uuid = require('uuid/v1');

const mutations = {
  ADD_NOTIFICATION(state, notification) {
    state.queue.push({
      ...notification,
      type: notification.type,
      id: notification.id === undefined ? uuid() : notification.id,
    });
  },
  REMOVE_NOTIFICATION(state, id) {
    let ixFound = state.queue.findIndex((e) => e.id === id);
    if (ixFound >= 0) {
      state.queue.splice(ixFound, 1);
    }
  },
};

export { mutations };
