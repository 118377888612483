import AccountApiProxyService from '@/services/AccountApiProxyService';
import { AccountConstants } from '@/constants';

export default {
  getChurnReasons() {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH + '/churn'
    );
  },
};
