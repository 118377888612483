const actions = {
  setFtEnabled: ({ commit }, payload) => commit('SET_FT_ENABLED', payload),
  setOtherEnabled: ({ commit }, payload) =>
    commit('SET_OTHER_ENABLED', payload),
  setAllSettings: ({ commit }, payload) => commit('SET_ALL_SETTINGS', payload),
  setSelectedReason: ({ commit }, payload) =>
    commit('SET_SELECTED_REASON', payload),
  setFtSelection: ({ commit }, payload) => commit('SET_FT_SELECTION', payload),
  setSelectedOtherSkus: ({ commit }, payload) =>
    commit('SET_SELECTED_OTHER_SKUS', payload),
  setAdditionalReasonDescription: ({ commit }, payload) =>
    commit('SET_ADDITIONAL_REASON_DESCRIPTION', payload),
};

export { actions };
