<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="data-table" :class="isBillingPage ? 'billing-table' : ''">
    <v-data-table
      select-all
      :hide-actions="disablePagination"
      :items="pagination.results"
      :item-key="rowKey"
      :headers="columns"
      :loading="loading"
      :no-data-text="
        loading ? $t('grid.noDataLoadingText') : $t('grid.noDataDefaultText')
      "
      :pagination.sync="gridPagination"
      :rows-per-page-text="$t('rowsPerPage')"
      :rows-per-page-items="rowsPerPageItems"
      :total-items="pagination.resultSize"
      :aria-label="$t('common.loading')"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <template v-slot:headers="props">
        <tr>
          <th
            data-qa="select-all-checkbox"
            v-if="selectable"
            :id="`${uniqueId}-${storeModule}-select-all`"
          >
            <v-checkbox
              class="data-grid-select-all"
              :aria-label="$t('grid.checkBox.selectAll.label')"
              :input-value="allSelected"
              :indeterminate="someSelected"
              color="primary"
              hide-details
              @change="toggleAll"
            />
          </th>
          <DataGridHeader
            v-for="column in visibleColumns"
            :key="column.headerKey"
            :column-name="column.pageSort"
            :column-header="column.headerText"
            :sortable="column.sortable"
            @toggle-sort-direction="changeSort(column.pageSort)"
            :pagination="gridPagination"
            :classes="column.headerClasses"
            :unique-id="uniqueId"
          >
            <template v-slot:default>
              {{ column.headerText }}
            </template>
            <template v-slot:action>
              <component :is="column.headerFilterRender" />
            </template>
          </DataGridHeader>
        </tr>
      </template>
      <template v-slot:items="props">
        <tr
          :data-qa="`grid-row${
            rowKey ? '-' + dashify(props.item[rowKey].toString()) : ''
          }`"
          :active="greyOutSelection(props.item[rowKey])"
          @click="rowAction(props)"
          class="px-2"
        >
          <td v-if="selectable">
            <v-checkbox
              data-qa="data-grid-row-select"
              :aria-label="rowLabel(props.item, props.index)"
              class="data-grid-select-box"
              :disabled="isDisabled(props.item)"
              :input-value="isSelected(props.item[rowKey])"
              color="primary"
              hide-details
              @click.native.stop
              @change="toggleSelected(props)"
            />
          </td>
          <DataGridItem
            v-for="(column, index) in visibleColumns"
            :key="
              dashify(
                rowKey ? '-' + dashify(props.item[rowKey].toString()) : ''
              ) +
              '-' +
              props.item[column.headerKey] +
              '-' +
              index
            "
            :column-name="column.headerKey"
            :classes="column.itemClasses"
          >
            <template v-slot:default v-if="!column.hideItem">
              <template v-if="column.hasToolTip">
                {{ props.item[column.headerKey].length }}
                <DataGridItemTooltip
                  :items="itemExtraction(props.item, column)"
                  :name="column.headerKey"
                  :internationalizeRoot="column.internationalizeRoot"
                  :show="column.hasToolTip"
                  :translate="column.internationalize"
                />
              </template>
              <template v-else-if="column.isDateField === true">
                {{ formatDate(props.item[column.headerKey]) }}
              </template>
              <template v-else-if="column.isBillingDateField === true">
                {{
                  formatModifiedDateReturnEmptyStringIfNotExist(
                    props.item[column.headerKey]
                  )
                }}
              </template>
              <template v-else-if="column.isModifiedDateField === true">
                {{ formatModifiedDate(props.item[column.headerKey]) }}
              </template>
              <template
                v-else-if="
                  column.internationalize &&
                  props.item[column.headerKey] !== '' &&
                  props.item[column.headerKey] != null
                "
              >
                {{
                  $tf(
                    column.internationalizeRoot + props.item[column.headerKey],
                    props.item[column.headerKey]
                  )
                }}
              </template>
              <template v-else-if="column.currency">
                {{
                  formatAmount(props.item[column.headerKey], column.currency)
                }}
              </template>
              <template v-else>
                {{ props.item[column.headerKey] }}
              </template>
            </template>
            <template v-slot:action>
              <component :is="column.itemActionRender" :item="props.item" />
            </template>
          </DataGridItem>
        </tr>
      </template>
    </v-data-table>
    <slot v-bind:dialog="showEditDialog" v-bind:data="data" name="drawer">
    </slot>
  </div>
</template>

<script>
import DataGridItem from '@/components/grid/DataGridItem';
import DataGridHeader from '@/components/grid/DataGridHeader';
import { parse } from 'query-string';
import dashify from 'dashify';
import moment from 'moment';
import DataGridItemTooltip from '@/components/grid/DataGridItemTooltip';
import { format } from '@/currency';
import { mapActions } from 'vuex';

export default {
  name: 'DataGrid',
  components: {
    DataGridItemTooltip,
    DataGridItem,
    DataGridHeader,
  },
  data: function () {
    return {
      gridPagination: {
        page: 1,
        descending: this.defaultSortDescending,
        sortBy: this.defaultSort,
        rowsPerPage: this.disablePagination ? -1 : 25,
        rowsPerPageItems: 25,
      },
      allSelected: false,
      someSelected: false,
      data: {},
      showEditDialog: false,
    };
  },
  props: {
    columns: Array,
    pagination: Object,
    defaultSort: {
      type: String,
    },
    rowKey: String,
    disableKey: String,
    rowsPerPageItems: Array,
    storeModule: String,
    baseBreadcrumb: Object,
    selectable: {
      type: Boolean,
      default: false,
    },
    hasDrawer: {
      type: Boolean,
      default: false,
    },
    hasFullPage: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    defaultSortDescending: {
      type: Boolean,
      default: false,
    },
    isBillingPage: {
      type: Boolean,
      default: false,
    },
    isLinkedAssetTilePopup: {
      type: Boolean,
      default: false,
    },
    uniqueId: String,
  },
  methods: {
    rowLabel(item, index) {
      const prefix = this.$t('grid.checkBox.row.labelWithIndex', {
        rowIndex: index + 1,
      });
      if (item.rowLabel) {
        return `${prefix}, ${item.rowLabel}`;
      } else {
        return prefix;
      }
    },
    itemExtraction(item, column) {
      return column.itemsExtractor !== undefined
        ? column.itemsExtractor(item)
        : item[column.headerKey];
    },
    dashify,
    parse,
    setPaginationQuery(query) {
      if (this.storeModule) {
        this.$store.dispatch(`${this.storeModule}/setPaginationQuery`, {
          data: query,
        });
      }
    },
    rowAction(props) {
      if (this.hasDrawer) {
        this.openEditDialog(props.item);
      } else if (this.hasFullPage) {
        this.pushBreadcrumbs(this.baseBreadcrumb);
        this.openDetails(props.item);
      } else if (this.selectable || this.isLinkedAssetTilePopup) {
        this.toggleSelected(props);
      }
    },
    toggleSelected(props) {
      let item = props.item;
      if (this.isDisabled(item)) {
        return;
      }
      let selected = !this.isSelected(item[this.rowKey]);
      if (selected) {
        if (this.isLinkedAssetTilePopup) {
          this.clearSelected();
        }
        this.addSelected([item]);
      } else {
        this.removeSelected([item[this.rowKey]]);
      }
      this.updateAllSelected();
      this.updateSomeSelected(true);
    },
    toggleAll(isAlreadyChecked) {
      let itemsToAdd = [];
      let itemsToRemove = [];

      if (!isAlreadyChecked) {
        for (let item of Object.keys(this.pagination.results)) {
          let theItem = this.pagination.results[item];
          if (
            !this.isDisabled(theItem) &&
            this.isSelected(theItem[this.rowKey])
          ) {
            itemsToRemove.push(theItem[this.rowKey]);
          }
        }
        this.removeSelected(itemsToRemove);
      } else {
        for (let item2 of Object.keys(this.pagination.results)) {
          let theItem2 = this.pagination.results[item2];
          if (
            !this.isDisabled(theItem2) &&
            !this.isSelected(theItem2[this.rowKey])
          ) {
            itemsToAdd.push(theItem2);
          }
        }
        this.addSelected(itemsToAdd);
      }

      this.updateAllSelected();
      this.updateSomeSelected(true);
    },
    addSelected(selected) {
      if (this.storeModule) {
        this.$store.dispatch(`${this.storeModule}/addSelected`, {
          data: selected,
          key: this.rowKey,
        });
      }
    },
    removeSelected(selectedIds) {
      if (this.storeModule) {
        this.$store.dispatch(`${this.storeModule}/removeSelected`, {
          data: selectedIds,
        });
      }
    },
    clearSelected() {
      if (this.storeModule) {
        this.$store.dispatch(`${this.storeModule}/clearSelected`);
      }
    },
    isSelected(key) {
      if (this.storeModule) {
        return this.$store.getters[`${this.storeModule}/isSelected`](key);
      }
    },
    isDisabled(item) {
      if (this.disableKey) {
        return item[this.disableKey];
      }
      return false;
    },
    updateAllSelected() {
      if (this.selectable && this.pagination.results) {
        for (let item of Object.keys(this.pagination.results)) {
          if (!this.isSelected(this.pagination.results[item][this.rowKey])) {
            this.allSelected = false;
            return false;
          }
        }
        this.allSelected = true;
        return true;
      }
    },
    updateSomeSelected(excludeDisabled) {
      if (this.selectable && this.pagination.results) {
        if (this.allSelected) return false;
        for (let itemId of Object.keys(this.pagination.results)) {
          if (excludeDisabled) {
            if (
              !this.isDisabled(this.pagination.results[itemId]) &&
              this.isSelected(this.pagination.results[itemId][this.rowKey])
            ) {
              this.someSelected = true;
              return true;
            }
          } else if (
            this.isSelected(this.pagination.results[itemId][this.rowKey])
          ) {
            this.someSelected = true;
            return true;
          }
        }
      }
      this.someSelected = false;
      return false;
    },
    getQuery(gridPagination) {
      return {
        page: gridPagination.page - 1,
        sort: [
          gridPagination.sortBy,
          gridPagination.descending ? 'desc' : 'asc',
        ].join(','),
        size: gridPagination.rowsPerPage,
      };
    },
    formatDate(date) {
      moment.locale(this.getLocale());
      if (!date) return '';
      if (date.length === 10) {
        return moment(new Date(date.split('-'))).format('DD-MMM-YYYY');
      } else {
        return moment(new Date(date)).format('DD-MMM-YYYY');
      }
    },
    formatModifiedDateReturnEmptyStringIfNotExist(date) {
      moment.locale(this.getLocale());
      if (!date) return '';
      return moment(new Date(date.toString().replace(/-/g, '/'))).format(
        'DD-MMM-YYYY'
      );
    },
    formatModifiedDate(date) {
      moment.locale(this.getLocale());
      if (!date) return this.$t('dataGrid.never');
      return moment(new Date(date)).calendar(null, { sameElse: 'DD-MMM-YYYY' });
    },
    formatAmount(value, currencyCode) {
      return format(value, currencyCode);
    },
    getLocale() {
      if (this.$i18n !== undefined && this.$i18n.locale !== undefined) {
        return this.$i18n.locale;
      }
      return 'en-US';
    },
    changeSort(key) {
      if (key === this.gridPagination.sortBy) {
        this.gridPagination.descending = !this.gridPagination.descending;
      } else {
        this.gridPagination.descending = false;
        this.gridPagination.sortBy = key;
      }
    },
    openEditDialog(data) {
      this.$emit('open-dialog', document.activeElement);
      this.data = data;
      this.showEditDialog = !this.showEditDialog;
    },
    openDetails(data) {
      this.$router.push({
        name: `${this.storeModule}Details`,
        params: {
          id: data.id,
        },
      });
    },
    greyOutSelection(row) {
      return (
        (this.selectable && this.isSelected(row)) ||
        (this.isLinkedAssetTilePopup && this.isSelected(row))
      );
    },
    ...mapActions('breadcrumb', ['pushBreadcrumbs']),
  },
  mounted() {
    if (this.rowsPerPageItems) {
      this.gridPagination.rowsPerPageItems = this.rowsPerPageItems;
      this.gridPagination.rowsPerPage = this.rowsPerPageItems[0];
    }
    if (this.columns && !this.defaultSort) {
      this.gridPagination.sortBy = this.columns.find((e) => {
        return e.sortable;
      }).pageSort;
    }
    if (this.hasDrawer) {
      this.$on('close-drawer', function () {
        this.showEditDialog = false;
      });
    }
  },
  watch: {
    gridPagination: {
      handler(pagination) {
        this.setPaginationQuery(this.getQuery(pagination));
      },
      deep: true,
    },
  },
  computed: {
    visibleColumns() {
      return this.columns.filter((column) => column.displayField);
    },
  },
};
</script>

<style lang="scss">
.active span {
  color: #2861a4 !important;
}

/* This is to override the very specific (and weird) vuetify css around tables */
.v-table.v-table.v-table {
  tr td,
  tr th {
    padding: 0 8px;
  }

  thead tr:first-child {
    border-bottom: 2px solid lightgrey;
  }
}

.data-table {
  width: 100%;
}

.billing-table {
  overflow: auto;
  max-height: 300px;
}
</style>
