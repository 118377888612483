<!--suppress CssInvalidPseudoSelector -->
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <h1 class="display-1" v-if="this.getIsLoggedIn">{{
      $t('sidebar.privacyPolicy')
    }}</h1>
    <h1 class="display-1" v-else>Privacy Policy</h1>
    <v-form>
      <v-layout row wrap class="pl-5">
        <p class="contentText">
          {{ $t('policy.privacy.preferredCountryLanguage') }}</p
        >
      </v-layout>
      <v-layout row wrap class="pl-5">
        <v-flex xs12 sm8 md5 class="pr-3">
          <v-select
            :items="countries"
            item-value="code"
            item-text="name"
            data-qa="country-code"
            :label="labels.countryPreference"
            v-model="selectedCountry"
            outline
            required
            @change="getPrivacyPolicy"
          >
            <template v-slot:item="data">
              <span :data-qa="'country-' + data.item.code">{{
                data.item.name
              }}</span>
            </template>
          </v-select>
        </v-flex>
        <v-flex xs12 sm8 md5 class="pr-3">
          <v-select
            :items="languages"
            item-value="code"
            item-text="name"
            data-qa="language-code"
            :label="labels.languagePreference"
            v-model="selectedLanguage"
            outline
            required
            @change="getPrivacyPolicy"
            :disabled="isLanguagePreference"
          >
            <template v-slot:item="data">
              <span :data-qa="'language-' + data.item.code">{{
                data.item.name
              }}</span>
            </template>
          </v-select>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="pl-5">
        <v-flex xs12 md6>
          <div v-if="!canShowPrivacyPolicyUri">
            <span data-qa="showLegalLanguage" v-html="legalLanguage"></span>
          </div>
          <div v-if="canShowPrivacyPolicyUri">
            <span class="contentText">
              {{ $t('policy.privacy.viewLink') }}</span
            >
            <a
              :href="accountConstants.PRIVACY_POLICY_URL.ENGLISH_US"
              class="language language-top"
              target="_blank"
              data-qa="usPrivacyPolicy"
            >
              {{ this.$t('privacyPolicy.languages[0].displayName') }}
            </a>
            <span v-if="canPrivacyPolicy">
              <a
                :href="accountConstants.PRIVACY_POLICY_URL.CANADA_FR"
                class="language language-top"
                target="_blank"
                data-qa="canFrPrivacyPolicy"
              >
                {{ this.$t('privacyPolicy.languages[1].displayName') }}
              </a>
            </span>
            <br /><br />
          </div>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import LocalityService from '@/services/LocalityService';
import PolicyService from '@/services/PolicyService';
import { mapGetters } from 'vuex';
import { AccountConstants } from '@/constants';

export default {
  name: 'PrivacyPolicy',
  mounted() {
    LocalityService.getLocals()
      .then((res) => {
        this.languages = res.data.languageList;
        this.countries = res.data.countryList;
        if (this.getIsLoggedIn) {
          this.selectedCountry = this.getCompanyCountryCode;
          this.selectedLanguage = this.getLanguage;
          this.getPrivacyPolicy();
        }
      })
      .catch((err) => console.log(err));
  },
  data: () => {
    return {
      languages: [],
      countries: [],
      selectedLanguage: '',
      selectedCountry: '',
      legalLanguage: '',
      isLanguagePreference: false,
      accountConstants: AccountConstants,
      canShowPrivacyPolicyUri: false,
      canPrivacyPolicy: false,
    };
  },
  methods: {
    getPrivacyPolicy() {
      if (
        this.selectedCountry === AccountConstants.USA_PRIVACY_POLICY ||
        this.selectedCountry === AccountConstants.CAN_PRIVACY_POLICY
      ) {
        this.selectedLanguage = '';
        this.isLanguagePreference = true;
        this.canShowPrivacyPolicyUri = true;
        this.canPrivacyPolicy =
          this.selectedCountry === AccountConstants.CAN_PRIVACY_POLICY;
      } else {
        this.isLanguagePreference = false;
        this.canShowPrivacyPolicyUri = false;
        if (this.selectedCountry !== '' && this.selectedLanguage !== '') {
          PolicyService.getPrivacyPolicy(
            this.selectedCountry,
            this.selectedLanguage
          )
            .then((res) => {
              this.legalLanguage = res.data.legalLanguage;
            })
            .catch((err) => {
              let tempLegalLanguage = this.$t(
                'policy.privacy.legalLanguageError'
              );
              this.legalLanguage = tempLegalLanguage;
              console.log(err);
            });
        }
      }
    },
  },
  computed: {
    labels() {
      let countryPreference = 'Country Preference';
      if (this.getIsLoggedIn) {
        countryPreference = this.$t('policy.privacy.countryPreference');
      }

      let languagePreference = 'Language Preference';
      if (this.getIsLoggedIn) {
        languagePreference = this.$t('policy.privacy.languagePreference');
      }

      return {
        countryPreference,
        languagePreference,
      };
    },
    ...mapGetters('auth', [
      'getIsLoggedIn',
      'getLanguage',
      'getCompanyCountryCode',
    ]),
  },
};
</script>

<style scoped>
h1 {
  padding: 1em;
}

.language {
  margin: 0 8px;
  color: #2861a4;
  font-size: 16px;
}
.contentText {
  height: 19px;
  width: 731px;
  color: #4d4d4d;
  font-size: 16px;
}
</style>
