import Vue from 'vue';
import AccountApiProxyService from '@/services/AccountApiProxyService';
import { AccountConstants } from '@/constants';

export default new Vue({
  methods: {
    confirmOwnership() {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/confirmation/type/ownership'
      );
    },
    getOwnershipConfirmation() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/confirmation/type/ownership'
      );
    },
  },
});
