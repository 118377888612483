import fpgConfiguration from '@/store/fpgConfiguration';
import { removeLeadingSlash } from '@/utils';
import configuration from '@/store/configuration';
import AuthService from './services/AuthService';
import AccountStaticService from '@/services/AccountStaticService';

function getModifiedPath(path) {
  if (typeof path === 'string') {
    return removeLeadingSlash(path);
  }
  return '';
}

function prependFleetAccount(path) {
  const modifiedPath = getModifiedPath(path);
  const prefixedAndModifiedPath =
    fpgConfiguration &&
    fpgConfiguration.state &&
    fpgConfiguration.state.isTileMode
      ? `fleet-account/${modifiedPath}`
      : modifiedPath;
  return '/' + prefixedAndModifiedPath;
}

export function getStaticAssetsBaseUrl() {
  return configuration.state.accountStaticBaseUrl;
}

function constructUrlIfValidAbsoluteUrl(urlString) {
  try {
    return new URL(urlString);
  } catch (e) {
    return null;
  }
}

function isFleetAccountUrl(urlString) {
  return urlString.includes(configuration.state.fleetAccountHost);
}

export async function routeToApp(appId, appUrl, countryCode) {
  if (isFleetAccountUrl(appUrl)) {
    AuthService.getPartnerTokenToRedirect(appId, countryCode)
      .then((res) => {
        window.open(res.data, '_blank');
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    window.open(appUrl, '_blank');
  }
}

export function getProxiedApplicationUrl(urlString, countryCode) {
  let url = constructUrlIfValidAbsoluteUrl(urlString);
  let currentlyOnFordProDomainProxyingToFleetAccountUrl =
    fpgConfiguration.state.isTileMode &&
    url?.host === configuration.state.fleetAccountHost;

  if (currentlyOnFordProDomainProxyingToFleetAccountUrl) {
    url.host = new URL(getFordProDomain(countryCode)).host;
    url.pathname = prependFleetAccount(url.pathname);
    return url.toString();
  }

  return replaceFordProUrlDomainBasedOnCountry(urlString, countryCode);
}

export function getFordProDomain(countryCode, fullUrl = true) {
  const domain = configuration.state.fordProDomains[countryCode];
  let fordProDomain;
  if (domain !== undefined) {
    fordProDomain = domain;
  } else {
    fordProDomain = configuration.state.fordProDomains['DEFAULT'];
  }

  if (fullUrl) {
    return configuration.state.fordProUrlSchemeAndSubDomain + fordProDomain;
  }
  return fordProDomain;
}

export function replaceFordProUrlDomainBasedOnCountry(url, countryCode) {
  const defaultDomain = configuration.state.fordProDomains['DEFAULT'];
  const domain = getFordProDomain(countryCode, false);
  if (domain !== defaultDomain) {
    return url.replace(defaultDomain, domain);
  }
  return url;
}

export async function verifyStaticAssetUrl(pdfUrl) {
  return AccountStaticService.get(pdfUrl)
    .then(() => getStaticAssetsBaseUrl() + pdfUrl)
    .catch((err) => {
      console.log(err);
      return '';
    });
}
