import { render, staticRenderFns } from "./PrivacyPolicy.vue?vue&type=template&id=1ba8d9da&scoped=true&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
import script from "./PrivacyPolicy.vue?vue&type=script&lang=js&"
export * from "./PrivacyPolicy.vue?vue&type=script&lang=js&"
import style0 from "./PrivacyPolicy.vue?vue&type=style&index=0&id=1ba8d9da&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ba8d9da",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VFlex,VForm,VLayout,VSelect})
