import CompanyService from '@/services/CompanyService';

const actions = {
  acknowledgeWelcomeDialog: ({ commit }, payload) =>
    commit('SET_FIRST_TIME_LOGIN_FALSE', payload),
  setIsLoggedIn: ({ commit }, payload) => commit('SET_IS_LOGGED_IN', payload),
  setUserInfo: ({ commit }, payload) => commit('SET_USER_INFO', payload),
  setTokenExpiry: ({ commit }, payload) => commit('SET_TOKEN_EXPIRY', payload),
  setLanguage: ({ commit }, payload) => commit('SET_USER_LANGUAGE', payload),
  setUserLanguages: ({ commit }, payload) =>
    commit('SET_USER_LANGUAGES', payload),
  setUserCountries: ({ commit }, payload) =>
    commit('SET_USER_COUNTRIES', payload),
  fetchCompanyProducts: ({ dispatch }) => {
    dispatch(
      'cache/fetchAsync',
      'auth/setUserCompanyProductsAndProductFamilies',
      { root: true }
    );
  },
  fetchCompanyAccountLevelProductFamilies: ({ dispatch }) => {
    return dispatch(
      'cache/fetch',
      {
        getter: 'auth/getCompanyAccountLevelProductFamilies',
        setter: 'auth/setUserCompanyProductsAndProductFamilies',
      },
      { root: true }
    );
  },
  fetchCompanyVehicleLevelProductFamilies: ({ dispatch }) => {
    return dispatch(
      'cache/fetch',
      {
        getter: 'auth/getCompanyVehicleLevelProductFamilies',
        setter: 'auth/setUserCompanyProductsAndProductFamilies',
      },
      { root: true }
    );
  },
  fetchHasProductFamily: ({ dispatch }, productFamily) => {
    return dispatch(
      'cache/fetch',
      {
        getter: 'auth/hasProductFamily',
        getterPayload: productFamily,
        setter: 'auth/setUserCompanyProductsAndProductFamilies',
      },
      { root: true }
    );
  },
  fetchHasProduct: ({ dispatch }, productSku) => {
    return dispatch(
      'cache/fetch',
      {
        getter: 'auth/hasProduct',
        getterPayload: productSku,
        setter: 'auth/setUserCompanyProductsAndProductFamilies',
      },
      { root: true }
    );
  },
  fetchHasProductInList: ({ dispatch }, productSkuList) => {
    return dispatch(
      'cache/fetch',
      {
        getter: 'auth/hasProductInList',
        getterPayload: productSkuList,
        setter: 'auth/setUserCompanyProductsAndProductFamilies',
      },
      { root: true }
    );
  },
  setUserCompanyProductsAndProductFamilies: async ({ commit }) => {
    await CompanyService.getCompanyProducts(null, true)
      .then((res) => {
        commit('SET_USER_COMPANY_PRODUCTS', res.data);
        commit('SET_USER_COMPANY_PRODUCT_FAMILIES', res.data);
        commit('SET_USER_COMPANY_PRODUCT_FAMILY_BY_LEVEL', res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  setGetStartedPdfUrl: ({ commit }, payload) =>
    commit('SET_GET_STARTED_PDF_URL', payload),
};

export { actions };
