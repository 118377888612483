const getters = {
  linkAssetFilter: (state) => {
    return state.linkAssetFilter;
  },
  paginationQuery: (state) => {
    return state.paginationQuery;
  },
  searchQuery: (state) => {
    return state.searchQuery;
  },
  selected: (state) => {
    return state.selected;
  },
  isSelected: (state) => (id) => {
    const existingLink = state.existingVehicleLinks.some(
      (link) => link.id === id
    );
    return state.selected[id] !== undefined || existingLink;
  },
  selectedLength: (state) => {
    return Object.keys(state.selected).length;
  },
  totalLinkedAsset: (state) => {
    return state.totalLinkedAsset;
  },
  areFiltersOrSearchApplied: (state) => {
    let keys = Object.keys(state.linkAssetFilter);
    return (
      keys.length !== 0 ||
      (state.searchQuery !== '' && state.searchQuery !== null) ||
      state.hasDefaultFilter['showFilterChip'] === true
    );
  },
  areFiltersOrSearchAppliedExceptCustom: (state) => {
    let keys = Object.keys(state.linkAssetFilter);
    return (
      keys.length !== 0 ||
      (state.searchQuery !== '' && state.searchQuery !== null)
    );
  },
  totalFilteredLinkAsset: (state) => {
    return state.totalFilteredLinkAsset;
  },
  hasDefaultFilter: (state) => {
    return state.hasDefaultFilter;
  },
  existingVehicleLinks: (state) => {
    return state.existingVehicleLinks;
  },
};

export { getters };
