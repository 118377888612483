import { isEqual } from 'lodash';

export function arrayEqual(a1, a2) {
  if (typeof a1 === 'undefined' || typeof a2 === 'undefined') return false;
  if (a1 === a2) return true;
  let arr1 = a1.slice().sort();
  let arr2 = a2.slice().sort();
  return isEqual(arr1, arr2);
}

export function removeTrailingSlash(origin) {
  if (origin.endsWith('/')) {
    return origin.substring(0, origin.length - 1);
  }
  return origin;
}

export function removeLeadingSlash(path) {
  if (path.startsWith('/')) {
    return path.substring(1);
  }
  return path;
}
