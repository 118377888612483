import AccountApiProxyService from '@/services/AccountApiProxyService';
import { AccountConstants } from '@/constants';

const localityService = {
  getLocals() {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH + '/locality'
    );
  },
};

export default localityService;
