<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    :data-qa="dataQa"
    :color="color"
    :class="buttonClass"
    :outline="showOutline"
    block
    large
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'BlockButton',
  props: {
    dataQa: String,
    color: String,
  },
  computed: {
    showOutline() {
      return this.color === undefined;
    },
    buttonClass() {
      let className = 'text-none';
      if (this.color !== 'primary') {
        className += ' primary--text';
      }
      return className;
    },
  },
};
</script>
