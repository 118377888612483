import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/src/stylus/app.styl';
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify, {
  breakpoint: {
    thresholds: {
      xs: 600,
      md: 1024,
      sm: 720,
      lg: 1920,
    },
  },
  theme: {
    primary: '#102B4E',
    secondary: '#2861A4',
    accent: '#BAD8E8',
    error: '#FF5252',
    warning: '#E58B00',
    info: '#767676',
    success: '#12B664',
    disableButton: '#0276B3',
    white: '#ffffff',
  },
  options: {
    customProperties: true,
  },
  iconfont: 'ic' || 'md' || 'fa',
});
