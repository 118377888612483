const state = {
  selected: {},
  address: {},
  orderFilter: {},
  paginationQuery: {},
  taxResponse: {
    orderTotal: 0.0,
    subTotal: 0.0,
    taxAmount: 0.0,
  },
  shippingResponse: {
    amount: 0.0,
  },
  driverAppEnabled: 'disabled',
  showUpgradeProductDialog: false,
  productMap: {},
};

export { state };
