import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import { beforeEach } from '@/router/beforeEach';

export function makeRouter(options) {
  if (!process || process.env.NODE_ENV !== 'test') {
    Vue.use(Router);
  }

  const router = new Router({
    mode: 'history',
    ...options,
  });

  router.beforeEach(beforeEach);

  return router;
}

export default makeRouter({ routes });
