<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <span class="subheading primary--text">
      {{ this.$t('churnReason.selectChurnReason') }}
    </span>
    <v-select
      :items="churnReasons"
      item-text="name"
      item-value="typeId"
      data-qa="dropdown-list"
      :label="this.$t('churnReason.selectChurnReasonType')"
      height="40px"
      v-model="selectedTypeId"
      outline
      required
    >
      <template v-slot:item="data">
        <div :data-qa="`item2-name-${dashify(data.item.typeId)}`">
          {{ $t('churnReason.reasonTypeId.' + data.item.typeId) }}
        </div>
      </template>
      <template v-slot:selection="data">
        <div :data-qa="`${dashify(data.item.typeId)}-name`">
          {{ $t('churnReason.reasonTypeId.' + data.item.typeId) }}
        </div>
      </template>
    </v-select>
    <v-select
      v-if="selectedTypeId && !isOtherSelected"
      :items="filteredChurnReasons"
      item-text="name"
      item-value="id"
      data-qa="dropdown-list-2"
      :label="this.$t('churnReason.selectChurnReasonId')"
      v-model="selectedId"
      height="40px"
      outline
      required
    >
      <template v-slot:item="data">
        <div :data-qa="`item2-name-${dashify(data.item.id)}`">
          {{ $t('churnReason.reasonId.' + data.item.id) }}
        </div>
      </template>
      <template v-slot:selection="data">
        <div :data-qa="`${dashify(data.item.id)}-name`">
          {{ $t('churnReason.reasonId.' + data.item.id) }}
        </div>
      </template>
    </v-select>
    <div v-if="selectedTypeId">
      <span class="subheading primary--text">
        {{ this.$t('churnReason.additionalComment') }} ({{ textRequired }})
      </span>
      <v-textarea
        data-qa="additional-reason"
        :rules="reasonRules"
        outline
        name="additional-reason"
        :label="this.$t('churnReason.additionalComment')"
        value=""
        v-model="reason"
      ></v-textarea>
    </div>
  </div>
</template>

<script>
import dashify from 'dashify';
import ChurnService from '@/services/ChurnService';

export default {
  name: 'ChurnReasonDialog',
  components: {},
  data() {
    return {
      churnReasons: [
        {
          id: '',
          typeId: '',
          ordernum: 0,
        },
      ],
      filteredChurnReasons: [
        {
          id: '',
          typeId: '',
          ordernum: 0,
        },
      ],
      originalChurnReasons: [
        {
          id: '',
          typeId: '',
          ordernum: 0,
        },
      ],
      textRequired: this.$t('churnReason.optionalField'),
      selectedTypeId: '',
      reason: '',
      selectedId: '',
      isOtherSelected: false,
      confirmDisabled: true,
    };
  },
  computed: {
    reasonRules() {
      return [(v) => !v || v.length <= 255 || this.$t('churnReason.tooLong')];
    },
  },
  methods: {
    dashify(str) {
      return dashify(str);
    },
    getChurnReasons() {
      return ChurnService.getChurnReasons()
        .then((response) => {
          this.originalChurnReasons = response.data;
          this.churnReasons = [...this.originalChurnReasons];
          this.churnReasons.sort((a, b) => a.ordernum - b.ordernum);
          const uniqueChurnReasons = [];
          const seenTypeIds = new Set();
          for (const reason of this.churnReasons) {
            if (!seenTypeIds.has(reason.typeId)) {
              seenTypeIds.add(reason.typeId);
              uniqueChurnReasons.push(reason);
            }
          }
          this.churnReasons = uniqueChurnReasons;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    isConfirmDisabled() {
      if (this.selectedTypeId === '') {
        this.confirmDisabled = true;
      } else if (
        this.selectedTypeId === '4e8e0141-5d75-4f38-acb2-138fb3c55655'
      ) {
        this.confirmDisabled = this.reason.trim() === '';
      } else {
        this.confirmDisabled = this.selectedId === '';
      }
    },
  },
  watch: {
    selectedTypeId() {
      this.selectedId = '';
      this.isConfirmDisabled();
      if (this.selectedTypeId === '4e8e0141-5d75-4f38-acb2-138fb3c55655') {
        this.isOtherSelected = true;
        this.textRequired = this.$t('churnReason.requiredField');
        this.$emit('churnReasonId', {
          id: '435646d8-423d-4f07-9883-8c784a22a0c8',
        });
      } else {
        this.isOtherSelected = false;
        this.textRequired = this.$t('churnReason.optionalField');
        const filteredChurnReasons = [];
        for (const reason of this.originalChurnReasons) {
          if (reason.typeId === this.selectedTypeId) {
            filteredChurnReasons.push(reason);
          }
        }
        this.filteredChurnReasons = filteredChurnReasons.sort((a, b) => {
          if (
            this.$t('churnReason.reasonId.' + b.id) <
            this.$t('churnReason.reasonId.' + a.id)
          ) {
            return 1;
          } else if (
            this.$t('churnReason.reasonId.' + b.id) >
            this.$t('churnReason.reasonId.' + a.id)
          ) {
            return -1;
          } else {
            return 0;
          }
        });
      }
    },
    selectedId() {
      this.isConfirmDisabled();
      this.$emit('churnReasonId', {
        id: this.selectedId,
      });
    },
    reason() {
      this.isConfirmDisabled();
      this.$emit('churnReasonText', {
        reason: this.reason,
      });
    },
    confirmDisabled() {
      this.$emit('confirmDisable', {
        confirmDisabled: this.confirmDisabled,
      });
    },
  },
  mounted() {
    this.getChurnReasons();
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  max-width: 480px;
}

.dropdown-list {
  padding-top: 16px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 0;
}

::v-deep .v-messages {
  min-height: 0;
}

::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0;
}

.v-dialog__container {
  display: none !important;
}

::v-deep .v-text-field .v-input__slot {
  height: initial !important;
  border: initial !important;
}
</style>
