const getters = {
  query: (state) => {
    return { ...state.paginationQuery, name: state.searchQuery };
  },
  areFiltersOrSearchApplied: (state) => {
    return state.searchQuery !== '' && state.searchQuery !== null;
  },
  selected: (state) => {
    return state.selected;
  },
  selectedLength: (state) => {
    return Object.keys(state.selected).length;
  },
};

export { getters };
