<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-layout row justify-end>
    <v-dialog
      data-qa="vehicle-bulk-edit-dialog"
      v-model="dialog"
      persistent
      lazy
      @keydown.esc="closeDialog"
      max-width="600px"
      data-app
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
      <v-card class="form-card">
        <v-card-title>
          <span class="headline primary--text">{{
            $t('vehicle.bulkModifyVehicle.title')
          }}</span>
        </v-card-title>
        <v-form ref="formBulk" v-model="validForm">
          <v-card flat>
            <v-card-text>
              <div class="bulk-instruction">
                <span>{{
                  $t('vehicle.bulkModifyVehicle.instruction.first')
                }}</span>
                <ul>
                  <li>
                    {{ $t('vehicle.addVehicle.bulkUpload.instruction.csv') }}
                    <icon-button
                      @click="downloadBulkCsvTemplate"
                      :aria-label="$t('common.download')"
                      icon-tag="ic-file-download"
                    />
                  </li>
                  <li>
                    {{ $t('vehicle.addVehicle.bulkUpload.instruction.excel') }}
                    <icon-button
                      @click="downloadBulkExcelTemplate"
                      :aria-label="$t('common.download')"
                      icon-tag="ic-file-download"
                    />
                  </li>
                </ul>
              </div>
              <p class="bulk-instruction">
                {{ $t('vehicle.bulkModifyVehicle.instruction.secondV2') }}
              </p>
              <p class="bulk-instruction">
                {{ $t('vehicle.bulkModifyVehicle.instruction.thirdV2') }}
              </p>
              <p class="bulk-instruction">
                {{ $t('vehicle.bulkModifyVehicle.instruction.fourth') }}
              </p>
              <FileUpload
                class="bulk-modify-vehicle-uploader"
                ref="bulkFile"
                @file-selected="onFileChange($event, validateFileContents)"
                :accept="this.accept"
                :uploadCountStatus="isUploadCountValid"
                :uploadCountLimit="this.vehicleUploadLimit"
              >
                {{ $t('fileUpload') }}
              </FileUpload>
              <div class="action-button-wrapper action-button-align-right">
                <v-btn
                  data-qa="bulk-modify-vehicle-cancel"
                  outline
                  large
                  class="primary--text text-none"
                  @click="closeDialog"
                >
                  {{ $t('vehicle.bulkModifyVehicle.buttonCancel') }}
                </v-btn>
                <v-btn
                  data-qa="bulk-modify-vehicle-update"
                  color="primary"
                  class="text-none"
                  large
                  @click="bulkModifyVehicles"
                  :disabled="!isFormValid"
                >
                  {{ $t('vehicle.bulkModifyVehicle.buttonModify') }}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card>
    </v-dialog>
    <ProcessingDialog
      :show-processing="showProcessing"
      :message="$t('vehicle.bulkModifyVehicle.processingBar.processingFile')"
    >
    </ProcessingDialog>
    <ErrorDialog
      :error="this.error"
      :show-dialog="showErrorPopup"
      @close-dialog="showErrorPopup = false"
    >
    </ErrorDialog>
  </v-layout>
</template>

<script>
import VehicleService from '@/services/VehicleService';
import ErrorMessageService from '@/services/ErrorMessageService';
import ErrorDialog from '@/components/utility-components/dialogs/ErrorDialog';
import FileUpload from '@/components/FileUpload';
import ProcessingDialog from '@/components/ProcessingDialog';
import ConfigurationService from '@/services/ConfigurationService';
import {
  base64toBlob,
  getFileNameFromResponseHeader,
  handleDownloadBlob,
} from '@/services/Downloads';
import IconButton from '@/components/utility-components/IconButton';

export default {
  name: 'VehicleBulkModifyDialog',
  components: {
    IconButton,
    FileUpload,
    ErrorDialog,
    ProcessingDialog,
  },
  data: () => ({
    bulkUploadFile: null,
    fileRowsCount: 0,
    dialog: false,
    validForm: false,
    showProcessing: false,
    error: {},
    showErrorPopup: false,
    vehicleUploadLimit: 1000,
    accept: '.csv, .xlsx',
  }),
  props: {
    selectedVehicles: Object,
  },
  methods: {
    async bulkModifyVehicles() {
      try {
        this.showProcessing = true;
        let modified = await VehicleService.bulkModifyVehicle(
          this.bulkUploadFile
        );
        this.showProcessing = false;
        let failedCount = modified.data.failedVehicles.length;
        if (failedCount > 0) {
          let failureDetails = 'Failed VINS: ';
          modified.data.failedVehicles.forEach((fv, i) => {
            if (i > 0) {
              failureDetails += ', ';
            }
            failureDetails += fv.vin;
          });
          this.$emit(
            'vehicle-modified-failure',
            this.$tc('vehicle.bulkModifyVehicle.failed', 1, {
              count: failedCount,
              failureDetails: failureDetails,
            })
          );
        }
        let succeededCount = modified.data.updatedVehicles.length;
        if (succeededCount > 0) {
          this.$emit(
            'vehicle-modified-success',
            this.$tc('vehicle.bulkModifyVehicle.succeeded', 1, {
              count: succeededCount,
            })
          );
        }
        this.$emit('deselect');
        this.closeDialog();
      } catch (error) {
        this.showProcessing = false;
        this.handleError(error);
      }
    },
    handleError(error) {
      console.log(error);
      this.error = ErrorMessageService.getErrorMessage(error);
      this.showErrorPopup = true;
    },
    setUploadLimit() {
      ConfigurationService.getAccountLimitsConfig()
        .then((response) => {
          this.vehicleUploadLimit = response.data.vehicleUploadLimit;
        })
        .catch((error) => {
          console.log(error);
          this.vehicleUploadLimit = 1000;
        });
    },
    closeDialog() {
      this.$emit('cancel');
      this.$refs.bulkFile.clearFile();
      this.$refs.formBulk.reset();
      this.dialog = false;
      this.showProcessing = false;
    },
    async onFileChange(file, callback) {
      this.bulkUploadFile = file;
      this.fileRowsCount = 0;

      let reader = new FileReader();
      reader.onload = function (e) {
        callback(e.target.result);
      };

      if (file != null) {
        reader.readAsText(file);
      }
    },
    downloadBulkExcelTemplate() {
      let selectedVehicleIds = Object.keys(this.selectedVehicles);
      VehicleService.getBulkModifyVehicleExcelTemplate(selectedVehicleIds)
        .then((response) => {
          const filename = getFileNameFromResponseHeader(
            response.headers['content-disposition']
          );
          const blob = base64toBlob(
            response.data,
            response.headers['content-type']
          );

          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
          } else {
            handleDownloadBlob(blob, filename);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadBulkCsvTemplate() {
      let selectedVehicleIds = Object.keys(this.selectedVehicles);
      VehicleService.getBulkModifyVehicleCsvTemplate(selectedVehicleIds)
        .then((response) => {
          const filename = getFileNameFromResponseHeader(
            response.headers['content-disposition']
          );
          const blob = new Blob([response.data], { type: 'text/csv' });

          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
          } else {
            handleDownloadBlob(blob, filename);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validateFileContents(contents) {
      this.fileRowsCount = contents.match(
        /(?:"(?:[^"]|"")*"|[^,\n]*)(?:,(?:"(?:[^"]|"")*"|[^,\n]*))*\n/g
      ).length;
    },
  },
  computed: {
    isFormValid() {
      return this.validForm && !!this.bulkUploadFile && this.isUploadCountValid;
    },
    isUploadCountValid() {
      return this.vehicleUploadLimit >= this.fileRowsCount;
    },
  },
  mounted() {
    this.setUploadLimit();
  },
};
</script>

<style lang="scss">
.bulk-modify-vehicle-uploader {
  margin: 3em 0 !important;

  input {
    height: 140px !important;
  }

  label {
    height: 140px !important;
  }
}
</style>
<style scoped lang="scss">
.form-card {
  padding: 16px;
}

.file-upload-hint1 {
  color: #c0c0c0;
  padding: 0.5em;
}

.file-upload-hint2 {
  text-decoration: underline;
  padding: 0.5em;
}

.action-button-wrapper {
  margin-top: 2em;
}

.action-button-align-right {
  text-align: right;
}

.bulk-instruction {
  margin-bottom: 0.5em;
}

.download-link-text {
  padding: 0 2px;
  cursor: pointer;
}
</style>
