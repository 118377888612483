<template>
  <v-slide-x-transition>
    <div v-if="areFiltersOrSearchApplied()">
      <v-btn
        :data-qa="`data-grid-clear-all-button-${storeModule}`"
        flat
        class="text-none"
        color="secondary"
        @click="clearFilterAndSearch"
      >
        {{ $t('actions.clearAll') }}
      </v-btn>
    </div>
  </v-slide-x-transition>
</template>

<script>
export default {
  name: 'DataGridClearAllButton',
  props: {
    customFilter: {
      required: false,
      default: null,
    },
    storeModule: {
      type: String,
      required: true,
    },
  },
  methods: {
    areFiltersOrSearchApplied() {
      if (this.storeModule) {
        if (this.customFilter) {
          return this.$store.getters[
            `${this.storeModule}/areFiltersOrSearchAppliedExceptCustom`
          ];
        }
        return this.$store.getters[
          `${this.storeModule}/areFiltersOrSearchApplied`
        ];
      }
    },
    clearFilterAndSearch() {
      if (this.storeModule) {
        this.$store.dispatch(
          `${this.storeModule}/clearFilterAndSearch`,
          this.customFilter
        );
      }
    },
  },
};
</script>

<style scoped></style>
