<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-layout row justify-end>
    <v-dialog
      v-model="dialog"
      lazy
      @keydown.esc="closeDialog"
      max-width="600px"
      data-app
    >
      <template v-slot:activator="{ on }">
        <v-btn
          data-qa="add-vehicle-button"
          class="text-none focusable-button-dark"
          color="primary"
          dark
          large
          v-on="on"
          @click="openDialog"
        >
          <v-icon small align-self-left>fa fa-plus</v-icon>
          <span> &nbsp; {{ $t('vehicle.addVehicle.button') }} </span>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline primary--text">{{
            $t('vehicle.addVehicle.title')
          }}</span>
        </v-card-title>
        <v-divider />
        <v-tabs v-model="tab" grow slider-color="primary">
          <v-tab
            :key="'bulkUpload'"
            :href="`#bulkUpload`"
            class="primary--text"
            data-qa="vehicle-bulk-upload-tab"
          >
            {{ $t('vehicle.addVehicle.tab.bulkUpload') }}
          </v-tab>
          <v-tab
            :key="'singleVehicle'"
            :href="`#singleVehicle`"
            class="primary--text"
            data-qa="vehicle-single-vehicle-tab"
          >
            {{ $t('vehicle.addVehicle.tab.singleVehicle') }}
          </v-tab>
        </v-tabs>
        <v-divider />
        <v-tabs-items v-model="tab">
          <v-tab-item
            :value="'singleVehicle'"
            :key="'singleVehicle'"
            data-qa="single-vehicle-tab"
          >
            <v-form ref="form" v-model="valid">
              <v-card flat>
                <v-card-text>
                  <v-flex xs12>
                    <v-text-field
                      :label="$t('vehicle.addVehicle.labelVin')"
                      :rules="vinRule"
                      :error-messages="vinErrors"
                      required
                      outline
                      data-qa="add-vehicle-vin-text"
                      v-model="vehicleDetails.vin"
                      data-qm-encrypt="true"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      :label="$t('vehicle.addVehicle.labelName')"
                      :rules="nameRule"
                      v-model="vehicleDetails.name"
                      data-qa="add-vehicle-name-text"
                      outline
                      data-qm-encrypt="true"
                    />
                  </v-flex>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-text-field
                        :label="$t('vehicle.addVehicle.labelLicensePlate')"
                        :rules="licensePlateRule"
                        v-model="vehicleDetails.licensePlate"
                        data-qa="add-vehicle-license-plate-text"
                        outline
                        data-qm-encrypt="true"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs6>
                      <v-text-field
                        :label="$t('vehicle.addVehicle.labelLicensePlateState')"
                        :rules="licensePlateStateRule"
                        v-model="vehicleDetails.licensePlateState"
                        data-qa="add-vehicle-license-plate-state-text"
                        :disabled="this.vehicleDetails.licensePlate.length <= 0"
                        outline
                        data-qm-encrypt="true"
                      />
                    </v-flex>
                    <v-flex xs6 pl-4>
                      <DatePicker
                        :label="
                          $t(
                            'vehicle.addVehicle.labelLicensePlateExpirationDate'
                          )
                        "
                        v-model="vehicleDetails.licensePlateExpirationDate"
                        data-qa="add-vehicle-license-plate-expiration-date"
                        :disabled="this.vehicleDetails.licensePlate.length <= 0"
                      >
                      </DatePicker>
                    </v-flex>
                    <v-flex xs12>
                      <MultiSelectDropdown
                        name="add-vehicle-groups"
                        :model="vehicleDetails.segmentIds"
                        :items="groups"
                        :select-all-label="
                          $t('vehicle.addVehicle.group.selectAll')
                        "
                        :hint="$t('vehicle.addVehicle.group.note')"
                        :label="$t('vehicle.addVehicle.group.label')"
                        :chip-count="3"
                        @dropdown-value-changed="
                          vehicleDetails.segmentIds = $event
                        "
                      ></MultiSelectDropdown>
                    </v-flex>
                  </v-layout>
                  <v-checkbox
                    color="primary"
                    data-qa="add-vehicle-consent-checkbox"
                    v-model="vehicleDetails.consent"
                    :rules="consentRule"
                    required
                    :aria-label="$t('sidebar.addVehicles')"
                  >
                    <i18n
                      slot="label"
                      path="vehicle.addVehicle.consent"
                      tag="span"
                      @click.stop="'';"
                    >
                      <a
                        slot="toc"
                        target="_blank"
                        href="/termsAndConditions"
                        >{{ $t('vehicle.addVehicle.toc') }}</a
                      >
                    </i18n>
                  </v-checkbox>
                  <div class="action-button-align-right">
                    <v-btn
                      data-qa="add-vehicle-cancel"
                      outline
                      large
                      class="primary--text text-none"
                      @click="closeDialog"
                    >
                      {{ $t('vehicle.addVehicle.buttonCancel') }}
                    </v-btn>
                    <v-btn
                      data-qa="add-vehicle"
                      color="primary"
                      class="text-none"
                      large
                      @click="createVehicle"
                      :disabled="!canAddVin"
                    >
                      {{ $t('vehicle.addVehicle.buttonAdd') }}
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-form>
          </v-tab-item>
          <v-tab-item
            :value="'bulkUpload'"
            :key="'bulkUpload'"
            data-qa="bulk-upload-tab"
          >
            <v-form ref="formBulk" v-model="validBulk">
              <v-card flat>
                <v-card-text>
                  <h2 class="subheading primary--text subheading-margin-bottom">
                    {{ $t('vehicle.addVehicle.bulkUpload.heading') }}
                  </h2>
                  <p class="bulk-instruction" style="margin-bottom: 0">
                    {{
                      $t('vehicle.addVehicle.bulkUpload.instruction.firstV2')
                    }}
                  </p>
                  <ul style="margin-bottom: 0.5rem">
                    <li>
                      {{ $t('vehicle.addVehicle.bulkUpload.instruction.csv') }}
                      <icon-button
                        @click="handleDownloadBulkUploadTemplate('csv')"
                        :aria-label="`${$t('common.download')} CSV`"
                        icon-tag="ic-file-download"
                      />
                    </li>
                    <li>
                      {{
                        $t('vehicle.addVehicle.bulkUpload.instruction.excel')
                      }}
                      <icon-button
                        @click="handleDownloadBulkUploadTemplate('xlsx')"
                        :aria-label="`${$t('common.download')} XLSX`"
                        icon-tag="ic-file-download"
                      />
                    </li>
                  </ul>
                  <p class="bulk-instruction">
                    {{ $t('vehicle.addVehicle.bulkUpload.instruction.second') }}
                  </p>
                  <p class="bulk-instruction">
                    {{ $t('vehicle.addVehicle.bulkUpload.instruction.third') }}
                  </p>
                  <p class="bulk-instruction">
                    {{ $t('vehicle.addVehicle.bulkUpload.instruction.fourth') }}
                  </p>
                  <FileUpload
                    data-qa="vehicle-add-file-upload"
                    ref="bulkFile"
                    @file-selected="onFileChange($event)"
                    :uploadCountStatus="true"
                    :uploadCountLimit="this.vehicleUploadLimit"
                  >
                    {{ $t('fileUpload') }}
                  </FileUpload>
                  <v-checkbox
                    color="primary"
                    data-qa="bulk-add-vehicle-consent-checkbox"
                    v-model="bulkConsent"
                    :rules="consentRule"
                    required
                  >
                    <i18n
                      slot="label"
                      path="vehicle.addVehicle.consent"
                      tag="span"
                      @click.stop="'';"
                    >
                      <a
                        slot="toc"
                        target="_blank"
                        href="/termsAndConditions"
                        >{{ $t('vehicle.addVehicle.toc') }}</a
                      >
                    </i18n>
                  </v-checkbox>
                  <div class="action-button-align-right">
                    <v-btn
                      data-qa="bulk-add-vehicle-cancel"
                      outline
                      large
                      class="primary--text text-none"
                      @click="closeDialog"
                    >
                      {{ $t('vehicle.addVehicle.buttonCancel') }}
                    </v-btn>
                    <v-btn
                      data-qa="bulk-add-vehicle"
                      color="primary"
                      class="text-none"
                      large
                      @click="bulkUploadVehicles"
                      :disabled="!canBulkUpload"
                    >
                      {{ $t('vehicle.addVehicle.buttonAddBulk') }}
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
    <ProcessingDialog
      :show-processing="showProcessing"
      :message="$t('vehicle.processingBar.processVehicles')"
    ></ProcessingDialog>
  </v-layout>
</template>

<script>
import VehicleService from '@/services/VehicleService';
import { AccountConstants } from '@/constants';
import FileUpload from '@/components/FileUpload';
import ProcessingDialog from '@/components/ProcessingDialog';
import ConfigurationService from '@/services/ConfigurationService';
import DatePicker from '@/components/DatePicker';
import { cloneDeep } from 'lodash';
import GroupService from '@/services/GroupService';
import MultiSelectDropdown from '@/components/MultiSelectDropdown';
import { getStaticAssetsBaseUrl } from '@/urlUtils';
import { handleDownloadUrl } from '@/services/Downloads';
import IconButton from '@/components/utility-components/IconButton';

export default {
  name: 'VehicleDialog',
  components: {
    IconButton,
    DatePicker,
    FileUpload,
    ProcessingDialog,
    MultiSelectDropdown,
  },
  data: () => ({
    cleanVehicleDetails: Object.freeze({
      vin: '',
      name: '',
      licensePlate: '',
      licensePlateExpirationDate: null,
      licensePlateState: '',
      consent: false,
      segmentIds: [],
    }),
    vehicleDetails: {
      vin: '',
      name: '',
      licensePlate: '',
      licensePlateExpirationDate: null,
      licensePlateState: '',
      consent: false,
      segmentIds: [],
    },
    bulkConsent: false,
    bulkUploadFile: null,
    fileRows: 0,
    dialog: false,
    tab: 'singleVehicle',
    valid: false,
    validBulk: false,
    consentRule: [(v) => !!v || ''],
    regex: new RegExp('^[a-zA-Z0-9]{17}$'),
    vinErrors: [],
    showProcessing: false,
    vehicleUploadLimit: 1000,
    groups: [],
    showChips: false,
    activeElement: null,
  }),
  methods: {
    async createVehicle() {
      this.showProcessing = true;
      VehicleService.createVehicle(this.vehicleDetails)
        .then((response) => {
          let createdVehicle = response;
          let creationMessage = 'vehicle.addVehicle.created.notVerified';
          if (
            createdVehicle &&
            createdVehicle.data &&
            createdVehicle.data.verified
          ) {
            creationMessage = 'vehicle.addVehicle.created.verified';
          }
          this.$emit(
            'vehicle-created-success',
            this.$tc(creationMessage, 1, { count: 1 })
          );
          this.closeDialog();
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.message.includes('Invalid letter in VIN')) {
            this.$emit(
              'vehicle-creation-failed',
              this.$t('vehicle.addVehicle.invalidLetter')
            );
          } else if (
            err.response.data.message.includes('Invalid character in VIN')
          ) {
            this.$emit(
              'vehicle-creation-failed',
              this.$t('vehicle.addVehicle.invalidNumber')
            );
          } else {
            this.$emit(
              'vehicle-creation-failed',
              this.$t('vehicle.addVehicle.error')
            );
          }
        })
        .finally(() => {
          this.showProcessing = false;
        });
    },
    async onFileChange(file) {
      this.bulkUploadFile = file;
    },
    async bulkUploadVehicles() {
      this.showProcessing = true;
      VehicleService.bulkVehicleUpload(this.bulkUploadFile)
        .then((response) => {
          let createdVehicle = response;
          let creationMessage;
          if (createdVehicle.data.verified > 0) {
            creationMessage = 'vehicle.addVehicle.bulkUpload.verified';
            this.$emit(
              'vehicle-created-success',
              this.$tc(creationMessage, 1, {
                count: createdVehicle.data.verified,
              })
            );
          }

          if (createdVehicle.data.unverified > 0) {
            creationMessage = 'vehicle.addVehicle.bulkUpload.unverified';
            this.$emit(
              'vehicle-created-success',
              this.$tc(creationMessage, 1, {
                count: createdVehicle.data.unverified,
              })
            );
          }

          if (createdVehicle.data.failed > 0) {
            creationMessage = 'vehicle.addVehicle.bulkUpload.failed';
            this.$emit(
              'vehicle-creation-failed',
              this.$tc(creationMessage, 1, {
                count: createdVehicle.data.failed,
              })
            );
          }
          this.closeDialog();
        })
        .catch((err) => {
          console.log(err);
          this.$emit(
            'vehicle-creation-failed',
            this.$t('vehicle.addVehicle.error')
          );
          this.closeDialog();
        })
        .finally(() => {
          this.showProcessing = false;
        });
    },
    findVehicleUploadLimit() {
      ConfigurationService.getAccountLimitsConfig()
        .then((response) => {
          this.vehicleUploadLimit = response.data.vehicleUploadLimit;
        })
        .catch((error) => {
          console.log(error);
          this.vehicleUploadLimit = 1000;
        });
    },
    openDialog() {
      this.activeElement = document.activeElement;
    },
    closeDialog() {
      this.vehicleDetails = cloneDeep(this.cleanVehicleDetails);
      this.$refs.form.resetValidation();
      this.$refs.bulkFile.clearFile();
      this.$refs.formBulk.reset();
      this.$refs.formBulk.resetValidation();
      this.dialog = false;
      this.showProcessing = false;
      this.showChips = false;
      this.vinErrors = [];
      if (this.activeElement) {
        this.activeElement.focus();
      }
    },
    checkVinExists(vin) {
      VehicleService.checkVinExists(vin)
        .then((response) => {
          if (response.data.vinExistsInCompany) {
            this.vinErrors = [
              this.$t('vehicle.addVehicle.rules.vinAlreadyExistsInCompany'),
            ];
          } else if (response.data.result) {
            this.vinErrors = [this.$t('vehicle.addVehicle.rules.vinExists')];
          } else {
            this.vinErrors = [];
          }
        })
        .catch((e) => {
          console.log('An error occurred on vin duplication precheck.');
        });
    },
    getBulkUploadTemplateUrl(extension) {
      return getStaticAssetsBaseUrl() + '/AddVehicles_Template.' + extension;
    },
    handleDownloadBulkUploadTemplate(extension) {
      const fileUrl = this.getBulkUploadTemplateUrl(extension);
      handleDownloadUrl(fileUrl);
    },
  },
  computed: {
    nameRule() {
      return [
        (v) =>
          v === '' ||
          (v && v.length <= AccountConstants.MAX_NAME_LENGTH) ||
          this.$t('vehicle.addVehicle.rules.name'),
      ];
    },
    licensePlateRule() {
      return [
        (v) =>
          v === '' ||
          (v && v.length <= AccountConstants.MAX_NAME_LENGTH) ||
          this.$t('vehicle.addVehicle.rules.licensePlate'),
      ];
    },
    licensePlateStateRule() {
      return [
        (v) =>
          v === '' ||
          (v && v.length <= AccountConstants.MAX_NAME_LENGTH) ||
          this.$t('vehicle.addVehicle.rules.licensePlateState'),
      ];
    },
    vinRule() {
      return [
        (v) => !!v || this.$t('vehicle.addVehicle.rules.required'),
        (v) =>
          (v && this.regex.test(v)) || this.$t('vehicle.addVehicle.rules.vin'),
        (v) => (v && this.regex.test(v) && this.checkVinExists(v)) || true,
      ];
    },
    canBulkUpload() {
      return (
        this.validBulk &&
        !!this.bulkUploadFile &&
        this.isUploadVinCountValid &&
        !this.showProcessing
      );
    },
    canAddVin() {
      return this.valid && !this.showProcessing;
    },
    isUploadVinCountValid() {
      return this.vehicleUploadLimit >= this.fileRows;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.findVehicleUploadLimit();
        GroupService.getBasicGroups()
          .then((response) => {
            this.groups = response.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.action-button-align-right {
  text-align: right;
}

.subheading-margin-bottom {
  margin-bottom: 1em;
}

.bulk-instruction {
  margin-bottom: 0.5em;
}

::v-deep .v-text-field .v-input__slot {
  height: initial !important;
  border: initial !important;
}

ul {
  list-style-type: '-';
}

li {
  padding-left: 5px;
}
</style>
