import { mockState, state } from '@/store/companyBillingInfo/state';
import { actions } from '@/store/companyBillingInfo/actions';
import { mutations } from '@/store/companyBillingInfo/mutations';
import { getters } from '@/store/companyBillingInfo/getters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export { state, mutations, actions, getters, mockState };
