<template>
  <td :data-qa="itemName" :class="classString">
    <slot name="default"></slot>
    <slot name="action"></slot>
  </td>
</template>

<script>
import dashify from 'dashify';

export default {
  name: 'DataGridItem',
  props: {
    columnName: String,
    classes: Array,
  },
  methods: {
    dashify,
  },
  computed: {
    itemName() {
      return 'grid-item-' + dashify(this.columnName);
    },
    classString() {
      if (this.classes) {
        return this.itemName + ' ' + this.classes.join(' ');
      } else {
        return this.itemName + ' text-xs-left';
      }
    },
  },
};
</script>

<style scoped lang="scss">
td[class^='grid-item'] {
  color: #292e35;
  font-size: 15px;
  line-height: 1.5;
  max-width: 30ch;
  word-wrap: break-word;
}
</style>
