<template>
  <div>
    <v-layout v-if="isLoading" justify-center row>
      <v-flex shrink>
        <loading-spinner
          :size="80"
          :width="5"
          color="secondary"
          data-qa="loading-spinner"
        />
      </v-flex>
    </v-layout>
    <slot v-else />
  </div>
</template>

<script>
import LoadingSpinner from '@/components/utility-components/LoadingSpinner';

export default {
  name: 'SpinnerWrapper',
  components: { LoadingSpinner },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
