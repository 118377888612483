import InvoiceService from '@/services/InvoiceService';
import csc from 'countries-states-cities';
import { AccountConstants } from '@/constants';

const actions = {
  addCompanyBillingInfo({ commit }, payload) {
    commit('SET_ALL_COMPANY_INFO', payload);
  },
  addCompanyName({ commit }, payload) {
    commit('SET_COMPANY_NAME', payload);
  },
  addAddressLine1({ commit }, payload) {
    commit('SET_ADDRESS_LINE1', payload);
  },
  addAddressLine2({ commit }, payload) {
    commit('SET_ADDRESS_LINE2', payload);
  },
  addCity({ commit }, payload) {
    commit('SET_CITY', payload);
  },
  addCountryCode({ commit }, payload) {
    commit('SET_COUNTRY_CODE', payload);
  },
  addState({ commit }, payload) {
    commit('SET_STATE', payload);
  },
  setInitialState({ commit }, payload) {
    commit('SET_INITIAL_STATE', payload);
  },
  addZipCode({ commit }, payload) {
    commit('SET_ZIP_CODE', payload);
  },
  addTaxID({ commit }, payload) {
    commit('SET_TAX_ID', payload);
  },
  deleteChildAccount({ commit }, payload) {
    commit('DELETE_CHILD_ACCOUNT', payload);
  },
  updateChildAccount({ commit }, payload) {
    commit('UPDATE_CHILD_ACCOUNT', payload);
  },
  updateChildAccountAddress({ commit }) {
    commit('UPDATE_CHILD_ACCOUNT_ADDRESS');
  },
  async fetchCompanyBillingInfo({ commit, dispatch }) {
    commit('SET_IS_FETCHING_DATA', true);
    await InvoiceService.getCompanyBillingInfo()
      .then((response) => {
        const flattenedResponse = {
          companyName: response.data.companyName,
          taxId: response.data.taxId,
          childAccounts: response.data.childAccounts,
          ...response.data.companyAddressResponse,
        };
        let country = csc
          .getAllCountries()
          .find((c) => c.iso3 === flattenedResponse.country.code);
        if (country.region === AccountConstants.EUROPE_REGION) {
          flattenedResponse.state = {
            name: flattenedResponse.state,
          };
        } else {
          let stateList = csc.getStatesOfCountry(country.id);
          flattenedResponse.state = stateList.find(
            (state) => state.name === flattenedResponse.state
          );
        }

        dispatch('addCompanyBillingInfo', flattenedResponse);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit('SET_IS_FETCHING_DATA', false);
      });
  },
  async fetchChildAccountsForAllCountries({ commit, dispatch }, payload) {
    commit('SET_IS_FETCHING_CHILD_ACCOUNT_DATA', true);
    await InvoiceService.getChildAccountsForAllCountries(payload)
      .then((response) => {
        dispatch('setChildAccounts', response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit('SET_IS_FETCHING_CHILD_ACCOUNT_DATA', false);
      });
  },
  setChildAccounts({ commit }, payload) {
    commit('SET_CHILD_ACCOUNTS', payload);
  },
};

export { actions };
