const state = {
  initialFtEnabled: false,
  ftEnabled: false,
  initialFtSelection: 'FT',
  ftSelection: 'FT',
  ftSkus: [],
  fteSkus: [],
  selectedReason: { key: undefined },
  initialEnabled: false,
  valid: false,
  additionalReasonDescription: '',
};

export { state };
