const actions = {
  setSearchQuery: ({ commit }, payload) => {
    commit('SET_SEARCH_QUERY', payload);
  },
  setPaginationQuery: ({ commit }, payload) => {
    commit('SET_PAGINATION_QUERY', payload);
  },
  clearFilterAndSearch: ({ commit }) => {
    commit('CLEAR_FILTER_AND_SEARCH');
  },
  addSelected: ({ commit }, payload) => {
    commit('ADD_SELECTED', payload);
  },
  clearSelected: ({ commit }, payload) => {
    commit('CLEAR_SELECTED', payload);
  },
};

export { actions };
