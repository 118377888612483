<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <ConfirmationDialog
      :title="confirmationDialogProps.title"
      :message="confirmationDialogProps.message"
      :acceptance-text="confirmationDialogProps.acceptanceText"
      :cancellation-text="confirmationDialogProps.cancellationText"
      :display-confirm="true"
      :enable-dialog="showConfirmationDialog"
      :data-qa-key="confirmationDialogProps.dataQaKey"
      @confirm="triggerAction"
      @cancel="showConfirmationDialog = false"
      @setEnableDialogFalse="showConfirmationDialog = false"
    >
      <template v-slot:content>
        <p>
          {{ displayName }}
        </p>
      </template>
      <template v-slot:activator="{ on }">
        <v-switch
          :data-qa="`block-switch-${item.vin}`"
          v-on="on"
          class="switch"
          color="primary"
          v-model="blocked"
          :label="label"
          :disabled="disabled"
        />
      </template>
    </ConfirmationDialog>
    <ProcessingDialog
      :message="processingDialogMessage"
      :show-processing="showProcessingDialog"
    />
  </div>
</template>
<script>
import ProcessingDialog from '@/components/ProcessingDialog';
import ConfirmationDialog from '@/components/utility-components/dialogs/ConfirmationDialog';
import { mapActions } from 'vuex';

export default {
  name: 'OTAActionItem',
  props: {
    item: Object,
    enabledText: String,
    disabledText: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.blocked = this.item.isBlocked;
  },
  data: function () {
    return {
      blocked: false,
      showConfirmationDialog: false,
      showProcessingDialog: false,
      processingDialogMessage: '',
    };
  },
  components: {
    ProcessingDialog,
    ConfirmationDialog,
  },
  computed: {
    label() {
      return this.item.isBlocked ? this.enabledText : this.disabledText;
    },
    confirmationDialogProps() {
      if (this.item.isBlocked) {
        return {
          name: this.$t('ota.toolbar.unblockVehicles.name'),
          title: this.$t('ota.toolbar.unblockVehicles.title'),
          message: this.$tc('ota.toolbar.unblockVehicles.message', 1),
          acceptanceText: this.$tc(
            'ota.toolbar.unblockVehicles.acceptanceText'
          ),
          cancellationText: this.$t('confirmationDialog.cancel'),
          dataQaKey: 'ota-toolbar-unblock-vehicles',
        };
      }

      return {
        name: this.$t('ota.toolbar.blockVehicles.name'),
        title: this.$t('ota.toolbar.blockVehicles.title'),
        message: this.$tc('ota.toolbar.blockVehicles.message', 1),
        acceptanceText: this.$tc('ota.toolbar.blockVehicles.acceptanceText'),
        cancellationText: this.$t('confirmationDialog.cancel'),
        dataQaKey: 'ota-toolbar-block-vehicles',
      };
    },
    displayName() {
      if (
        this.item.vehicleName !== null &&
        this.item.vehicleName !== '' &&
        this.item.vehicleName !== undefined
      ) {
        return this.item.vehicleName;
      }

      return this.item.vin;
    },
  },
  methods: {
    triggerAction() {
      this.setDisplayName(this.displayName);
      this.setVehicles([this.item.vin]);
      if (this.item.isBlocked) {
        this.performUnblockOTA();
      } else {
        this.performBlockOTA();
      }
    },
    ...mapActions('notification', [
      'addErrorNotification',
      'addSuccessNotification',
    ]),
    ...mapActions('ota', [
      'refreshTable',
      'setDisplayName',
      'setVehicles',
      'performBlockOTA',
      'performUnblockOTA',
    ]),
  },
};
</script>

<style scoped>
.switch ::v-deep .v-input__slot {
  margin-top: 6px;
  margin-bottom: 0px;
}

.switch ::v-deep .v-label {
  margin-bottom: 0px;
}
</style>
