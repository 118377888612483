<!--suppress CssInvalidPseudoSelector -->
<template>
  <v-layout row class="grid-search">
    <v-flex xl4 md6 sm10 xs11>
      <v-text-field
        class="search mb-0"
        v-model="searchQuery"
        flat
        :placeholder="placeholderText"
        :label="placeholderText"
        prepend-inner-icon="search"
        solo
        clearable
        @click:prepend-inner="search"
        @keydown.enter="search"
        @click:clear="clearSearch"
        :data-qa="this.storeModule + '-search-field'"
      ></v-text-field>
    </v-flex>
    <v-flex xs1 v-if="!hideClearAll">
      <DataGridClearAllButton
        :storeModule="storeModule"
        :custom-filter="customFilter"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import DataGridClearAllButton from '@/components/grid/DataGridClearAllButton';

export default {
  name: 'DataGridSearch',
  components: {
    DataGridClearAllButton,
  },
  async mounted() {
    if (this.storeModule) {
      this.searchQuery = this.initialValue;
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === `${this.storeModule}/CLEAR_FILTER_AND_SEARCH`) {
          this.searchQuery = '';
        }
      });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  props: {
    placeholderText: {
      default: function () {
        return this.$t('search');
      },
    },
    customFilter: {
      required: false,
      default: null,
    },
    storeModule: {
      type: String,
      required: true,
    },
    initialValue: {
      type: String,
      required: false,
      default: '',
    },
    hideClearAll: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchQuery: '',
  }),
  methods: {
    async clearSearch() {
      this.searchQuery = null;
      await this.search();
    },
    async search() {
      await this.$store.dispatch(this.storeModule + '/setSearchQuery', {
        data: this.searchQuery,
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .v-text-field__details {
    display: none;
  }
}
</style>
