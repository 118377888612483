import axios from 'axios';
import { gatewayInterceptor } from '@/services/interceptors/EquipmentGatewayInterceptor';
import configuration from '@/store/configuration';

const EquipmentApiProxyService = axios.create({});
EquipmentApiProxyService.interceptors.request.use(gatewayInterceptor);
EquipmentApiProxyService.interceptors.request.use((config) => {
  config.baseURL = configuration.state.equipmentApiBaseUrl;
  config.headers = configuration.state.equipmentApiProxyHeaders;
  config.withCredentials =
    configuration.state.equipmentApiProxyWithConfiguration;
  return config;
});
export default EquipmentApiProxyService;
