import fpgConfiguration from '@/store/fpgConfiguration';
import configuration from '@/store/configuration';

export function gatewayInterceptor(config) {
  let isTileMode = fpgConfiguration.state.isTileMode;
  if (isTileMode) {
    if (config.baseURL.includes('localhost')) {
      config.baseURL = '/fleet-account' + config.baseURL;
    } else {
      let host = window.location.hostname.replace('www.', '');
      let decodedConfigUrl = decodeURIComponent(config.url);
      try {
        const urlObj = new URL(config.url);
        decodedConfigUrl =
          urlObj.pathname.replace(new RegExp('^/[^/]+'), '') + urlObj.search;
      } catch {}
      let encodedUrl = encodeURIComponent(configuration.state.apigeeApiBaseUrl);
      config.baseURL =
        'https://api.' +
        host +
        '/fpg-bff/proxy-api-request?redirect=' +
        encodedUrl;
      config.url = encodeURIComponent(decodedConfigUrl);
    }
  }
  return config;
}
