export const validSettings = (state) => {
  let validOnState = false;
  let validOffState = false;

  if (state.ftEnabled) {
    validOnState = true;
  } else {
    validOffState =
      state.selectedReason?.key !== undefined &&
      (state.selectedReason.key !== 'other' ||
        state.additionalReasonDescription.length !== 0);
  }
  return validOnState || validOffState;
};

const mutations = {
  SET_FT_SKUS: (state, payload) => {
    state.ftSkus = payload.ftSkus;
  },
  SET_TOGGLES: (state, payload) => {
    state.initialFtEnabled = payload.initialFtEnabled;
    state.ftEnabled = payload.ftEnabled;
  },
  SET_PRODUCTS: (state, payload) => {
    state.ftSkus = payload.ftSkus;
    state.fteSkus = payload.fteSkus;
  },
  SET_FT_SELECTION: (state, payload) => {
    state.ftSelection = payload;
  },
  SET_FT_ENABLED: (state, payload) => {
    state.ftEnabled = payload;
    state.valid = validSettings(state);
  },
  SET_SELECTED_REASON: (state, payload) => {
    state.selectedReason = payload;
    state.valid = validSettings(state);
  },
  SET_ADDITIONAL_REASON_DESCRIPTION: (state, payload) => {
    state.additionalReasonDescription = payload;
    state.valid = validSettings(state);
  },
  SET_ALL_SETTINGS: (state, payload) => {
    state.initialFtEnabled = payload.ftEnabled;
    state.initialFtSelection = payload.ftSelection;
    state.ftSelection = payload.ftSelection;
    state.ftEnabled = payload.ftEnabled;
    state.ftSkus = payload.ftSkus;
    state.fteSkus = payload.fteSkus;
    state.additionalReasonDescription = payload.additionalReasonDescription;
    state.selectedReason = payload.selectedReason;
    state.valid = validSettings(state);
  },
};

export { mutations };
