const state = {
  equipmentFilter: {},
  totalEquipment: 0,
  searchQuery: '',
  selected: {},
  paginationQuery: {},
  totalFilteredEquipment: 0,
  equipmentDetails: {
    name: '',
    identifier: '',
    description: null,
    type: null,
    brand: null,
    model: null,
    year: null,
    details: {},
  },
  cleanEquipmentDetails: Object.freeze({
    name: '',
    identifier: '',
    description: null,
    type: null,
    brand: null,
    model: null,
    year: null,
    details: {},
  }),
};

export { state };
