import policyService from '@/services/PolicyService';

const actions = {
  setFetchedTermsAndConditions({ commit }, payload) {
    commit('HAS_FETCHED_TERMS_AND_CONDITIONS', payload);
  },
  setSignedTermsAndConditions({ commit }, payload) {
    commit(
      'HAS_SIGNED_PRODUCT_TERMS_AND_CONDITIONS',
      payload.hasSignedProductTermsAndConditions
    );
    commit(
      'HAS_SIGNED_FFM_TERMS_AND_CONDITIONS',
      payload.hasSignedFFMTermsAndConditions
    );
  },
  setCanViewOnboardPage({ commit }, payload) {
    commit('CAN_VIEW_ONBOARD_PAGE', payload);
  },
  setCheckedAgreeTermsAndConditions({ commit }, payload) {
    commit('CHECKED_AGREE_TERMS_AND_CONDITIONS', payload);
  },
  setProductTermsAndConditionsMap({ commit }, payload) {
    commit('SET_PRODUCT_TERMS_AND_CONDITIONS_MAP', payload);
  },
  setLatestTermsAndConditionsDirect({ commit }, payload) {
    commit('SET_LATEST_TERMS_AND_CONDITIONS', payload);
  },
  async setLatestTermsAndConditions({ commit }) {
    await policyService.getLatestTermsAndConditions().then((response) => {
      commit('SET_LATEST_TERMS_AND_CONDITIONS', response.data);
    });
  },
  fetchLatestTermsAndConditions({ dispatch }) {
    return dispatch(
      'cache/fetch',
      {
        getter: 'termsAndConditions/getLatestTermsAndConditions',
        setter: 'termsAndConditions/setLatestTermsAndConditions',
      },
      { root: true }
    );
  },
};

export { actions };
