/**
 * @param callback
 * The NAME of a callback function that you have defined in your component.
 * The callback function will be passed the triggering mutation object as the first parameter
 * and the resulting vuex state as the second parameter.
 */
const SubscribeToStore = function (callback) {
  return {
    mounted: function () {
      this._unsubscribe = this.$store.subscribe(this._callback);
    },
    beforeDestroy: function () {
      this._unsubscribe();
    },
    methods: {
      _callback(...args) {
        this[callback](...args);
      },
    },
  };
};

export default SubscribeToStore;
