import Vue from 'vue';
import { cloneDeep, set } from 'lodash';

const mutations = {
  SET_EQUIPMENT_FILTER: (state, payload) => {
    if (payload.selected.length === 0) {
      Vue.delete(state.equipmentFilter, payload.name);
    } else {
      Vue.set(state.equipmentFilter, payload.name, payload.selected);
    }
  },
  SET_BULK_EQUIPMENT_FILTERS: (state, payload) => {
    Vue.set(state, 'equipmentFilter', {});
    if (payload.length > 0) {
      for (let filter of payload) {
        Vue.set(state.equipmentFilter, filter.name, filter.value);
      }
    }
  },
  SET_TOTAL_EQUIPMENT: (state, payload) => {
    Vue.set(state, 'totalEquipment', payload);
  },
  SET_SEARCH_QUERY: (state, payload) => {
    Vue.set(state, 'searchQuery', payload.data);
  },
  SET_PAGINATION_QUERY: (state, payload) => {
    Vue.set(state, 'paginationQuery', payload.data);
  },
  REFRESH_TABLE: (state) => {
    return state;
  },
  REFRESH_EQUIPMENT: (state) => {
    return state;
  },
  EQUIPMENT_ROUTE_CHANGED: (state) => {
    return state;
  },
  SET_TOTAL_FILTERED_EQUIPMENT: (state, payload) => {
    Vue.set(state, 'totalFilteredEquipment', payload);
  },
  CLEAR_FILTER_AND_SEARCH: (state) => {
    Vue.set(state, 'searchQuery', '');
    Vue.set(state, 'equipmentFilter', {});
  },
  ADD_SELECTED: (state, payload) => {
    for (let equipmentId of Object.keys(payload.data)) {
      Vue.set(state.selected, equipmentId, payload.data[equipmentId]);
    }
  },
  REMOVE_SELECTED: (state, payload) => {
    for (let equipmentId of Object.keys(payload.data)) {
      Vue.delete(state.selected, payload.data[equipmentId]);
    }
  },
  CLEAR_SELECTED: (state) => {
    state.selected = {};
  },
  CLOSE_LINKED_ASSETS_DIALOG: () => {},
  RESET_STATE: (state) => {
    state.equipmentFilter = {};
    state.totalEquipment = 0;
    state.searchQuery = '';
    state.selected = {};
    state.paginationQuery = {};
    state.totalFilteredEquipment = 0;
  },
  SET_EQUIPMENT_DETAIL: (state, payload) => {
    if (payload.fieldValue === undefined) {
      payload.fieldValue = null;
    }
    set(state.equipmentDetails, payload.fieldName, payload.fieldValue);
  },
  SET_EQUIPMENT: (state, payload) => {
    Vue.set(state, 'equipmentDetails', cloneDeep(payload));
  },
  SET_TYPE_SCHEMAS: (state, payload) => {
    Vue.set(state, 'typeSchemas', cloneDeep(payload));
  },
  RESET_EQUIPMENT_DETAILS: (state, _payload) => {
    Vue.set(state, 'equipmentDetails', cloneDeep(state.cleanEquipmentDetails));
  },
  REMOVE_EQUIPMENT_FILTERS: (state, payload) => {
    for (let filter of payload) {
      Vue.delete(state.equipmentFilter, filter.name);
    }
  },
};

export { mutations };
