<!--suppress CssInvalidPseudoSelector -->
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <PageComponent :title="$t('sidebar.termsAndConditionsPolicy')">
      <v-form>
        <v-layout row wrap class="pl-5">
          <v-flex class="content">
            <span v-html="legalLanguage"></span>
          </v-flex>
          <v-flex>
            <v-btn
              class="downloadButton white--text"
              :data-qa="'downloadTermsAndConditions'"
              :color="'primary'"
              @click="downloadPDF(false)"
              >{{ $t('termsAndConditions.download') }}</v-btn
            >
          </v-flex>
          <v-flex>
            <v-btn
              class="downloadButton white--text"
              :data-qa="'downloadSignedTermsAndConditions'"
              :color="'primary'"
              @click="downloadPDF(true)"
              >{{ $t('termsAndConditions.downloadSigned') }}</v-btn
            >
          </v-flex>
        </v-layout>
      </v-form>
    </PageComponent>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { handleTermsAndConditionsDownload } from '@/services/Downloads';
import PageComponent from '@/components/PageComponent';

export default {
  name: 'TermsAndConditionsPolicy',
  components: {
    PageComponent,
  },
  mounted() {
    this.getTermsAndConditionsPolicyLanguage();
  },
  data: () => {
    return {
      legalLanguage: '',
      legalLanguageByLlidId: '',
    };
  },
  methods: {
    ...mapActions('termsAndConditions', ['fetchLatestTermsAndConditions']),
    getTermsAndConditionsPolicyLanguage() {
      this.fetchLatestTermsAndConditions()
        .then((res) => {
          this.legalLanguage = res.legalLanguage;
          this.legalLanguageByLlidId = res.legalLanguageByLlidId;
        })
        .catch((err) => {
          let tempLegalLanguage = this.$t(
            'policy.termsAndConditions.legalLanguageError'
          );
          if (!this.getIsLoggedIn) {
            tempLegalLanguage =
              'Unable to find terms and conditions policy for country and language combination';
          }
          this.legalLanguage = tempLegalLanguage;
          console.log(err);
        });
    },
    downloadPDF(signed) {
      handleTermsAndConditionsDownload(
        this.legalLanguageByLlidId,
        null,
        null,
        signed
      );
    },
  },
};
</script>

<style scoped>
h1 {
  padding: 1em;
}
.content {
  margin-top: 1%;
  overflow: auto;
  height: 650px;
}
.content-font ::v-deep p {
  margin-bottom: 1px;
  padding-left: 52px;
}
.downloadButton {
  margin-top: 25px;
  margin-left: 45%;
}
</style>
