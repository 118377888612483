<template>
  <div data-qa="company-billing-child-account-information" class="mt-3">
    <div class="font-weight-medium mb-1"
      >{{ $t('countryName.' + childAccount.isoCountryCode) }}
      {{ $t('childAccount.vatId') }}
      <EditChildAccountDialog :child-account="childAccount">
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            class="mb-0 ml-1 mr-1"
            color="secondary"
            :data-qa="`edit-child-account-billing-information-${childAccount.index}`"
            small
            >ic-edit
          </v-icon>
        </template>
      </EditChildAccountDialog>
      <v-icon
        class="mb-0"
        style="font-size: 14px"
        :data-qa="`delete-child-account-information-` + childAccount.index"
        color="secondary"
        @click="deleteChildAccount(childAccount.index)"
        >far fa-trash-alt fa-sm
      </v-icon>
    </div>
    <div>{{ childAccount.customerTaxId }}</div>
    <div v-if="formattedAddress !== ''" data-qa="child-account-address">
      <span style="white-space: pre">{{ formattedAddress }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import EditChildAccountDialog from '@/components/EditChildAccountDialog';

export default {
  name: 'ChildAccountInformation',
  components: { EditChildAccountDialog },
  data() {
    return {
      showEditChildAccountDialogue: false,
    };
  },
  props: {
    childAccount: Object,
  },
  computed: {
    formattedAddress() {
      if (
        this.childAccount.physicalPresenceIndicator === 'true' &&
        this.childAccount.billToContact.address1 !== '' &&
        this.childAccount.billToContact.address1 !== null &&
        this.childAccount.billToContact.address1 !== undefined
      ) {
        let address = this.childAccount.billToContact.address1 + '\n';
        if (
          this.childAccount.billToContact.address2 !== '' &&
          this.childAccount.billToContact.address2 !== null &&
          this.childAccount.billToContact.address2 !== undefined
        ) {
          address += this.childAccount.billToContact.address2 + '\n';
        }
        address += this.childAccount.billToContact.city + '\n';
        if (this.childAccount.billToContact.state !== '') {
          address += this.childAccount.billToContact.state + ', ';
        }
        address += this.childAccount.billToContact.zipCode + '\n';
        address +=
          this.$t('countryName.' + this.childAccount.billToContact.country) +
          '\n';
        return address;
      }
      return '';
    },
  },
  methods: {
    ...mapActions('companyBillingInfo', ['deleteChildAccount']),
  },
};
</script>
