import Vue from 'vue';

const mutations = {
  SET_SEARCH_QUERY: (state, payload) => {
    Vue.set(state, 'searchQuery', payload.data);
  },
  SET_PAGINATION_QUERY: (state, payload) => {
    Vue.set(state, 'paginationQuery', payload.data);
  },
  CLEAR_FILTER_AND_SEARCH: (state) => {
    Vue.set(state, 'searchQuery', '');
  },
  CLEAR_SELECTED: (state) => {
    state.selected = [];
  },
  ADD_SELECTED: (state, payload) => {
    state.selected = payload;
  },
};

export { mutations };
