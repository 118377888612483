<template>
  <v-btn
    ref="elementRef"
    :data-qa="dataQaTag"
    icon
    class="primary--text outline-revert"
    @click="$emit('close-clicked')"
    :aria-label="$t('common.close')"
  >
    <v-icon>close</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'CloseButton',
  props: {
    elementRef: { type: HTMLElement, required: false },
    dataQaTag: { type: String, default: 'close-dialog' },
  },
};
</script>
