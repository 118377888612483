const actions = {
  setFilter: ({ commit }, payload) => {
    commit('SET_FILTER', payload);
  },
  setSearchQuery: ({ commit }, payload) => {
    commit('SET_SEARCH_QUERY', payload);
  },
  clearFilterAndSearch: ({ commit }) => {
    commit('CLEAR_FILTER_AND_SEARCH');
  },
  setTotalOTAs: ({ commit }, payload) => {
    commit('SET_TOTAL_OTAS', payload);
  },
  setTotalFilteredOTAs: ({ commit }, payload) => {
    commit('SET_TOTAL_FILTERED_OTAS', payload);
  },
  setPaginationQuery: ({ commit }, payload) => {
    commit('SET_PAGINATION_QUERY', payload);
  },
  refreshTable: ({ commit }) => {
    commit('REFRESH_TABLE');
  },
  performBlockOTA: ({ commit }) => {
    commit('PERFORM_BLOCK_OTA');
  },
  performUnblockOTA: ({ commit }) => {
    commit('PERFORM_UNBLOCK_OTA');
  },
  addSelected: ({ commit }, payload) => {
    let newSelected = {};

    payload.data.forEach((vehicle) => {
      newSelected[vehicle[payload.key]] = {
        vin: vehicle.vin,
        displayName:
          vehicle.vehicleName !== undefined &&
          vehicle.vehicleName !== '' &&
          vehicle.vehicleName !== null
            ? vehicle.vehicleName
            : vehicle.vin,
      };
    });

    commit('ADD_SELECTED', { data: newSelected });
  },
  removeSelected: ({ commit }, payload) => {
    commit('REMOVE_SELECTED', payload);
  },
  clearSelected: ({ commit }, payload) => {
    commit('CLEAR_SELECTED', payload);
  },
  setDisplayName: ({ commit }, payload) => {
    commit('SET_DISPLAY_NAME', payload);
  },
  setVehicles: ({ commit }, payload) => {
    commit('SET_VEHICLES', payload);
  },
};

export { actions };
