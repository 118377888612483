import Vue from 'vue';
import AccountApiProxyService from '@/services/AccountApiProxyService';
import { AccountConstants } from '@/constants';
import AccountApiProxyServiceForDownload from '@/services/AccountApiProxyServiceForDownload';

export default new Vue({
  methods: {
    getStatementDetails() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/v1/invoice/statementDetails'
      );
    },
    getAccountBalance() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/v2/invoice/balance'
      );
    },
    getCDRDetails(cdrRequest) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/v1/invoice/csvCdrDetails',
        cdrRequest,
        {
          responseType: 'blob',
        }
      );
    },
    getBillingFile(statementDate, fileType, statementNumber) {
      let service = AccountApiProxyService;
      if (fileType === 'VIN_DETAIL') {
        service = AccountApiProxyServiceForDownload;
      }
      return service.get(
        AccountConstants.ACCOUNT_API_PATH +
          `/v1/invoice/${fileType}/${statementDate}/${statementNumber}`,
        {
          responseType: 'blob',
        }
      );
    },
    getCompanyBillingInfo() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + `/v1/billing/pay/billingInformation`
      );
    },
    validateBillingAddress(request) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH +
          `/v1/billing/pay/billingInformation/address/verify`,
        request
      );
    },
    updateCompanyBillingInfo(request) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/v1/billing/pay/address/update',
        request
      );
    },
    getChildAccountsForAllCountries(productSkus) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/v1/billing/pay/child-accounts-for-all-countries?productSkus=' +
          productSkus
      );
    },
  },
});
