const actions = {
  setUserFilter: ({ commit }, payload) => {
    commit('SET_USER_FILTER', payload);
  },
  setSearchQuery({ commit }, payload) {
    commit('SET_SEARCH_QUERY', payload);
  },
  clearFilterAndSearch: ({ commit }) => {
    commit('CLEAR_FILTER_AND_SEARCH');
  },
  setPaginationQuery({ commit }, payload) {
    commit('SET_PAGINATION_QUERY', payload);
  },
  clearQueries({ commit }) {
    commit('SET_PAGINATION_QUERY', { data: {} });
    commit('SET_SEARCH_QUERY', { data: '' });
  },
  addSelected: ({ commit }, payload) => {
    let newSelected = {};

    payload.data.forEach((user) => {
      newSelected[user[payload.key]] = {
        firstName: user.firstName,
        lastName: user.lastName,
        primary: user.primary,
      };
    });

    commit('ADD_SELECTED', { data: newSelected });
  },
  removeSelected: ({ commit }, payload) => {
    commit('REMOVE_SELECTED', payload);
  },
  clearSelected: ({ commit }, payload) => {
    commit('CLEAR_SELECTED', payload);
  },
  setInitialSelected: ({ commit }) => {
    commit('SET_INITIAL_SELECTED');
  },
  clearInitialSelected: ({ commit }) => {
    commit('CLEAR_INITIAL_SELECTED');
  },
  resetSelected: ({ commit }) => {
    commit('RESET_SELECTED');
  },
  setUserGridColumns: ({ commit }, payload) => {
    commit('SET_USER_GRID_COLUMNS', payload);
  },
  setTotalUsers: ({ commit }, payload) => {
    commit('SET_TOTAL_USERS', payload);
  },
  setShowReimbursableAddressDialog: ({ commit }, payload) => {
    commit('SET_SHOW_REIMBURSABLE_ADDRESS_DIALOG', payload);
  },
  setShowAddressVerificationDialog: ({ commit }, payload) => {
    commit('SET_SHOW_ADDRESS_VERIFICATION_DIALOG', payload);
  },
  setReimbursableAddress: ({ commit }, payload) => {
    commit('SET_REIMBURSABLE_ADDRESS', payload);
  },
  clearReimbursableAddress: ({ commit }) => {
    commit('CLEAR_REIMBURSABLE_ADDRESS');
  },
  setViewAddress: ({ commit }, payload) => {
    commit('SET_VIEW_ADDRESS', payload);
  },
  setNotificationPreference: ({ commit }, payload) => {
    commit('SET_NOTIFICATION_PREFERENCE', payload);
  },
  clearNotificationPreferences: ({ commit }) => {
    commit('CLEAR_NOTIFICATION_PREFERENCES');
  },
  setShowChargingStationDialog: ({ commit }, payload) => {
    commit('SET_SHOW_CHARGING_STATION_DIALOG', payload);
  },
  setChargeStationErrors: ({ commit, payload }) => {
    commit('SET_CHARGE_STATION_ERRORS', payload);
  },
  validateChargeStationId: ({ commit }, payload) => {
    commit('VALIDATE_CHARGE_STATION_ID', payload);
  },
};

export { actions };
