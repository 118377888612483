<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-menu
    :close-on-content-click="false"
    :data-qa="'icon-tooltip-' + dataQaKey"
    max-width="275px"
    nudge-left="16pt"
    nudge-top="16pt"
    v-model="tooltip"
  >
    <template v-slot:activator="{ on }">
      <icon-button
        :data-qa="'icon-tooltip-activator-' + dataQaKey"
        v-on="on"
        :aria-label="$t('toolTip.icon.label')"
        :icon-tag="tooltipIcon"
        icon-class-names="tooltip-icon"
      />
    </template>
    <v-card>
      <v-card-title class="pb-1">
        <v-icon color="secondary" small>{{ tooltipIcon }}</v-icon>
      </v-card-title>
      <v-card-text
        :data-qa="'icon-tooltip-message-' + dataQaKey"
        v-html="tooltipMessage"
        role="status"
      >
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import IconButton from '@/components/utility-components/IconButton';

export default {
  name: 'IconTooltip',
  components: { IconButton },
  props: {
    dataQaKey: String,
    tooltipMessage: String,
    tooltipIcon: {
      type: String,
      default: 'ic-info-circle',
    },
  },
  data() {
    return {
      tooltip: false,
    };
  },
};
</script>

<style>
.tooltip-icon {
  padding-bottom: 1px;
}
</style>
