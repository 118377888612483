<template>
  <v-card data-qa="error-dialog" v-show="!popUp" class="pa-2">
    <v-card-text>
      <h3
        class="error-title mb-3 marketplace-heading-2"
        data-qa="error-title"
        :class="primaryHeader ? 'primary-error-title' : 'secondary-error-title'"
      >
        {{ $t(error.title) }}
      </h3>
      <p class="mb-3 marketplace-body-1" data-qa="error-description">
        {{ $t(error.description) }}
      </p>
      <p
        v-if="error.referenceId"
        class="text--secondary mb-3 marketplace-body-1"
        data-qa="error-reference"
      >
        {{ `${$t('Reference Error ID')} ${error.referenceId}` }}
      </p>
      <i18n
        v-if="error.action !== undefined"
        :path="error.action"
        tag="p"
        class="action marketplace-body-1"
      >
        <v-dialog
          slot="contact"
          v-model="popUp"
          width="480px"
          data-qa="contact-fcs-dialog"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="secondary--text link contact-info-button"
              data-qa="contact-fcs-activator"
              v-on="on"
              flat
            >
              {{ $t('error.contact.linkText') }}
            </v-btn>
          </template>
          <v-card data-qa="contact-fcs-dialog-card">
            <v-card-title>
              <p class="primary--text">{{ $t('error.contact.title') }}</p>
            </v-card-title>
            <v-card-text>
              <p class="body-text">
                {{ $t('error.contact.description') }}
              </p>
            </v-card-text>
            <v-card-text>
              <p class="body-text">
                <i18n path="error.contact.action" tag="p">
                  <template slot="phone">
                    <span
                      class="secondary--text"
                      data-qa="contact-phone-number"
                    >
                      {{ phoneNumber }}
                    </span>
                  </template>
                </i18n>
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                block
                data-qa="popup-dialog-cancel"
                flat
                large
                class="primary--text text-none"
                @click="popUp = false"
              >
                {{ $t('error.contact.cancelButton') }}
              </v-btn>
              <v-btn
                block
                data-qa="popup-dialog-confirm"
                large
                color="primary"
                class="text-none"
                :href="$t('error.contact.contactLink', { emailAddress: email })"
                @click="popUp = false"
              >
                {{ $t('error.contact.confirmButton') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </i18n>
    </v-card-text>
    <v-card-actions>
      <v-btn
        block
        data-qa="error-dialog-confirm"
        large
        color="primary"
        class="text-none confirm-button focusable-button-dark"
        @click="hideDialog"
      >
        {{ $t('error.confirmButton') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'ErrorCard',
  props: {
    error: {
      title: String,
      description: String,
      action: String,
    },
    primaryHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popUp: false,
    };
  },
  methods: {
    hideDialog() {
      this.$emit('close');
    },
  },
  computed: {
    ...mapState({
      contact: (state) => state.contact,
    }),
    ...mapGetters('contact', ['getContact']),
    phoneNumber() {
      if (this.getContact.phone === undefined) {
        return '';
      } else {
        return this.getContact.phone;
      }
    },
    email() {
      if (this.getContact.email === undefined) {
        return '';
      } else {
        return this.getContact.email;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.error-title {
  height: 41px;
  width: 420px;
  font-size: 34px;
  line-height: 41px;
}

.secondary-error-title {
  color: #d92e14;
}

.primary-error-title {
  color: #102b4e;
}

.action {
  color: #333333;
}

.body-text {
  color: #292e35;
  font-size: 16px;
  line-height: 19px;
}

.confirm-button {
  margin: 0px 8px 8px 8px !important;
}

.link {
  text-decoration: underline;
}

.contact-info-button {
  color: var(--v-secondary-base);
  text-transform: none;
  padding: 0 0 0.25em;
  text-decoration: underline;
  margin: 0;
}
</style>
