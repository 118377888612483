<template>
  <div v-if="hasConsentedConsumer">
    <span class="text-left consent-list-item">
      {{ vehicleConsent.displayName }}
    </span>
    <span class="text-right consent-list-item">
      {{ vehicleConsent.consentedConsumers[0].name }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'VehicleEditDialogListDetails',
  props: {
    vehicleConsent: { type: Object, required: true },
  },
  computed: {
    hasConsentedConsumer() {
      return (
        this.vehicleConsent.consentedConsumers &&
        this.vehicleConsent.consentedConsumers.length > 0
      );
    },
  },
};
</script>

<style>
::v-deep .consent-list-item {
  width: 50%;
  display: inline-block;
  box-sizing: content-box;

  &.text-right {
    text-align: right;
  }
}
</style>
