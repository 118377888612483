import AccountApiProxyService from '@/services/AccountApiProxyService';

export default {
  getProductsInfo(productSkus) {
    return AccountApiProxyService.post(
      '/pro/cat-man-api/v1/product',
      productSkus
    );
  },
  getDefaultPrice(productSku) {
    return AccountApiProxyService.get(
      '/pro/cat-man-api/v1/product/default-pricing/' + productSku
    );
  },
};
