const getters = {
  equipmentFilter: (state) => {
    return state.equipmentFilter;
  },
  paginationQuery: (state) => {
    return state.paginationQuery;
  },
  searchQuery: (state) => {
    return state.searchQuery;
  },
  selected: (state) => {
    return state.selected;
  },
  isSelected: (state) => (id) => {
    return state.selected[id] !== undefined;
  },
  selectedLength: (state) => {
    return Object.keys(state.selected).length;
  },
  totalEquipment: (state) => {
    return state.totalEquipment;
  },
  areFiltersOrSearchApplied: (state) => {
    let keys = Object.keys(state.equipmentFilter);
    return (
      keys.length !== 0 ||
      (state.searchQuery !== '' && state.searchQuery !== null)
    );
  },
  totalFilteredEquipment: (state) => {
    return state.totalFilteredEquipment;
  },
};

export { getters };
