import axios from 'axios';
import { gatewayInterceptor } from '@/services/interceptors/AccountGatewayInterceptor';
import configuration from '@/store/configuration';

const AccountApiProxyService = axios.create({});
AccountApiProxyService.interceptors.request.use(gatewayInterceptor);
AccountApiProxyService.interceptors.request.use((config) => {
  config.baseURL = configuration.state.accountApiProxyBaseUrl;
  config.headers = {
    ...config.headers,
    ...configuration.state.accountApiProxyHeaders,
  };
  config.withCredentials = configuration.state.accountApiProxyWithConfiguration;
  return config;
});
export default AccountApiProxyService;
