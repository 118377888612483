import { render, staticRenderFns } from "./IconTooltip.vue?vue&type=template&id=bcf476ca&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
import script from "./IconTooltip.vue?vue&type=script&lang=js&"
export * from "./IconTooltip.vue?vue&type=script&lang=js&"
import style0 from "./IconTooltip.vue?vue&type=style&index=0&id=bcf476ca&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VCard,VCardText,VCardTitle,VIcon,VMenu})
