<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    block
    large
    class="text-none"
    color="primary"
    :data-qa="dataQa"
  >
    <slot>{{ $t('confirmationDialog.confirm') }}</slot>
  </v-btn>
</template>

<script>
export default {
  name: 'ConfirmButton',
  props: {
    dataQa: String,
  },
};
</script>
