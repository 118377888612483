var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"white elevation-5",style:({ marginTop: _vm.headerHeight + 'px' }),attrs:{"data-qa":"sidebar","clipped":"","touchless":"","app":"","mini-variant":_vm.drawer.mini,"width":"240px","permanent":""}},[_c('v-layout',{attrs:{"column":""}},[_c('v-list',{attrs:{"data-qa":"navigation-items"}},_vm._l((_vm.visibleItems),function(item){return _c('span',{key:item.title,attrs:{"data-qa":'navigation-item-' + _vm.dashify(item.title)}},[(_vm.isSubItem(item))?_c('v-list-tile',{class:{
            'list-item': true,
            'font-weight-regular': true,
            'mr-2': true,
            'font-weight-medium': _vm.isItemActive(item.path),
            highlighted: _vm.isItemActive(item.path),
          },staticStyle:{"margin-left":"65px"},attrs:{"to":_vm.resolvePath(item.path, item.querystring),"data-qa":'sidebar-subitem-' + _vm.dashify(item.title)}},[_c('v-list-tile-content',[_c('v-list-tile-title',{staticClass:"list-item-title"},[_c('p',{staticClass:"primary--text"},[_vm._v(_vm._s(item.title))])])],1)],1):_c('v-list-tile',{class:{
            'list-item': true,
            'font-weight-medium': true,
            'mx-2': true,
            highlighted: _vm.isItemActive(item.path),
          },attrs:{"to":_vm.resolvePath(item.path, item.querystring),"data-qa":'sidebar-item-' + _vm.dashify(item.title)}},[_c('v-list-tile-action',[_c('v-icon',{staticClass:"list-item-icon primary--text",attrs:{"size":"22pt"}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-tile-content',[_c('v-list-tile-title',{staticClass:"list-item-title subheading font-weight-medium"},[_c('p',{staticClass:"primary--text"},[_vm._v(_vm._s(item.title))])])],1)],1)],1)}),0),_c('v-layout',{attrs:{"align-end":"","row":""}},[_c('v-flex',[_c('v-layout',{attrs:{"column":"","align-end":""}},[_c('v-flex',[(!_vm.drawer.displayCollapse)?_c('v-btn',{staticClass:"collapse-button outline-revert",class:{ collapsed: _vm.drawer.mini },attrs:{"flat":"","color":"primary--darken-2","data-qa":"collapse-button"},on:{"click":_vm.toggleCollapse}},[_c('v-icon',{staticClass:"body-1 mr-1 primary--text font-weight-medium"},[_vm._v("ic-collapse")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.drawer.mini),expression:"!drawer.mini"}],staticClass:"body-1 text-none primary--text font-weight-medium"},[_vm._v(_vm._s(_vm.$t('sidebar.collapse')))])],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }