import { state } from '@/store/equipment/state';
import { getters } from '@/store/equipment/getters';
import { actions } from '@/store/equipment/actions';
import { mutations } from '@/store/equipment/mutations';

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export { state, getters, mutations, actions };
