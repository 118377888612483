import Vue from 'vue';
import AccountApiProxyService from '@/services/AccountApiProxyService';
import { AccountConstants } from '@/constants';

export default new Vue({
  methods: {
    getFFMTermsAndConditions() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/v1/onboard/ffm/termsAndConditions'
      );
    },
    agreeTermsAndConditions(productConsent) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH +
          '/v1/onboard/termsAndConditions/agree',
        productConsent
      );
    },
    getProductTermsAndConditions() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/v1/onboard/product/termsAndConditions'
      );
    },
    downloadFFMTermsAndConditions(legalLanguageByLlid, isFFM, signed) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          `/v1/onboard/termsAndConditions/download/${legalLanguageByLlid}?isFFM=${isFFM}&signed=${signed}`
      );
    },
    getSignedTermsAndConditions() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/v1/onboard/signed-termsAndConditions'
      );
    },
    updateUserRoleAfterAcceptingTermsAndConditions() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/authorize/flip-termsAndConditions-role'
      );
    },
  },
});
