export function addRowLabelsToVehiclesResults(paginationResponse) {
  return addRowLabels(
    paginationResponse,
    (vehicle) => vehicle.name || vehicle.vin
  );
}

export function addRowLabels(paginationResponse, cb) {
  return {
    ...paginationResponse,
    results: paginationResponse.results
      ? paginationResponse.results.map((item) => ({
          ...item,
          rowLabel: cb(item),
        }))
      : [],
  };
}
