<template>
  <v-dialog
    max-width="600px"
    v-model="dialog"
    scrollable
    persistent
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title>
        <h1 class="title primary--text" data-qa="replacement-info-title">
          {{ $t('equipment.unlinkAssociationPopup.unlinkAsset') }}
        </h1>
      </v-card-title>
      <v-card-text style="padding-top: 0">
        <v-layout>
          <v-flex data-qa="replacement-info-description">
            {{ $t('equipment.unlinkAssociationPopup.description') }}
          </v-flex>
        </v-layout>
        <v-layout justify-center column style="padding: 24px 0 24px 0">
          <v-flex xs12 mt-3>
            <v-layout align-center justify-start fill-height>
              <v-flex xs1 style="font-size: 10px">
                <div>
                  {{
                    $t('equipment.associationPopup.replacement.current')
                  }}</div
                >
              </v-flex>
              <v-flex xs5 ml-3>
                <v-layout
                  align-center
                  justify-start
                  column
                  :data-qa="`unlink-current-entity-${this.associationId}`"
                  class="currentOrSelected"
                >
                  <v-flex align-self-start>
                    <v-layout align-center justify-start fill-height>
                      <v-flex
                        align-self-start
                        :data-qa="`unlink-current-entity-name-${this.currentEntity.identifier}`"
                      >
                        <div>{{
                          `${getVehicleName(
                            this.currentEntity.name,
                            this.currentEntity.brand,
                            this.currentEntity.model
                          )}`
                        }}</div>
                      </v-flex>
                      <v-flex
                        ml-2
                        align-self-center
                        class="currentDevice"
                        :data-qa="`current-device-${this.currentEntity.identifier}`"
                      >
                        <div>{{
                          $t(
                            'equipment.associationPopup.replacement.thisDevice'
                          )
                        }}</div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    align-self-start
                    :data-qa="`replacement-info-current-equipment-identifier-${this.currentEntity.identifier}`"
                  >
                    <div>{{ this.currentEntity.identifier }}</div>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs1 shrink align-self-center>
                <v-icon style="font-size: 14px">fal fa-unlink</v-icon>
              </v-flex>
              <v-flex xs5>
                <v-layout
                  align-center
                  justify-start
                  column
                  :data-qa="`unlink-linkedEntity-${this.associationId}`"
                  class="notCurrentOrSelected"
                >
                  <v-flex align-self-start>
                    <v-layout align-center justify-start fill-height>
                      <v-flex
                        align-self-start
                        :data-qa="`unlink-linkedEntity-name-${this.linkedEntity.identifier}`"
                      >
                        <div>{{
                          `${getVehicleName(
                            this.linkedEntity.name,
                            this.linkedEntity.brand,
                            this.linkedEntity.model
                          )}`
                        }}</div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    align-self-start
                    :data-qa="`unlink-linkedEntity-identifier-${this.linkedEntity.identifier}`"
                  >
                    <div>{{ this.linkedEntity.identifier }}</div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn
          block
          flat
          data-qa="unlink-asset-dialog-cancel"
          medium
          class="primary--text text-none"
          @click="onCancel"
        >
          {{ $t('equipment.add.buttonCancel') }}
        </v-btn>
        <v-btn
          block
          data-qa="unlink-asset-dialog-confirm"
          medium
          color="primary"
          class="text-none"
          @click="onConfirm"
        >
          {{ $t('generic.unlink') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ResolveVehicleName from '@/mixins/ResolveVehicleName';

export default {
  name: 'LinkAssetReplaceInfoDialog',
  data() {
    return {
      showDialog: false,
    };
  },
  mixins: [ResolveVehicleName],
  props: {
    origination: String,
    associationId: String,
    enableDialog: {
      type: Boolean,
      default: false,
    },
    currentEntity: Object,
    linkedEntity: Object,
  },
  computed: {
    dialog: {
      get: function () {
        return this.showDialog || this.enableDialog;
      },
      set: function (value) {
        this.showDialog = value;
        if (value === false) {
          this.$emit('unlink-cancelled');
        }
      },
    },
  },
  methods: {
    onCancel() {
      this.dialog = false;
    },
    onConfirm() {
      this.dialog = false;
      this.$emit(
        'unlink-confirmed',
        this.getVehicleName(
          this.linkedEntity.name,
          this.linkedEntity.brand,
          this.linkedEntity.model
        )
      );
    },
  },
};
</script>

<style scoped lang="scss">
.currentOrSelected {
  color: var(--v-primary-base);
  font-weight: 500;
}

.notCurrentOrSelected {
  color: var(--v-white-darken4);
  font-weight: 500;
}

.currentDevice {
  font-size: 10px;
  font-style: italic;
}
</style>
