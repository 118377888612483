const getters = {
  checkedAgreeTermsAndConditions: (state) =>
    state.checkedAgreeTermsAndConditions,
  hasFetchedTermsAndConditions: (state) => state.hasFetchedTermsAndConditions,
  hasSignedFFMTermsAndConditions: (state) =>
    state.hasSignedFFMTermsAndConditions,
  hasSignedProductTermsAndConditions: (state) =>
    state.hasSignedProductTermsAndConditions,
  canViewOnboardPage: (state) => state.canViewOnboardPage,
  hasSignedTermsAndConditions: (state) =>
    state.hasSignedFFMTermsAndConditions &&
    state.hasSignedProductTermsAndConditions,
  productTermsAndConditionsMap: (state) => state.productTermsAndConditionsMap,
  getLatestTermsAndConditions: (state) => state.latestTermsAndConditions,
};

export { getters };
