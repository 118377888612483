import { make } from 'vuex-pathify';
import { state as configuration } from '@/store/configuration';
import ConfigurationService from '@/services/ConfigurationService';
import { snakeCase } from 'snake-case';

const actions = {
  ...make.actions(configuration),
  fetch: ({ dispatch }, field) => {
    let params = {
      setter: 'configuration/populate',
    };
    if (field) {
      params = {
        ...params,
        getter: `configuration/${field}`,
      };
    }
    return dispatch('cache/fetch', params, { root: true });
  },
  populate: async ({ commit }) => {
    await ConfigurationService.getConfiguration('account-web')
      .then(({ data }) => {
        for (const configItem of Object.keys(data)) {
          if (
            window.location.host.includes('fleetaccount-e2e') &&
            typeof data[configItem] === 'string'
          ) {
            data[configItem] = data[configItem].replace(
              '{{E2E_ACCOUNT_API_HOST}}',
              window.location.host
            );
          }
          commit(
            `SET_${snakeCase(configItem).toUpperCase()}`,
            data[configItem]
          );
        }
      })
      .catch((err) => {
        console.log('There was an error setting the configuration: ' + err);
      });
  },
};

export { actions };
