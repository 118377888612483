<template>
  <div
    class="mt-4 file-upload-wrapper"
    :class="[dragging ? 'over-dropzone' : '', isUsers ? 'users-dialog' : '']"
    @drop="dragging = false"
    @dragenter="dragging = true"
    @dragover="dragging = true"
    @dragleave="dragging = false"
  >
    <input
      type="file"
      data-qa="file-upload"
      v-bind:name="dropzoneName"
      @change="setFileName"
      @click="$refs.filePicker.value = null"
      ref="filePicker"
      v-bind:accept="accept"
      :class="[isUsers ? 'users-dialog-input-height' : '']"
      required
      id="filePicker"
    />
    <label :class="[isUsers ? 'users-label' : '']" for="filePicker">
      <span class="secondary--text" v-if="fileName">
        <span
          :class="
            !uploadCountStatus || otherValidationError ? 'error--text' : ''
          "
          >{{ fileName }}
          <span v-if="!uploadCountStatus && isUsers"
            ><br />{{
              $t('bulkUserUpload.error.fileUploadExceedLimit', {
                uploadCountLimit: uploadCountLimit,
              })
            }}</span
          >
          <span v-else-if="!uploadCountStatus"
            ><br />{{
              $t('fileUploadExceedLimit', {
                uploadCountLimit: uploadCountLimit,
              })
            }}</span
          >
          <span v-if="otherValidationError"
            ><br />{{ otherValidationError }}</span
          >
        </span>
      </span>
      <span class="secondary--text" v-else>
        <slot> </slot>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'FileUpload',
  data() {
    return {
      fileName: '',
      dragging: false,
      dropzoneName: 'filepicker',
    };
  },
  props: {
    accept: String,
    otherValidationError: String,
    uploadCountStatus: Boolean,
    uploadCountLimit: Number,
    isUsers: Boolean,
  },
  methods: {
    setFileName(event) {
      let file = this.$refs.filePicker.files[0];
      if (file) {
        this.fileName = event.target.value.replace('C:\\fakepath\\', '');
        this.$emit('file-selected', file);
      }
    },
    clearFile() {
      if (this.fileName) {
        this.$refs.filePicker.value = null;
        this.fileName = null;
        this.$emit('file-selected', null);
      }
    },
    preventUnintendedDragDropHandler(e) {
      if (e.target.name !== this.dropzoneName) {
        e.preventDefault();
        e.dataTransfer.effectAllowed = 'none';
        e.dataTransfer.dropEffect = 'none';
      }
    },
    preventUnintendedDragDrop() {
      window.addEventListener(
        'dragenter',
        this.preventUnintendedDragDropHandler,
        false
      );
      window.addEventListener(
        'dragover',
        this.preventUnintendedDragDropHandler
      );
      window.addEventListener('drop', this.preventUnintendedDragDropHandler);
    },
    restoreDragDrop() {
      window.removeEventListener(
        'dragenter',
        this.preventUnintendedDragDropHandler,
        false
      );
      window.removeEventListener(
        'dragover',
        this.preventUnintendedDragDropHandler
      );
      window.removeEventListener('drop', this.preventUnintendedDragDropHandler);
    },
  },
  mounted() {
    this.preventUnintendedDragDrop();
  },
  destroyed() {
    this.restoreDragDrop();
  },
};
</script>

<style scoped lang="scss">
.file-upload-wrapper {
  position: relative;
  border: 1px dashed lightgray;
  &.over-dropzone {
    border: 1px solid #2861a4;
  }
}

[type='file'] {
  border: 0;
  padding: 0;
  width: 100%;
  height: 75px;
  cursor: pointer;
  opacity: 0;
  position: absolute !important;
  white-space: nowrap;
}

[type='file'] + label {
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  text-align: center;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

[type='file']:focus + label {
  outline: 1px dotted #000;
}

.users-dialog {
  height: 140px;
  border-radius: 4px;
  z-index: 0;
  background-color: transparent;
}

.users-label {
  position: relative;
  top: 26px;
  z-index: -1;
}

.users-dialog-input-height {
  height: 140px;
}
</style>
