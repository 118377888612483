import IdleVue from 'idle-vue';
export default {
  install(Vue, options) {
    Vue.use(IdleVue, {
      store: options.store,
      idleTime: options.idleTime,
      startAtIdle: false,
    });
  },
};
