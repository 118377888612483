const getters = {
  getSelected: (state) => {
    return state.selected;
  },
  getSelectedArray: (state) => {
    return Object.keys(state.selected).map((k) => state.selected[k]);
  },
  getProductFamilies: (state) => {
    return state.productFamilies;
  },
  selectedLength: (state) => {
    return Object.keys(state.selected).length;
  },
};

export { getters };
