import Vue from 'vue';

const mutations = {
  ADD_SELECTED: (state, payload) => {
    let productFamily = payload.data;
    Vue.set(state.selected, payload.data.id, {
      productFamilyId: productFamily.id,
      productFamilyName: productFamily.name,
      ...productFamily,
    });
  },
  REMOVE_SELECTED: (state, payload) => {
    Vue.delete(state.selected, payload);
  },
  CLEAR_SELECTED: (state) => {
    state.selected = {};
  },
  SELECTED_CHANGED: () => {
    // This is intentional
  },
  SET_PRODUCT_FAMILIES(state, payload) {
    let prioritizedProductFamilies = [
      this.state.configuration.fordTelematicsProductFamilyId,
      this.state.configuration.fordTelematicsEssentialsProductFamilyId,
    ];
    let prioritizedProductFamiliesInPayload = payload.data.filter((x) =>
      prioritizedProductFamilies.includes(x.id)
    );
    let nonPrioritizedProductFamiliesInPayload = payload.data.filter(
      (x) => !prioritizedProductFamilies.includes(x.id)
    );
    state.productFamilies = [
      ...prioritizedProductFamiliesInPayload,
      ...nonPrioritizedProductFamiliesInPayload,
    ];
  },
};

export { mutations };
