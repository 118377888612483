import Vue from 'vue';
import AccountApiProxyService from '@/services/AccountApiProxyService';
import { cloneDeep } from 'lodash';
import { AccountConstants } from '@/constants';

const queryString = require('query-string');
const filterNames = ['status'];

export default new Vue({
  methods: {
    getOTAVehicles(pagination, searchText, otaFilter) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/ota/vehicles?' +
          this.getQueryString(searchText, otaFilter, pagination)
      );
    },
    getAllOTAVehicles(searchText, otaFilter) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/ota/vehicles/basic?' +
          this.getQueryStringV2(searchText, otaFilter)
      );
    },
    getOTAVehicleBlockStatusCounts(searchText, otaFilter) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/ota/vehicles/status/counts?' +
          this.getQueryStringV2(searchText, otaFilter)
      );
    },
    blockOTAVehicles(vins) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/ota/block',
        vins
      );
    },
    unblockOTAVehicles(vins) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/ota/unblock',
        vins
      );
    },
    getQueryStringV2(searchQuery, filters) {
      return this.getQueryString(searchQuery, filters, null);
    },
    getQueryString(searchQuery, filters, pagination) {
      let localFilters = this.extractFilterValues(filters);
      localFilters['searchFilter'] = searchQuery;
      let query = {
        filter: JSON.stringify(localFilters),
      };

      if (pagination) {
        query.page = pagination.page;
        query.size = pagination.size;
        query.sort = pagination.sort;
      }

      return queryString.stringify(query);
    },
    extractFilterValues(filters) {
      if (typeof filters === 'undefined') {
        return {};
      }
      let localFilter = cloneDeep(filters);
      for (let i = 0; i < filterNames.length; i++) {
        let filter = localFilter[filterNames[i]];
        if (filter) {
          localFilter[filterNames[i]] = filter;
        }
      }
      return localFilter;
    },
  },
});
