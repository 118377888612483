<template>
  <ConfirmationDialog
    :acceptance-text="$t('fordProRedirectDialog.goToFordProButtonText')"
    :enable-dialog="shouldFordProGatewayRedirect && !cancelBtnClicked"
    :show-cancel-button="!this.hideFordProRedirectCancelButton"
    :title="$t('fordProRedirectDialog.title')"
    :message="
      $t('fordProRedirectDialog.message', {
        ffmLink: '/',
        fordProGatewayUrl: fordProUrl,
      })
    "
    data-qa="ford-pro-redirect-dialog"
    :data-qa-key="'ford-pro-redirect-dialog'"
    @confirm="handleConfirm"
    @cancel="handleCancel"
    display-confirm
    hide-content
    persistent
  />
</template>

<script>
import ConfirmationDialog from '@/components/utility-components/dialogs/ConfirmationDialog';
import { mapActions, mapGetters, mapState } from 'vuex';
import AuthService from '@/services/AuthService';
import { getFordProDomain } from '@/urlUtils';

export default {
  name: 'FordProGatewayRedirectDialog',
  components: { ConfirmationDialog },
  data() {
    return {
      cancelBtnClicked: false,
    };
  },
  computed: {
    ...mapGetters('fpgConfiguration', ['shouldFordProGatewayRedirect']),
    ...mapState('configuration', [
      'fpgApplicationId',
      'hideFordProRedirectCancelButton',
      'fpgRedirectPath',
    ]),
    ...mapGetters('auth', ['companyCountryCode']),
    fordProUrl() {
      return getFordProDomain(this.companyCountryCode);
    },
  },
  methods: {
    getFordProDomain,
    ...mapActions('fpgConfiguration', [
      'setLastReminderForFordProGatewayRedirect',
    ]),
    handleConfirm() {
      this.setLastReminderForFordProGatewayRedirect();
      AuthService.getPartnerTokenToRedirect(
        this.fpgApplicationId,
        this.companyCountryCode,
        {
          redirect: '/' + this.$i18n.locale + this.fpgRedirectPath,
        }
      )
        .then((res) => {
          window.location.replace(res.data);
        })
        .catch((err) => {
          console.error(err);
          window.location.replace(this.fordProUrl);
        });
    },
    handleCancel() {
      this.cancelBtnClicked = true;
    },
  },
};
</script>
