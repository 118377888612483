import Vue from 'vue';

export default new Vue({
  methods: {
    getErrorMessage(error) {
      if (error.response === undefined) {
        return {
          title: 'error.default.title',
          description: 'error.default.description',
          action: 'error.default.action',
        };
      }
      switch (error.response.data.status) {
        case 400:
          return {
            title: 'error.badRequest.title',
            description: 'error.badRequest.description',
            action: 'error.badRequest.action',
          };
        case 403:
          return {
            title: 'error.forbidden.title',
            description: 'error.forbidden.description',
            action: 'error.forbidden.action',
          };
        case 404:
          return {
            title: 'error.notFound.title',
            description: 'error.notFound.description',
            action: 'error.notFound.action',
          };
        case 409:
          return {
            title: 'error.conflict.title',
            description: 'error.conflict.description',
            action: 'error.conflict.action',
          };
        default:
          return {
            title: 'error.default.title',
            description: 'error.default.description',
            action: 'error.default.action',
          };
      }
    },
  },
});
