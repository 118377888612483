import { redirectIfCheckAuthorityForPathUnauthorized } from './routeUtils';

export function getEquipmentPageRoutes(store, getRedirectRoute) {
  return [
    {
      path: '/equipment',
      name: 'equipment',
      component: () =>
        import(/* webpackChunkName: "equipmentView" */ '@/views/EquipmentView'),
      beforeEnter(to, _from, next) {
        redirectIfCheckAuthorityForPathUnauthorized(
          to,
          next,
          store,
          getRedirectRoute,
          store.get('fpgConfiguration/isTileMode'),
          'equipment'
        );
      },
      children: [
        {
          path: '',
          name: 'MyEquipment',
          component: () => import('@/views/equipment/MyEquipment'),
          meta: { authorityPath: 'auth/canViewEquipment' },
          beforeEnter(to, _from, next) {
            redirectIfCheckAuthorityForPathUnauthorized(
              to,
              next,
              store,
              getRedirectRoute,
              store.get('fpgConfiguration/isTileMode')
            );
          },
        },
        {
          path: 'decommissioned',
          name: 'decommissionedEquipment',
          component: () =>
            import(
              /* webpackChunkName: "decommissioned" */ '@/views/equipment/DecommissionedEquipment'
            ),
          meta: { authorityPath: 'auth/canViewDecommission' },
          beforeEnter(to, from, next) {
            redirectIfCheckAuthorityForPathUnauthorized(
              to,
              next,
              store,
              getRedirectRoute,
              store.get('fpgConfiguration/isTileMode')
            );
          },
        },
      ],
    },
    {
      path: '/equipment/:id',
      name: 'equipmentDetails',
      component: () =>
        import(
          /* webpackChunkName: "unauthorized" */ '@/views/equipment/EquipmentDetail'
        ),
      meta: { authorityPath: 'auth/canViewEquipment' },
      beforeEnter(to, _from, next) {
        redirectIfCheckAuthorityForPathUnauthorized(
          to,
          next,
          store,
          getRedirectRoute,
          store.get('fpgConfiguration/isTileMode')
        );
      },
    },
  ];
}
