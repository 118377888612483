const state = {
  currentData: {
    userId: '',
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    region: '',
    postalCode: '',
    primaryPhone: '',
    countryCode: '',
    lastLoginTime: '',
    firstLoginTime: '',
    inviteStatus: '',
    inviteTime: '',
    roles: [],
    applicationNames: [],
    primary: false,
    employeeId: '',
    primaryPhoneCountryCode: '',
    primaryPhoneIso2code: '',
    driver: false,
    segmentIds: [],
    driverPin: '',
    language: '',
    reimbursableAddress: {},
    reimbursableLocations: [],
  },
  userDetails: {
    userId: '',
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    region: '',
    postalCode: '',
    primaryPhone: '',
    countryCode: '',
    lastLoginTime: '',
    firstLoginTime: '',
    inviteStatus: '',
    inviteTime: '',
    roles: [],
    applicationNames: [],
    primary: false,
    employeeId: '',
    primaryPhoneCountryCode: '',
    primaryPhoneIso2code: '',
    driver: false,
    segmentIds: [],
    driverPin: '',
    language: '',
    reimbursableAddress: {},
    reimbursableLocations: [],
  },
  valid: false,
  rolesToAdd: [],
  rolesToRemove: [],
  hasOneOrMoreApplicationRoles: false,
};

export { state };
