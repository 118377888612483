const getters = {
  selected: (state) => {
    return state.selected;
  },
  isSelected: (state) => (id) => {
    return state.selected[id] !== undefined;
  },
  selectedLength: (state) => {
    return Object.keys(state.selected).length;
  },
  otaFilter: (state) => {
    return state.otaFilter;
  },
  areFiltersOrSearchApplied: (state) => {
    let keys = Object.keys(state.otaFilter);
    return (
      keys.length !== 0 ||
      (state.searchQuery !== '' && state.searchQuery !== null)
    );
  },
  paginationQuery: (state) => {
    return state.paginationQuery;
  },
  searchQuery: (state) => {
    return state.searchQuery;
  },
  totalOTAs: (state) => {
    return state.totalOTAs;
  },
  totalFilteredOTAs: (state) => {
    return state.totalFilteredOTAs;
  },
  blockUnblockPayload: (state) => {
    return {
      vehicles: state.vehicles,
      displayName: state.displayName,
    };
  },
};

export { getters };
