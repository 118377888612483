var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(['ENROLLED', 'SUBSCRIBED'].includes(_vm.status))?_c('v-icon',{attrs:{"data-qa":"vehicle-status-icon-success","color":_vm.color ? _vm.color : 'success'}},[_vm._v("ic-circle-check")]):(
      [
        'PROCESSING_UNENROLLMENT',
        'PROCESSING_ENROLLMENT',
        'PROCESSING' ].includes(_vm.status)
    )?_c('v-icon',{attrs:{"data-qa":"vehicle-status-icon-warning","color":_vm.color ? _vm.color : 'warning'}},[_vm._v("ic-clock ")]):(
      ['UNENROLLMENT_ALERT', 'ENROLLMENT_ALERT', 'ACTION'].includes(_vm.status)
    )?_c('v-icon',{attrs:{"data-qa":"vehicle-status-icon-error","color":_vm.color ? _vm.color : 'error'}},[_vm._v("ic-alert ")]):_vm._e(),(_vm.displayStatusText)?_c('span',{staticClass:"statusText",attrs:{"data-qa":"status-text"}},[_vm._v(" "+_vm._s(_vm.statusText)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }