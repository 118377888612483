import axios from 'axios';
import configuration from '@/store/configuration';

const AccountStaticService = axios.create({});
AccountStaticService.interceptors.request.use((config) => {
  config.baseURL = configuration.state.accountStaticBaseUrl;
  return config;
});

export default AccountStaticService;
