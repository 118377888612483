const actions = {
  pushBreadcrumbs: ({ commit }, payload) => {
    commit('PUSH_BREADCRUMBS', payload);
  },
  popBreadcrumbs: ({ commit }, payload) => {
    commit('POP_BREADCRUMBS', payload);
  },
  emptyBreadcrumbs: ({ commit }) => {
    commit('EMPTY_BREADCRUMBS');
  },
  setBreadcrumbs: ({ commit }, index) => {
    commit('SET_BREADCRUMBS', index);
  },
};

export { actions };
