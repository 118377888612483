import Vue from 'vue';
import AccountApiProxyService from '@/services/AccountApiProxyService';
import { AccountConstants } from '@/constants';

export default new Vue({
  methods: {
    bulkConsentVehicle(bulkConsentRequest) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/consent/bulk',
        bulkConsentRequest
      );
    },
    bulkDeconsentVehicle(bulkConsentRequest) {
      return AccountApiProxyService.delete(
        AccountConstants.ACCOUNT_API_PATH + '/consent/bulk',
        {
          data: { ...bulkConsentRequest },
        }
      );
    },
    getLegalLanguage(consumerId, productFamilyIds) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/consent/legalLanguage',
        {
          consumerId: consumerId,
          productFamilyIds: productFamilyIds,
        }
      );
    },
    getLegalLanguages(consumerIds) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/consent/legalLanguages',
        {
          consumerIds: consumerIds,
        }
      );
    },
    getCompanyLegalLanguage(companyId, productFamilyIds) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/consent/company/legalLanguage',
        {
          companyId: companyId,
          productFamilyIds: productFamilyIds,
        }
      );
    },
    updateAutoConsent(consumerId) {
      let url = '/consent/autoConsent';
      if (consumerId !== '' && consumerId != null) {
        url = url + '/' + consumerId;
      }
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + url
      );
    },
    getAutoConsentStatus() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/consent/autoConsent/info'
      );
    },
    getConsents(vehicleIds) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/consent/remove/validate',
        vehicleIds
      );
    },
  },
});
