const state = {
  equipmentFilter: {},
  totalEquipment: 0,
  searchQuery: '',
  selected: {},
  paginationQuery: {},
  totalFilteredEquipment: 0,
};

export { state };
