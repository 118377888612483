import store from '@/store';

export function format(value, currencyCode) {
  if (!value && value !== 0) return '';
  if (!currencyCode) return value;
  const language = store.state.auth['language']
    ? store.state.auth['language']
    : 'en-US';
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currencyCode,
  }).format(value);
}

export function unFormat(value, currencyCode) {
  if (!value) return '';
  if (!currencyCode) return value;
  const currencySymbol = getCurrencySymbol(currencyCode);
  const decimal = getDecimal(currencyCode);
  value =
    decimal === ','
      ? value.toString().replace(/\./g, '').replace(/,/g, '.')
      : value.toString().replace(/,/g, '');
  return parseFloat(
    value.replace(currencySymbol, '').replace(/ /g, '')
  ).toFixed(2);
}

export function getCurrencySymbol(currencyCode) {
  return getCurrencyProperty(currencyCode, 'currency');
}

export function getDecimal(currencyCode) {
  return getCurrencyProperty(currencyCode, 'decimal');
}

function getCurrencyProperty(currencyCode, property) {
  if (!currencyCode) return '';
  const language = store.state.auth['language']
    ? store.state.auth['language']
    : 'en-US';
  const parts = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currencyCode,
  }).formatToParts(20.21);
  return parts.find((item) => property === item.type).value;
}
