import { checkAuthorityForPath } from '@/router/routeUtils';

export const getOwnerVehiclePageRoutes = (store) => ({
  path: '/consent',
  component: () =>
    import(/* webpackChunkName: "consent" */ '@/views/ConsentView.vue'),
  beforeEnter(to, from, next) {
    checkAuthorityForPath(to.meta.authorityPath, next, store, false, 'consent');
  },
  children: [
    {
      path: '',
      name: 'consent',
      component: () => import('@/views/consent/MyFleet.vue'),
      meta: { authorityPath: 'auth/canViewVehicleConsent' },
      beforeEnter(to, from, next) {
        checkAuthorityForPath(to.meta.authorityPath, next, store);
      },
    },
    {
      path: 'add',
      name: 'consentAddVehicles',
      component: () =>
        import(
          /* webpackChunkName: "consentAddVehicles" */ '@/views/consent/AddVehicles.vue'
        ),
      meta: { authorityPath: 'auth/canViewAddVehicleConsent' },
      beforeEnter(to, from, next) {
        checkAuthorityForPath(to.meta.authorityPath, next, store);
      },
    },
    {
      path: 'trash',
      name: 'consentTrash',
      component: () =>
        import(
          /* webpackChunkName: "consentAddVehicles" */ '@/views/vehicles/TrashView.vue'
        ),
      meta: { authorityPath: 'auth/canViewTrashCanForOwner' },
      beforeEnter(to, from, next) {
        checkAuthorityForPath(to.meta.authorityPath, next, store);
      },
    },
  ],
});
