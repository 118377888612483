<template>
  <v-flex>
    <h2
      data-qa="vehicle-selection-form-header"
      class="headline primary--text stepper-header text-uppercase"
      >{{ $t('productUpgradeStepper.vehicleSelectionStepTitle') }}</h2
    >
    <br />
    <p
      data-qa="vehicle-selection-form-description"
      class="confirm-text"
      v-html="$t('productUpgradeStepper.vehicleSelectionStepDescription')"
    ></p>
    <v-container class="grid-list-md">
      <MyFleet
        class="layout"
        :visible-columns="[
          'ID',
          'VIN',
          'Products',
          'Groups',
          'Model',
          'Year',
          'Fuel',
        ]"
        :display-add-vehicle="false"
        :display-customize-fields="false"
        :display-export-data-dropdown="false"
        :display-filter-buttons="false"
        :display-bulk-action-button="false"
        :vehicle-edit-drawer="false"
        :display-close-chip="false"
        :custom-filter="customFilter"
        :unique-id="`${Math.random()}-vehicle-selection-form`"
      ></MyFleet>
    </v-container>
  </v-flex>
</template>

<script>
import MyFleet from '@/views/vehicles/MyFleet';
import { mapGetters } from 'vuex';

export default {
  name: 'VehicleSelectionForm',
  components: { MyFleet },
  computed: {
    ...mapGetters('vehicle', ['vehicleFilter']),
    customFilter() {
      return {
        vehicleList: this.vehicleFilter.vehicleList,
        externalFilterDisplay: this.vehicleFilter.externalFilterDisplay,
      };
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 1264px) {
  .container.grid-list-md {
    max-width: 100%;
    margin: auto 0;
  }
}

.confirm-text {
  text-align: center;
  font-size: 16px;
  padding-right: 5px;
  margin-bottom: 0;
}

h2 {
  display: flex;
  justify-content: center;
}
</style>
