import axios from 'axios';
import { gatewayInterceptor } from '@/services/interceptors/GatewayInterceptor';
import configuration from '@/store/configuration';

const AccountApiProxyServiceForDownload = axios.create({});
AccountApiProxyServiceForDownload.interceptors.request.use(gatewayInterceptor);
AccountApiProxyServiceForDownload.interceptors.request.use((config) => {
  config.baseURL = configuration.state.accountApiProxyBaseUrl;
  config.headers = configuration.state.accountApiProxyHeaders;
  config.timeout = 600000;
  return config;
});
export default AccountApiProxyServiceForDownload;
