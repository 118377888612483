<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-menu
    nudge-top="-16pt"
    v-model="model"
    :close-on-content-click="false"
    max-width="275px"
    v-if="items && items.length > 0"
    :data-qa="`item-tooltip-${dashify(name)}`"
  >
    <template v-slot:activator="{ on }">
      <icon-button
        :data-qa="`item-tooltip-activator-${dashify(name)}`"
        v-on="on"
        @click.stop=""
        :aria-label="$t('grid.toolTip.button.label')"
        icon-tag="more_horiz"
      />
    </template>
    <v-card>
      <v-card-text class="pt-3">
        <div v-bind:key="item" v-for="item in items">
          <div
            class="pt-1"
            data-qa="item-tooltip-item"
            @click="$emit('action', getItemName(item))"
            role="status"
            >{{ getItemName(item) }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import dashify from 'dashify';
import IconButton from '@/components/utility-components/IconButton';

export default {
  name: 'DataGridItemTooltip',
  components: { IconButton },
  data() {
    return {
      model: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    items: Array,
    name: String,
    translate: {
      type: Boolean,
      default: false,
    },
    internationalizeRoot: String,
  },
  watch: {
    show(newShow) {
      this.model = newShow;
    },
  },
  methods: {
    dashify,
    getItemName(item) {
      if (this.itemName !== undefined) {
        return this.itemName(item);
      } else if (this.translate) {
        return this.$tf(this.internationalizeRoot + item, item);
      } else {
        return item;
      }
    },
  },
};
</script>

<style scoped></style>
