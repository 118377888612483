const state = {
  linkAssetFilter: {},
  totalLinkedAsset: 0,
  searchQuery: '',
  selected: {},
  paginationQuery: {},
  totalFilteredLinkAsset: 0,
  hasDefaultFilter: {
    showFilterChip: false,
    productFamilyId: null,
  },
  existingVehicleLinks: {},
};

export { state };
