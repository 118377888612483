const state = {
  otaFilter: {},
  totalOTAs: 0,
  totalFilteredOTAs: 0,
  searchQuery: '',
  paginationQuery: {},
  blockAction: false,
  selected: {},
  displayName: '',
  vehicles: [],
};

export { state };
