const actions = {
  setUpdateLastCalled: ({ commit }, payload) => {
    commit('SET_UPDATE_LAST_CALLED', payload);
  },
  fetch: async (
    { dispatch, getters, state, rootGetters },
    { getter, getterPayload, setter }
  ) => {
    let ttl = rootGetters['configuration/defaultCacheTimeToLive'];
    let lastCalled = rootGetters['cache/getLastCalled'](setter);
    if (!lastCalled || lastCalled + ttl < Date.now()) {
      await dispatch(setter, null, { root: true });
      dispatch('setUpdateLastCalled', setter);
    }
    let resolvedGetter = rootGetters[getter];
    if (typeof resolvedGetter === 'function') {
      return resolvedGetter(getterPayload);
    } else {
      return resolvedGetter;
    }
  },
  fetchAsync: ({ dispatch, getters, state, rootGetters }, setter) => {
    let ttl = rootGetters['configuration/defaultCacheTimeToLive'];
    let lastCalled = rootGetters['cache/getLastCalled'](setter);
    if (!lastCalled || lastCalled + ttl < Date.now()) {
      dispatch('setUpdateLastCalled', setter);
      dispatch(setter, null, { root: true });
    }
  },
  invalidate: ({ commit }, payload) => {
    commit('INVALIDATE_LAST_CALLED_KEY', payload);
  },
};

export { actions };
