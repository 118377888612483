<template onload="getStartedAction()">
  <footer class="account-footer caption" data-qa="footer">
    <div class="left-links">
      <router-link
        to="/manage-cookies"
        v-if="accountConstants.ONE_TRUST.ENABLE"
        class="footer-link"
        data-qa="manage-cookies"
      >
        <v-icon small class="footer-icon">ic-cog</v-icon>
        {{ $t('footer.manageCookies') }}
      </router-link>
      <v-btn
        @click.native.prevent="showContactUs"
        data-qa="contact-us"
        class="footer-link footer-button"
        small
        flat
      >
        <v-icon small class="footer-icon">ic-contactUs</v-icon>
        {{ $t('footer.contactUs') }}
      </v-btn>
      <div class="get-started footer-item-text" data-qa="get-started">
        <a :href="getGetStartedPdfUrl" class="get-started-link" target="_blank">
          <v-icon small class="footer-icon">ic-info-circle</v-icon>
          {{ $t('footer.getStarted') }}
        </a>
      </div>
    </div>
    <div class="copyright-info" data-qa="copyright-info">
      {{
        $t('footer.copyright', {
          copyrightYear: accountConstants.copyrightYear,
        })
      }}
    </div>
    <ContactUsDialog
      :show-dialog="showContactUsDialog"
      @close-dialog="showContactUsDialog = false"
    />
  </footer>
</template>

<script>
import { AccountConstants } from '@/constants';
import ContactUsDialog from '@/components/ContactUsDialog';
import { mapGetters } from 'vuex';

export default {
  name: 'TheFooter',
  components: {
    ContactUsDialog,
  },
  data() {
    return {
      accountConstants: AccountConstants,
      showContactUsDialog: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['getLanguage', 'getGetStartedPdfUrl']),
  },
  methods: {
    showContactUs() {
      this.showContactUsDialog = true;
    },
  },
};
</script>

<style scoped lang="scss">
.left-links {
  display: flex;
  gap: 1rem;
}

.footer-icon.v-icon {
  color: var(--v-secondary-base) !important;
}

.copyright-info {
  margin-left: auto;
}

.account-footer {
  display: flex;
  margin: 0.5rem 1.5rem;
  color: var(--v-secondary-base);

  a {
    text-decoration: none;
    color: var(--v-secondary-base);
  }

  .footer-button {
    text-transform: none;
    margin: 0;
    color: var(--v-secondary-base);
    font-size: 12px;
    font-weight: 400;
    height: 16px;
  }
}
</style>
