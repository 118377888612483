var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('h2',{staticClass:"headline primary--text stepper-header text-uppercase",attrs:{"data-qa":"vehicle-selection-form-header"}},[_vm._v(_vm._s(_vm.$t('productUpgradeStepper.vehicleSelectionStepTitle')))]),_c('br'),_c('p',{staticClass:"confirm-text",attrs:{"data-qa":"vehicle-selection-form-description"},domProps:{"innerHTML":_vm._s(_vm.$t('productUpgradeStepper.vehicleSelectionStepDescription'))}}),_c('v-container',{staticClass:"grid-list-md"},[_c('MyFleet',{staticClass:"layout",attrs:{"visible-columns":[
        'ID',
        'VIN',
        'Products',
        'Groups',
        'Model',
        'Year',
        'Fuel' ],"display-add-vehicle":false,"display-customize-fields":false,"display-export-data-dropdown":false,"display-filter-buttons":false,"display-bulk-action-button":false,"vehicle-edit-drawer":false,"display-close-chip":false,"custom-filter":_vm.customFilter,"unique-id":((Math.random()) + "-vehicle-selection-form")}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }