<template>
  <div class="page">
    <div class="header">
      <h2
        class="page-title display-1 marketplace-heading-1"
        :data-qa="pageTitleDataQa"
      >
        {{ title }}
      </h2>
      <span v-if="headerText" class="link-section">
        {{ headerText }}
        <router-link
          class="link"
          :data-qa="headerLinkDataQa"
          :to="headerLinkTo"
        >
          {{ headerLinkText }}
        </router-link>
      </span>
    </div>
    <v-sheet class="px-3 pb-3 page-content" elevation="2">
      <slot></slot>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: 'PageComponent',
  props: {
    title: {
      type: String,
      required: true,
    },
    headerText: {
      type: String,
    },
    headerLinkText: {
      type: String,
    },
    headerLinkDataQa: {
      type: String,
    },
    headerLinkTo: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: baseline;
  margin-bottom: 18px;
  justify-content: space-between;
  flex-wrap: wrap;

  .page-title.display-1 {
    color: var(--v-primary-base);
    display: inline;
    margin-bottom: 10px;
    min-width: 180px;
  }

  .link-section {
    font-size: 18px;
    color: #292e35;

    .link {
      color: var(--v-secondary-base);
    }
  }
}

.page {
  padding: 2rem 1.5rem 0.5rem;
}

.page-title.display-1 {
  color: var(--v-primary-base);
  margin-bottom: 1.5rem;
}

.page-content {
  min-height: calc(100vh - 209px);
  display: flow-root;
}
</style>
