const mutations = {
  SET_UPDATE_LAST_CALLED: (state, payload) => {
    state.lastCalled[payload] = Date.now();
  },
  INVALIDATE_LAST_CALLED_KEY: (state, payload) => {
    delete state.lastCalled[payload];
  },
};

export { mutations };
