<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <v-dialog
    data-qa="processing-request-bar"
    v-model="showProcessing"
    persistent
    width="400"
  >
    <!-- eslint-enable vue/no-mutating-props -->
    <v-card color="white" dark>
      <v-card-text class="text--primary">
        {{ message }}
        <v-progress-linear
          indeterminate
          color="primary"
          class="mb-0"
          :aria-label="$t('common.loading')"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ProcessingDialog',
  props: {
    message: String,
    showProcessing: Boolean,
  },
};
</script>
