const state = {
  lastCalled: {},
};

const mockState = function () {
  return {
    lastCalled: {},
  };
};

export { state, mockState };
