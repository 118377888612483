import OnboardService from '@/services/OnboardService';
import store from '@/store';
import { i18n } from '@/i18n';

export function handleTermsAndConditionsDownload(
  legalLanguageByLlidId,
  productName,
  signedTcDate,
  signed
) {
  OnboardService.downloadFFMTermsAndConditions(
    legalLanguageByLlidId,
    !productName,
    signed
  )
    .then((response) => {
      const name = productName || 'FordPro';
      let filename = `${name}_TermsAndConditions_`;
      if (signedTcDate) {
        filename =
          'Signed_' + filename + `_${signedTcDate.toLocaleDateString()}.pdf`;
      } else {
        filename += `_${new Date().toLocaleDateString()}.pdf`;
      }
      downloadPDF(response.data, filename);
    })
    .catch(() => {
      store.dispatch(
        'notification/addErrorNotification',
        i18n.t('downloadPDF.failure')
      );
    });
}

function downloadPDF(content, fileName) {
  const linkSource = `data:application/pdf;base64,${content}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  store.dispatch(
    'notification/addSuccessNotification',
    i18n.t('downloadPDF.success')
  );
}

export function handleDownloadBlob(blob, filename) {
  const downloadLinkElement = document.createElement('a');
  downloadLinkElement.href = window.URL.createObjectURL(blob);
  downloadLinkElement.download = filename;
  document.body.appendChild(downloadLinkElement);
  downloadLinkElement.click();
  document.body.removeChild(downloadLinkElement);
  window.URL.revokeObjectURL(downloadLinkElement.href);
}

export function handleDownloadUrl(fileUrl) {
  const downloadLinkElement = document.createElement('a');
  downloadLinkElement.href = fileUrl;
  document.body.appendChild(downloadLinkElement);
  downloadLinkElement.click();
  document.body.removeChild(downloadLinkElement);
}

export function base64toBlob(base64Data, contentType) {
  contentType = contentType || '';
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export function getFileNameFromResponseHeader(headerLine) {
  const startFileNameIndex = headerLine.indexOf('"') + 1;
  const endFileNameIndex = headerLine.lastIndexOf('"');
  return headerLine.substring(startFileNameIndex, endFileNameIndex);
}
