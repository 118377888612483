<template>
  <v-btn
    :data-qa="dataQa"
    class="link-style-button"
    small
    flat
    @click="$emit('click')"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'LinkStyleButton',
  props: {
    dataQa: String,
  },
};
</script>
