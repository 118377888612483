import { AccountConstants } from '@/constants';

const getters = {
  selected: (state) => {
    return state.selected;
  },
  totalQuantity: (state) => {
    let subtotalQuantity = 0;
    let product;
    for (let productKey of Object.keys(state.selected)) {
      product = state.selected[productKey];
      subtotalQuantity += parseInt(product.quantity);
    }
    return subtotalQuantity;
  },
  currencyCode: (state) => {
    let currencyCode = AccountConstants.DEFAULT_CURRENCY_CODE;
    for (let productKey of Object.keys(state.selected)) {
      if (state.selected[productKey].currencyCode) {
        currencyCode = state.selected[productKey].currencyCode;
        break;
      }
    }
    return currencyCode;
  },
  paginationQuery: (state) => {
    return state.paginationQuery;
  },
  searchQuery: (state) => {
    return state.searchQuery;
  },
  orderFilter: (state) => {
    return state.orderFilter;
  },
  address: (state) => {
    return state.address;
  },
  taxResponse: (state) => {
    return state.taxResponse;
  },
  shippingResponse: (state) => {
    return state.shippingResponse;
  },
  driverAppEnabled: (state) => {
    return state.driverAppEnabled;
  },
  showUpgradeProductDialog: (state) => {
    return state.showUpgradeProductDialog;
  },
  getProductMap: (state) => {
    return state.productMap;
  },
};

export { getters };
