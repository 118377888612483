<template>
  <v-container grid-list-md text-xs-left class="pa-0">
    <v-layout row wrap>
      <slot name="message"></slot>
    </v-layout>
    <v-layout row wrap class="mt-3">
      <v-flex xs8>
        <slot name="search"> </slot>
      </v-flex>
      <v-flex xs4>
        <v-layout row justify-end>
          <slot name="action"></slot>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <slot name="metrics"></slot>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <slot></slot>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'DataGridLayout',
};
</script>

<style scoped lang="scss">
.container.grid-list-md {
  max-width: 100%;
}
</style>
