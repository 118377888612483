import Vue from 'vue';
import AccountApiProxyService from '@/services/AccountApiProxyService';
import store from '@/store';
import { AccountConstants } from '@/constants';

export default new Vue({
  methods: {
    getContactInfo() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/contact'
      );
    },
    async populateContactInfo() {
      if (this.isContactPopulated()) {
        return;
      }
      this.getContactInfo()
        .then((res) => {
          if (res.status === 200) {
            let contact = {};
            contact.phone = res.data.phoneNumber;
            contact.email = res.data.email;
            store.dispatch('contact/setContact', contact);
          }
        })
        .catch((err) => console.log(err));
    },
    isContactPopulated() {
      let contact = store.getters['contact/getContact'];
      return contact.email !== undefined && contact.phone !== undefined;
    },
    async populateContactInfoByCountryCode(countryCode) {
      AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          `/contact/findByCountryCode/${countryCode}`
      )
        .then((res) => {
          if (res.status === 200) {
            let contact = {};
            contact.phone = res.data.phoneNumber;
            contact.email = res.data.email;
            store.dispatch('contact/setContact', contact);
          }
        })
        .catch((err) => console.log(err));
    },
  },
});
