import { make } from 'vuex-pathify';
import { state as userEditDetails } from '@/store/userEditDetails/state';

const mutations = {
  ...make.mutations(userEditDetails),
  CLEAR_CURRENT_REIMBURSABLE_ADDRESS: (state) => {
    state.currentData.reimbursableAddress = {};
  },
};

export { mutations };
