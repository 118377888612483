import authService from '@/services/AuthService';
import defaultStore from '@/store';
import PrivacyPolicy from '@/views/PrivacyPolicy';
import TermsAndConditionsPolicy from '@/views/TermsAndConditionsPolicy';
import { AccountConstants } from '@/constants';
import { getVehiclePageRoutes } from '@/router/vehiclePageRoutes';
import { checkAuthorityForPath } from './routeUtils';
import { getOwnerVehiclePageRoutes } from '@/router/ownerVehiclePageRoutes';
import { getProductsPageRoutes } from '@/router/productsPageRoutes';
import { getEquipmentPageRoutes } from '@/router/equipmentPageRoutes';

export const getRoutes = (store) => {
  const routes = [
    ...(store.get('fpgConfiguration/isTileMode')
      ? []
      : [getProductsPageRoutes(store, findFirstAuthorizedRoutePath)]),
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () =>
        import(
          /* webpackChunkName: "unauthorized" */ '@/views/UnauthorizedView.vue'
        ),
    },
    getVehiclePageRoutes(store, findFirstAuthorizedRoutePath),
    ...getEquipmentPageRoutes(store, findFirstAuthorizedRoutePath),
    getOwnerVehiclePageRoutes(store),
    {
      path: '/users',
      name: 'users',
      component: () =>
        import(/* webpackChunkName: "users" */ '@/views/UsersView.vue'),
      meta: { authorityPath: 'auth/canViewUsers' },
      beforeEnter(to, _from, next) {
        checkAuthorityForPath(
          to.meta.authorityPath,
          next,
          store,
          false,
          'users'
        );
      },
    },
    {
      path: '/groups',
      name: 'groups',
      component: () =>
        import(/* webpackChunkName: "groups" */ '@/views/GroupsView.vue'),
      meta: { authorityPath: 'auth/canViewGroups' },
      beforeEnter(to, _from, next) {
        checkAuthorityForPath(to.meta.authorityPath, next, store);
      },
    },
    {
      path: '/billing',
      name: 'billing',
      component: () =>
        import(/* webpackChunkName: "billing" */ '@/views/BillingView.vue'),
      meta: { authorityPath: 'auth/canViewBilling' },
      beforeEnter(to, _from, next) {
        checkAuthorityForPath(to.meta.authorityPath, next, store);
      },
    },
    {
      path: '/manage-cookies',
      name: 'cookie-management',
      component: () =>
        import(
          /* webpackChunkName: "cookieManagement" */ '@/components/cookie-management/CookieManagementComponent.vue'
        ),
    },
    {
      path: '/policy',
      name: 'cookie-privacy-policy',
      component: () =>
        import(
          /* webpackChunkName: "cookiePrivacyPolicy" */ '@/components/cookie-management/CookiePrivacyPolicyComponent.vue'
        ),
    },
    {
      path: '/invalid-state',
      name: 'invalid-state',
      component: () =>
        import(
          /* webpackChunkName: "invalidState" */ '@/views/InvalidState.vue'
        ),
      meta: {
        unprotected: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      beforeEnter(to, _from, _next) {
        if (to.query.return_page) {
          return authService.goToLogin(to.query.return_page);
        } else {
          return authService.goToLogin();
        }
      },
      meta: {
        unprotected: true,
      },
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter() {
        authService.goToLogout();
      },
      meta: {
        unprotected: true,
      },
    },
    {
      path: '/Privacy',
      name: 'privacy',
      component: PrivacyPolicy,
      meta: { unprotected: true },
      beforeEnter(to, _from, next) {
        checkSignedTermsAndConditions(to, next, true);
      },
    },
    {
      path: '/termsAndConditions',
      name: 'termsAndConditions',
      component: TermsAndConditionsPolicy,
      meta: { unprotected: true },
      beforeEnter(to, _from, next) {
        checkSignedTermsAndConditions(to, next, true);
      },
    },
    {
      path: '/onboard',
      name: 'onboard',
      component: () =>
        import(
          /* webpackChunkName: "Onboard" */ '@/components/OnboardComponent.vue'
        ),
      beforeEnter(_to, _from, next) {
        if (!store.getters['termsAndConditions/canViewOnboardPage']) {
          next('/');
        }
        next();
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () =>
        import(
          /* webpackChunkName: "Settings" */ '@/views/settings/SettingsView.vue'
        ),
      children: [
        {
          path: 'user',
          name: 'userSettings',
          component: () =>
            import(
              /* webpackChunkName: "UserSettings" */ '@/views/settings/UserSettings.vue'
            ),
          beforeEnter(to, _from, next) {
            checkSignedTermsAndConditions(to, next, false);
          },
        },
        {
          path: 'company',
          component: () =>
            import(
              /* webpackChunkName: "CompanySettings" */ '@/views/settings/CompanySettings.vue'
            ),
          name: 'companySettings',
          meta: { authorityPath: 'auth/canViewCompanySettings' },
          beforeEnter(to, _from, next) {
            checkAuthorityForPath(to.meta.authorityPath, next, store);
          },
        },
      ],
    },
    {
      path: '/userSettings',
      name: 'oldUserSettings',
      redirect: '/settings/user',
    },
    {
      path: '/swagger',
      name: 'swagger',
      meta: { authorityPath: 'auth/canViewSwagger' },
      beforeEnter(to, _from, next) {
        checkAuthorityForPath(to.meta.authorityPath, next, store);
      },
      redirect:
        '/account-api/swagger-ui/index.html?configUrl=/account-api/v3/api-docs/swagger-config',
    },
    {
      path: '/customer-signup',
      name: 'signup',
      meta: {
        unprotected: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "signup" */ '@/components/customer-signup/SignupFormComponent.vue'
        ),
    },
    {
      path: '/third-party-signup',
      name: 'thirdPartySignup',
      meta: {
        unprotected: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "signup" */ '@/components/customer-signup/SignupFormComponent.vue'
        ),
    },
    {
      path: '/upgrade-fte',
      name: 'ProductUpgradeStepper',
      component: () =>
        import(
          /* webpackChunkName: "ProductUpgradeStepper" */ '@/components/product/ProductUpgradeStepper.vue'
        ),
      meta: { authorityPath: 'auth/canViewFtUpgrade' },
      props: {
        isUpgrade: true,
        cancelRedirectRoute: '/',
        dataQaKey: 'upgrade-fte',
        products: [
          {
            productFamilyName:
              AccountConstants.FORD_TELEMATICS_PRODUCT_FAMILY_NAME,
            get productFamilyId() {
              return store.get('configuration/fordTelematicsProductFamilyId');
            },
            productSku: AccountConstants.FORD_TELEMATICS_30S_PRODUCT_SKU,
          },
        ],
        get oldProductFamilyId() {
          return store.get(
            'configuration/fordTelematicsEssentialsProductFamilyId'
          );
        },
        oldProductFamilyName:
          AccountConstants.FORD_TELEMATICS_ESSENTIALS_PRODUCT_FAMILY_NAME,
      },
      beforeEnter(to, _from, next) {
        checkAuthorityForPath(to.meta.authorityPath, next, store);
      },
    },
    {
      path: '*',
      name: 'NotFound',
      meta: {
        unprotected: true,
      },
      component: () => import('@/components/error/NotFound.vue'),
    },
  ];

  function checkSignedTermsAndConditions(_to, next, isUnauthorizedAccess) {
    if (
      (isUnauthorizedAccess && !store.getters['auth/getIsLoggedIn']) ||
      !store.getters['termsAndConditions/canViewOnboardPage']
    ) {
      next();
      return;
    }
    next('/onboard');
  }

  function _findFirstAuthorizedRoutePath(routesAsArg) {
    for (let route of routesAsArg) {
      if (route.children) {
        let result = _findFirstAuthorizedRoutePath(route.children);
        if (result !== undefined) {
          return result ? route.path + '/' + result : route.path;
        }
      } else {
        const isNotTileModeOrRouteLaunchedInFpg =
          !store.get('fpgConfiguration/isTileMode') ||
          store
            .get('configuration/fpgLaunchedSidebarItems')
            .includes(route.name);
        if (
          route.meta &&
          route.meta.authorityPath !== undefined &&
          store.getters[route.meta.authorityPath]() &&
          isNotTileModeOrRouteLaunchedInFpg
        ) {
          return route.path;
        }
      }
    }
  }

  function findFirstAuthorizedRoutePath() {
    return _findFirstAuthorizedRoutePath(routes) || '/unauthorized';
  }

  return routes;
};

export default getRoutes(defaultStore);
