<!--suppress CssInvalidPseudoSelector -->
<template>
  <div class="snack-group-container">
    <transition-group name="snackbar-transition" class="snackbar-group">
      <v-snackbar
        :data-qa="`snackbar-item-${notification.id}`"
        class="white--text"
        :color="`${snackbarColor(notification)}`"
        v-for="notification in activeQueue.slice().reverse()"
        :key="notification.id"
        :value="true"
        @input="dismiss(notification.id)"
        :timeout="0"
        transition=""
      >
        <div class="snack-icon-container">
          <v-icon
            data-qa="snack-icon-success"
            v-if="notification.type === 'success'"
            color="white"
          >
            fal fa-check-circle
          </v-icon>
          <v-icon
            data-qa="snack-icon-error"
            v-else-if="notification.type === 'error'"
            color="white"
          >
            fal fa-exclamation-circle
          </v-icon>
          <v-icon data-qa="snack-icon-information" v-else color="white">
            fal fa-info-circle
          </v-icon>
        </div>
        <div class="snackbar-text" data-qa="snackbar-text">
          {{ notification.text }}
        </div>

        <v-btn
          data-qa="snackbar-dismiss-button"
          class="white--text text-none"
          aria-label="dismiss"
          flat
          @click="dismiss(notification.id)"
        >
          <v-icon>ic-close</v-icon>
        </v-btn>
      </v-snackbar>
    </transition-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

let timeoutHandle;

export default {
  name: 'NotificationQueue',
  data: () => {
    return {
      baseTimeout: 5000,
      notificationBackgroundColor: {
        success: '#6DB812',
        error: '#E04935',
        information: '#5C5B5B',
      },
    };
  },
  computed: {
    ...mapGetters('notification', ['activeQueue']),
  },
  methods: {
    snackbarColor(notification) {
      return this.notificationBackgroundColor[notification.type];
    },
    dismiss(id) {
      this.removeNotification(id);
    },
    ...mapActions('notification', ['removeNotification']),
  },
  watch: {
    activeQueue() {
      if (timeoutHandle) {
        clearTimeout(timeoutHandle);
      }
      timeoutHandle = setTimeout(
        (t) => {
          if (t.activeQueue.length > 0) t.dismiss(t.activeQueue[0].id);
        },
        this.baseTimeout,
        this
      );
    },
  },
};
</script>

<style scoped lang="scss">
.snack-group-container {
  $trTime: 0.1s;

  display: flex;
  justify-content: center;

  .snack-icon-container {
    margin-right: 10px;
  }

  .v-snack--right.v-snack--top {
    transform: none;
  }

  .snackbar-group {
    position: fixed;
    top: 50px;
    z-index: 1000000;
    width: 600px;
  }

  .v-snack {
    position: relative;
    padding-top: 1em;

    ::v-deep .v-snack__wrapper > .v-snack__content {
      height: auto;
    }
  }

  .snackbar-text {
    width: 500px;
    font-size: medium;
  }

  .snackbar-transition {
    &-enter {
      &-active,
      &-active ::v-deep .v-snack__wrapper {
        transition: all $trTime linear;
      }

      ::v-deep .v-snack__wrapper {
        transform: scale(0.6);
        opacity: 0;
      }

      &-to ::v-deep .v-snack__wrapper {
        opacity: 1;
      }
    }

    &-leave {
      &-active {
        transition: opacity $trTime linear;
        position: absolute;
      }

      &-to {
        opacity: 0;
      }

      opacity: 1;
    }

    &-move {
      transition: transform $trTime linear;
    }
  }

  @media only screen and (max-width: 600px) {
    .snackbar-group {
      width: 95%;
    }
  }
}
</style>
