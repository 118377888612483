import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import {
  faCheckCircle,
  faCopy,
  faDownload,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faPhoneAlt,
  faPlus,
  faTrash,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

import '@/plugins/vuetify';
import '@/plugins/VueCookies';
import idleVue from '@/plugins/idleVue';
import { i18n } from '@/i18n';
import App from '@/App';
import router from '@/router/router';
import store from '@/store';
import oneTrustService from '@/services/OneTrustService';
import RefreshTokenService from '@/services/RefreshTokenService';
import UserService from '@/services/UserService';
import VehicleService from '@/services/VehicleService';
import adobeDTMService from '@/services/AdobeDTMService';

library.add(
  faTrash,
  faCopy,
  faPlus,
  faExclamationTriangle,
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faDownload,
  faTrashAlt,
  faEnvelope,
  faPhoneAlt
);
Vue.component('font-awesome-icon', FontAwesomeIcon);

(function () {
  oneTrustService.doGenerateOneTrust();
  adobeDTMService.generateAdobeDTMScripts();
})();

let providedServices = {};
providedServices['UserService'] = UserService;
providedServices['VehicleService'] = VehicleService;

Vue.config.productionTip = false;
Vue.use(idleVue, { store, idleTime: 15 * 60 * 1000 });

store.set('configuration/populate!', undefined).then(() => {
  new Vue({
    i18n,
    router,
    store,
    provide: providedServices,
    render: (h) => h(App),
    mounted() {
      RefreshTokenService.setJob();
    },
  }).$mount('#app');
});
