<!--suppress ALL -->
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-menu :close-on-content-click="false" v-model="menu" min-width="400px">
    <template v-slot:activator="{ on }">
      <div class="edit-label">
        <p
          :class="[
            'subheading font-weight-bold',
            disableEdit && !active ? 'disabled-label' : '',
          ]"
          :data-qa="`text-field-label-${dashify(dataQaKey)}`"
        >
          {{ name }}
        </p>
        <v-icon v-if="active" v-on="on" class="secondary--text" small>
          ic-edit
        </v-icon>
        <v-icon
          :disabled="true"
          v-else-if="disableEdit"
          class="secondary--text"
          small
        >
          ic-edit
        </v-icon>
        <span v-if="showIcon" class="pl-2">
          <IconTooltip
            :data-qa-key="`${dashify(dataQaKey)}-info`"
            :tooltip-message="tooltipMessage"
          />
        </span>
      </div>
      <button
        type="button"
        flat
        :data-qa="`text-field-activator-${dashify(dataQaKey)}`"
        class="text-truncate field-activator"
        v-on="on"
        :aria-label="`${$t('common.edit')} ${name}`"
        v-if="active"
        ref="activeButton"
      >
        {{ field !== '' && field !== null ? field : '—' }}
      </button>
      <button
        type="button"
        flat
        :data-qa="`text-field-activator-${dashify(dataQaKey)}`"
        class="text-truncate field-activator"
        disabled="true"
        :aria-label="$t('common.edit')"
        v-else
      >
        {{ field !== '' && field !== null ? field : '—' }}
      </button>
    </template>
    <v-card>
      <v-text-field
        :maxlength="maxLength"
        ref="fieldInput"
        :rules="rules"
        :data-qa="`text-field-input-${dashify(dataQaKey)}`"
        @input="$emit('value-changed', $event)"
        @keydown.enter="
          menu = false;
          $emit('value-changed', field);
        "
        :value="field"
        :error-messages="errors"
        single-line
        solo
        :placeholder="name"
        :hint="isChargingReimbursement ? $t('addUser.rules.maxCharacters') : ''"
        data-qm-encrypt="true"
      />
    </v-card>
  </v-menu>
</template>

<script>
import dashify from 'dashify';
import IconTooltip from '@/components/IconTooltip';

export default {
  name: 'TextFieldDialog',
  components: { IconTooltip },
  props: {
    active: Boolean,
    disableEdit: {
      type: Boolean,
      default: false,
    },
    field: [String, Number],
    name: String,
    dataQaKey: String,
    rules: Array,
    errors: Array,
    showIcon: {
      type: Boolean,
      default: false,
    },
    tooltipMessage: String,
    maxLength: Number,
    isChargingReimbursement: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    dashify(str) {
      return dashify(str);
    },
  },
  watch: {
    menu(val) {
      if (val) {
        setTimeout(() => this.$refs.fieldInput.focus(), 100);
      } else {
        setTimeout(() => this.$refs.activeButton.focus(), 100);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-dialog {
  max-width: 400px;
}

.disabled-label {
  color: rgba(0, 0, 0, 0.38) !important;
}

.field-activator {
  font-size: 16px;
  width: 100%;
  text-align: left;
  &:focus {
    border: 1px dashed lightgray;
  }
}

::v-deep {
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: none;
    margin-bottom: 0;
  }

  .v-menu__content {
    overflow: scroll;
  }
}

::v-deep .v-input__slot {
  border: none;
}

.edit-label {
  * {
    display: inline;
  }

  .v-icon {
    vertical-align: middle;
    padding-bottom: 4px;
    padding-left: 0.5em;
  }

  padding-top: 4px;
}
</style>
