import { isEqual } from 'lodash';
import { AccountConstants } from '@/constants';

function shouldBeVisible(child) {
  return (
    child.hasOwnProperty('customerTaxId') &&
    typeof child.customerTaxId === 'string' &&
    child.customerTaxId.length
  );
}

const getters = {
  companyName: (state) => {
    return state.companyName;
  },
  address1: (state) => {
    return state.address1;
  },
  address2: (state) => {
    return state.address2;
  },
  childAccounts: (state) => {
    return state.childAccounts;
  },
  visibleChildAccounts: (state) => {
    return state.childAccounts
      .map((child, index) => {
        child.index = index;
        return child;
      })
      .filter((child) => {
        return shouldBeVisible(child);
      });
  },
  hiddenChildAccounts: (state) => {
    return state.childAccounts
      .map((child, index) => {
        child.index = index;
        return child;
      })
      .filter((child) => {
        return !shouldBeVisible(child);
      });
  },
  city: (state) => {
    return state.city;
  },
  countryCode: (state) => {
    return state.countryCode;
  },
  state: (state) => {
    return state.state.name;
  },
  zipCode: (state) => {
    return state.zipCode;
  },
  taxId: (state) => {
    return state.taxId;
  },
  isFetchingData: (state) => {
    return state.isFetchingData;
  },
  isFetchingChildAccountData: (state) => {
    return state.isFetchingChildAccountData;
  },
  initialBillingInfo: (state) => {
    return state.initialBillingInfo;
  },
  isAddressModified: (state) => {
    return isEqual(state.rawBillingInfo, state.initialBillingInfo) === false;
  },
  getCompanyBillingInformation: (state) => {
    if (
      state.address1 !== '' &&
      state.address1 !== null &&
      state.address1 !== undefined
    ) {
      let address = state.address1 + '\n';
      if (
        state.address2 !== '' &&
        state.address2 !== null &&
        state.address2 !== undefined
      ) {
        address += state.address2 + '\n';
      }
      address += state.city + '\n';
      if (
        state.state.name !== '' &&
        state.state.name !== null &&
        state.state.name !== undefined
      ) {
        address += state.state.name + ', ';
      }
      address += state.zipCode + '\n';
      address += state.countryCode + '\n';
      return address;
    }
    return '';
  },
  getCompanyBillingAddressFormattedToTwoLines: (state) => {
    if (
      state.companyName !== '' &&
      state.companyName !== null &&
      state.companyName !== undefined
    ) {
      let address = state.companyName + ', ';
      if (
        state.address1 !== '' &&
        state.address1 !== null &&
        state.address1 !== undefined
      ) {
        address += state.address1 + ', ';
        if (
          state.address2 !== '' &&
          state.address2 !== null &&
          state.address2 !== undefined
        ) {
          address += state.address2 + ', ';
        }
        address += state.city + '\n';
        if (
          state.state.name !== '' &&
          state.state.name !== null &&
          state.state.name !== undefined
        ) {
          address += state.state.name + ', ';
        }
        address += state.countryCode + ', ';
        address += state.zipCode + '\n';
        return address;
      }
      return '';
    }
  },
  getCountryTaxDisplayType: (state) => {
    let countryCode = state.countryCode;
    let countryCodes = AccountConstants.COUNTRY_CODES;
    if (countryCodes.includes(countryCode)) {
      return {
        title: 'childAccount.addTaxId',
        label: 'childAccount.taxIdLabel',
        legalEstablishment: 'childAccount.legalEntityEstablishmentTax',
        legalEstablishmentNote: 'childAccount.legalEntityEstablishmentTaxNote',
        additionalTaxText: 'childAccount.additionalTaxId',
        additionalTaxBtn: 'childAccount.addTaxIdBtn',
        editTaxId: 'childAccount.editTaxId',
      };
    }
    return {
      title: 'childAccount.addVatId',
      label: 'childAccount.vatIdLabel',
      legalEstablishment: 'childAccount.legalEntityEstablishment',
      legalEstablishmentNote: 'childAccount.legalEntityEstablishmentNote',
      additionalTaxText: 'childAccount.additionalVatId',
      additionalTaxBtn: 'childAccount.addVatIdBtn',
      editTaxId: 'childAccount.editVatId',
    };
  },
  getBillingInfoLabelText: (state) => {
    let countryCode = state.countryCode;
    let nonEuropeanCountryCodes = AccountConstants.COUNTRY_CODES;
    if (nonEuropeanCountryCodes.includes(countryCode)) {
      return 'billing.companyInfo.taxId';
    } else {
      return 'billing.companyInfo.vatId';
    }
  },
  allBillingInfo: (state) => {
    return {
      companyName: state.companyName,
      taxId: state.taxId,
      address1: state.address1,
      address2: state.address2,
      city: state.city,
      state: state.state.state_code || state.state.name,
      zipCode: state.zipCode,
      country: state.countryCode,
      childAccounts: state.childAccounts,
    };
  },
  rawBillingInfo: (state) => {
    return {
      companyName: state.companyName,
      taxId: state.taxId,
      address1: state.address1,
      address2: state.address2,
      city: state.city,
      state: state.state.name,
      zipCode: state.zipCode,
      country: state.country,
    };
  },
  addressValidationObject: (state) => {
    return {
      postCode: state.zipCode,
      country: state.countryCode,
      addressLine1: state.address1,
      city: state.city,
      state: state.state.state_code || state.state.name,
    };
  },
  hasBillingInfo: (state) => {
    return (
      state.address1 !== '' &&
      state.address1 !== null &&
      state.address1 !== undefined
    );
  },
  hasChanged: (state) => {
    return state.hasChanged;
  },
};

export { getters };
