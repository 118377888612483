const actions = {
  setEquipmentFilter: ({ commit }, payload) => {
    commit('SET_EQUIPMENT_FILTER', payload);
  },
  setBulkEquipmentFilters: ({ commit }, payload) => {
    commit('SET_BULK_EQUIPMENT_FILTERS', payload);
  },
  setTotalEquipment: ({ commit }, payload) => {
    commit('SET_TOTAL_EQUIPMENT', payload);
  },
  setSearchQuery: ({ commit }, payload) => {
    commit('SET_SEARCH_QUERY', payload);
  },
  setPaginationQuery({ commit }, payload) {
    commit('SET_PAGINATION_QUERY', payload);
  },
  clearQueries({ commit }) {
    commit('SET_PAGINATION_QUERY', { data: {} });
  },
  refreshTable: ({ commit }) => {
    commit('REFRESH_TABLE');
  },
  refreshEquipment: ({ commit }) => {
    commit('REFRESH_EQUIPMENT');
  },
  equipmentRouteChanged: ({ commit }) => {
    commit('EQUIPMENT_ROUTE_CHANGED');
  },
  setTotalFilteredEquipment: ({ commit }, payload) => {
    commit('SET_TOTAL_FILTERED_EQUIPMENT', payload);
  },
  clearFilterAndSearch: ({ commit }) => {
    commit('CLEAR_FILTER_AND_SEARCH');
  },
  addSelected: ({ commit }, payload) => {
    let newSelected = {};

    payload.data.forEach((equipment) => {
      newSelected[equipment[payload.key]] = {
        id: equipment.id,
        name: equipment.name,
        type: equipment.type,
      };
    });

    commit('ADD_SELECTED', { data: newSelected });
  },
  removeSelected: ({ commit }, payload) => {
    commit('REMOVE_SELECTED', payload);
  },
  clearSelected: ({ commit }, payload) => {
    commit('CLEAR_SELECTED', payload);
  },
  closeLinkedAssetsDialog: ({ commit }, payload) => {
    commit('CLOSE_LINKED_ASSETS_DIALOG', payload);
  },
  resetState: ({ commit }) => {
    commit('RESET_STATE');
  },
  setEquipmentDetail: ({ commit }, payload) => {
    commit('SET_EQUIPMENT_DETAIL', payload);
  },
  resetEquipmentDetails: ({ commit }, payload) => {
    commit('RESET_EQUIPMENT_DETAILS', payload);
  },
  setEquipment: ({ commit }, payload) => {
    commit('SET_EQUIPMENT', payload);
  },
  setTypeSchemas: ({ commit }, payload) => {
    commit('SET_TYPE_SCHEMAS', payload);
  },
  removeEquipmentFilters: ({ commit }, payload) => {
    commit('REMOVE_EQUIPMENT_FILTERS', payload);
  },
};

export { actions };
