import { state } from '@/store/auth/state';
import { actions } from '@/store/auth/actions';
import { mutations } from '@/store/auth/mutations';
import { getters } from '@/store/auth/getters';

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export { state, getters, mutations, actions };
