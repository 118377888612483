import { render, staticRenderFns } from "./VehicleFilterTotal.vue?vue&type=template&id=d4c6ffbc&scoped=true&"
import script from "./VehicleFilterTotal.vue?vue&type=script&lang=js&"
export * from "./VehicleFilterTotal.vue?vue&type=script&lang=js&"
import style0 from "./VehicleFilterTotal.vue?vue&type=style&index=0&id=d4c6ffbc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4c6ffbc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFadeTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VFadeTransition})
