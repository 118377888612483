<template>
  <div>
    <ConfirmationDialog
      :acceptance-text="$t('welcomeDialog.getStartedGuideButtonText')"
      :enable-dialog="shouldShowWelcomeDialog"
      :message="$t('welcomeDialog.content')"
      :title="$t('welcomeDialog.title')"
      data-qa="welcome-dialog"
      :data-qa-key="'welcome-dialog'"
      display-confirm
      hide-content
      persistent
      @cancel="closeWelcomeDialogAndRefresh()"
      @setEnableDialogFalse="closeWelcomeDialogAndRefresh()"
      @keydown.esc="closeWelcomeDialogAndRefresh()"
    >
      <template v-slot:confirmation-section>
        <v-btn
          block
          flat
          large
          class="primary--text text-none button-text get-started-guide-button"
          data-qa="download-get-started-guide"
          @click="downloadGetStartedGuide"
        >
          {{ $t('welcomeDialog.getStartedGuide') }}
        </v-btn>
        <ProductUpgradeStepper
          data-qa="welcome-dialog-product-upgrade-stepper"
          dataQaKey="welcome-dialog"
          :is-upgrade="false"
          @close-dialog="closeWelcomeDialogAndRefresh()"
          @confirm-success="closeWelcomeDialogAndRefresh()"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              block
              large
              color="primary"
              class="text-none"
              data-qa="add-products-to-account"
              v-on="on"
            >
              {{ $t('welcomeDialog.addProductsNow') }}
            </v-btn>
          </template>
        </ProductUpgradeStepper>
      </template>
    </ConfirmationDialog>
    <ConfirmationDialog
      :acceptance-text="$t('ownershipConfirmation.confirm')"
      :cancellation-text="$t('ownershipConfirmation.cancel')"
      :confirm-disabled="!this.confirmedOwnership"
      :display-divider="false"
      :enable-dialog="shouldShowOwnershipConfirmationDialog"
      :message="$t('ownershipConfirmation.content')"
      :title="$t('ownershipConfirmation.title')"
      :data-qa-key="'ownership-confirmation'"
      data-qa="vehicle-ownership-confirmation-dialog"
      display-confirm
      height="100px"
      persistent
      @cancel="closeConfirmOwnershipDialog()"
      @confirm="confirmOwnership()"
    >
      <template v-slot:content>
        <v-checkbox
          v-model="confirmedOwnership"
          :label="$t('ownershipConfirmation.checkboxMessage')"
          color="primary"
          data-qa="ownership-confirmation-checkbox"
        ></v-checkbox>
      </template>
    </ConfirmationDialog>
    <ConfirmationDialog
      :acceptance-text="$t('oneTermsAndConditions.accept')"
      :display-divider="false"
      :enable-dialog="shouldShowOneTermsAndConditionsDialog"
      :title="$t('oneTermsAndConditions.title')"
      :data-qa-key="'one-terms-and-conditions-dialog'"
      :confirm-disabled="isConfirmDisabled"
      :show-cancel-button="false"
      data-qa="one-terms-and-conditions-dialog"
      @confirm="acceptTermsAndConditions"
      persistent
      max-width="800px"
      height="500px"
    >
      <template v-slot:content>
        <p
          class="subheading text--secondary mt-0"
          data-qa="last-updated"
          v-text="
            `${$t('oneTermsAndConditions.lastUpdated')} ${i18nMoment(
              latestTermsAndConditions.effectiveStartDate
            )}`
          "
        ></p>
        <v-expansion-panel>
          <v-expansion-panel-content
            v-for="(item, i) in legalLanguageDisplayText"
            :key="i"
          >
            <template v-slot:header>
              <p
                class="subheading primary--text mb-1"
                style="font-weight: 500"
                >{{ item.title }}</p
              >
            </template>
            <div style="padding-bottom: 20px">
              <div
                id="scroll-target"
                style="max-height: 300px; margin-left: 8px"
                class="scroll-y"
              >
                <v-card-text
                  class="body-1"
                  data-qa="latest-legal-language"
                  v-html="item.body"
                  v-scroll:#scroll-target="onScroll"
                >
                </v-card-text>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </ConfirmationDialog>
  </div>
</template>

<script>
import ConfirmationService from '@/services/ConfirmationService';
import RefreshTokenService from '@/services/RefreshTokenService';
import { mapActions, mapGetters } from 'vuex';
import ConfirmationDialog from '@/components/utility-components/dialogs/ConfirmationDialog';
import ProductUpgradeStepper from '@/components/product/ProductUpgradeStepper';
import onboardService from '../services/OnboardService';
import moment from 'moment/moment';
import { i18n } from '@/i18n';

export default {
  name: 'InitialSignOnDialogs',
  components: {
    ConfirmationDialog,
    ProductUpgradeStepper,
  },
  data() {
    return {
      showWelcomeDialog: true,
      showVehicleOwnershipConfirmationDialog: false,
      confirmedOwnership: false,
      showOneTermsAndConditionsDialog: false,
      disableConfirmButton: false,
      legalLanguageDisplayText: [],
      latestTermsAndConditions: {},
    };
  },
  computed: {
    ...mapGetters('auth', [
      'getGetStartedPdfUrl',
      'isFirstTimeLogin',
      'canReadLegalLanguage',
      'needsToSignTermsAndConditions',
      'canConfirmVehicleOwnership',
      'canViewProducts',
      'canReadAgreement',
      'canReadUserActionLog',
      'canSignTermsAndConditions',
    ]),
    ...mapGetters('termsAndConditions', ['hasSignedFFMTermsAndConditions']),
    shouldShowWelcomeDialog() {
      let hasFullyAgreedToLegalLanguage = !this.needsToSignTermsAndConditions;
      return (
        this.showWelcomeDialog &&
        (this.isFirstTimeLogin || this.hasSignedFFMTermsAndConditions) &&
        hasFullyAgreedToLegalLanguage &&
        this.canViewProducts()
      );
    },
    shouldShowOwnershipConfirmationDialog() {
      return (
        this.showVehicleOwnershipConfirmationDialog &&
        !this.shouldShowWelcomeDialog &&
        !this.shouldShowOneTermsAndConditionsDialog
      );
    },
    shouldShowOneTermsAndConditionsDialog() {
      return (
        this.showOneTermsAndConditionsDialog && !this.shouldShowWelcomeDialog
      );
    },
    isConfirmDisabled() {
      return this.disableConfirmButton;
    },
  },
  methods: {
    confirmOwnership() {
      this.closeConfirmOwnershipDialog();
      ConfirmationService.confirmOwnership().catch((err) => {
        console.log(err);
      });
    },
    closeConfirmOwnershipDialog() {
      this.showVehicleOwnershipConfirmationDialog = false;
      localStorage.setItem('dmShowVehicleOwnershipConfirmationDialog', 'false');
    },
    downloadGetStartedGuide() {
      window.open(this.getGetStartedPdfUrl, '_blank');
      this.closeWelcomeDialogAndRefresh();
    },
    closeWelcomeDialogAndRefresh() {
      this.showWelcomeDialog = false;
      RefreshTokenService.fetchNewToken().catch((err) => {
        console.log(err);
      });
      this.acknowledgeWelcomeDialog();
    },
    async acceptTermsAndConditions() {
      this.disableConfirmButton = true;
      onboardService
        .agreeTermsAndConditions({
          llid: this.latestTermsAndConditions.llid,
          legalLanguage: this.latestTermsAndConditions.legalLanguage,
          productFamilyId: null,
        })
        .then(() => {
          this.invalidate('termsAndConditions/setLatestTermsAndConditions');
        })
        .catch(() => {
          this.addErrorNotification(
            this.$t('policy.termsAndConditions.agreementError')
          );
        })
        .finally(() => {
          this.showOneTermsAndConditionsDialog = false;
        });
    },
    useMicroservicesForTermsAndConditions() {
      return (
        this.canReadLegalLanguage() &&
        this.canReadAgreement('FFM Terms and Conditions') &&
        this.canReadUserActionLog()
      );
    },
    hasPermissionsToSignTermsAndConditions() {
      let useAccountForTermsAndConditions = this.canSignTermsAndConditions;
      return (
        this.useMicroservicesForTermsAndConditions() ||
        useAccountForTermsAndConditions
      );
    },
    i18nMoment(date) {
      moment.locale(i18n.locale);
      return moment(date).format('L');
    },
    async renderSignTermsAndConditions() {
      if (this.hasPermissionsToSignTermsAndConditions()) {
        this.latestTermsAndConditions =
          await this.fetchLatestTermsAndConditions();
        if (this.latestTermsAndConditions.hasSigned === null) {
          this.showOneTermsAndConditionsDialog = false;
        } else {
          this.showOneTermsAndConditionsDialog =
            !this.latestTermsAndConditions.hasSigned &&
            !this.latestTermsAndConditions.impersonate;
        }
        this.legalLanguageDisplayText = [
          {
            title: this.$t('oneTermsAndConditions.terms.title'),
            body: this.latestTermsAndConditions.legalLanguage,
          },
          {
            title: this.$t('oneTermsAndConditions.whatsNew.title'),
            body: this.$t('oneTermsAndConditions.whatsNew.body'),
          },
        ];
        this.legalLanguageDisplayText = this.legalLanguageDisplayText.filter(
          (item) =>
            item.title !== this.$t('oneTermsAndConditions.whatsNew.title')
        );
      } else {
        this.showOneTermsAndConditionsDialog = false;
      }
    },
    ...mapActions('auth', ['acknowledgeWelcomeDialog']),
    ...mapActions('cache', ['invalidate']),
    ...mapActions('notification', ['addErrorNotification']),
    ...mapActions('termsAndConditions', ['fetchLatestTermsAndConditions']),
  },
  async mounted() {
    await this.renderSignTermsAndConditions();
  },
  watch: {
    async canSignTermsAndConditions() {
      await this.renderSignTermsAndConditions();
    },
    async canReadLegalLanguage() {
      await this.renderSignTermsAndConditions();
    },
    async canReadAgreement() {
      await this.renderSignTermsAndConditions();
    },
    async canReadUserActionLog() {
      await this.renderSignTermsAndConditions();
    },
    canConfirmVehicleOwnership() {
      if (
        this.canConfirmVehicleOwnership &&
        localStorage.getItem('dmShowVehicleOwnershipConfirmationDialog') ===
          'true'
      ) {
        ConfirmationService.getOwnershipConfirmation()
          .then((res) => {
            this.showVehicleOwnershipConfirmationDialog = !res.data;
            if (res.data) {
              localStorage.setItem(
                'dmShowVehicleOwnershipConfirmationDialog',
                'false'
              );
            }
          })
          .catch((err) => {
            console.log(err);
            localStorage.setItem(
              'dmShowVehicleOwnershipConfirmationDialog',
              'false'
            );
          });
      }
    },
  },
};
</script>

<style lang="scss">
.get-started-guide-button {
  background-color: #fff !important; /* Blame Vue for making us do this */
  color: var(--v-primary-base);
  border: 1px solid var(--v-primary-base);
}
</style>
