const getters = {
  query: (state) => {
    return { ...state.paginationQuery };
  },
  dateToViewInvoice: (state) => {
    return state.dateToViewInvoice;
  },
  statementNumber: (state) => {
    return state.statementNumber;
  },
  paymentClientSecret: (state) => {
    return state.paymentClientSecret;
  },
  paymentClient: (state) => {
    return state.paymentClient;
  },
  isPaymentElementLoaded: (state) => {
    return state.paymentElementLoaded;
  },
  paymentElement: (state) => {
    return state.paymentElement;
  },
  paymentEnabledCurrencies: (state) => {
    return state.paymentEnabledCurrencies;
  },
  doExistInPaymentEnabledCurrencyList: (state) => (selectedCurrency) => {
    return state.paymentEnabledCurrencies.includes(selectedCurrency);
  },
};

export { getters };
