<template>
  <v-dialog
    v-model="showDialog"
    data-qa="edit-child-account-dialog"
    max-width="600px"
    persistent
  >
    <template v-slot:activator="slotData">
      <slot v-bind="slotData" name="activator" />
    </template>
    <div>
      <v-card class="pa-3">
        <v-card-title v-if="isAdd" data-qa="add-country-tax-display-title">
          <p>{{ $t(getCountryTaxDisplayType.title) }}</p>
        </v-card-title>
        <v-card-title v-else style="font-size: 34px">
          {{
            $t(getCountryTaxDisplayType.editTaxId, {
              country: $t('countryName.' + newChildAccount.countryCode),
            })
          }}
        </v-card-title>
        <v-form
          ref="editChildAccountForm"
          v-model="formValid"
          data-qa="edit-child-account-form"
          v-on:submit.prevent="updateChanges"
        >
          <v-container class="pb-0 pt-0">
            <v-flex class="form-field" xs12>
              <v-text-field
                v-model="newChildAccount.taxId"
                :rules="nullRuleCheck"
                required
                :label="$t(getCountryTaxDisplayType.label)"
                data-qa="vat-id-edit"
                outline
                data-qm-encrypt="true"
              ></v-text-field>
            </v-flex>
            <v-flex class="form-field" xs12>
              <v-autocomplete
                v-if="isAdd"
                v-model="selectedChildAccount"
                :label="$t('childAccount.countryLabel')"
                :items="countryOptions"
                data-qa="child-account-selector"
                v-on:change="setFromChildAccount"
                item-text="countryName"
                outline
                return-object
                data-qm-encrypt="true"
              >
                <template v-slot:item="data">
                  <span
                    :data-qa="'child-account-selector-' + data.item.index"
                    >{{ $t('countryName.' + data.item.isoCountryCode) }}</span
                  >
                </template>
              </v-autocomplete>
              <v-text-field
                v-else
                :label="$t('childAccount.countryLabel')"
                :value="$t('countryName.' + newChildAccount.countryCode)"
                data-qa="vat-id-isoCountryCode"
                disabled
                outline
                data-qm-encrypt="true"
              ></v-text-field>
            </v-flex>
          </v-container>
          <v-card-actions>
            <v-btn
              block
              data-qa="edit-child-account-cancel-button"
              flat
              large
              class="primary--text text-none button-text"
              :disabled="isVerifying"
              @click="closeDialog"
            >
              {{ $t('childAccount.cancelButton') }}
            </v-btn>
            <v-btn
              block
              large
              data-qa="edit-child-account-update-button"
              color="primary"
              class="text-none button-text"
              :disabled="isSubmitDisabled"
              type="submit"
            >
              {{
                isAdd
                  ? $t('childAccount.addButton')
                  : $t('childAccount.updateChildButton')
              }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep, isEqual } from 'lodash';
import csc from 'countries-states-cities';
import InvoiceService from '@/services/InvoiceService';

export default {
  name: 'EditChildAccountDialog',
  mounted() {
    this.init();
  },
  data() {
    return {
      isVerifying: false,
      selectedChildAccount: {},
      disableAddress: true,
      showDialog: false,
      useStateSelection: false,
      selectedState: {},
      countries: [],
      states: [],
      isMounted: false,
      isDisabled: false,
      formValid: true,
      newChildAccount: {},
      initialChildAccount: {},
      nullRuleCheck: [
        (v) => !!v || this.$t('billing.companyInfo.rules.required'),
      ],
    };
  },
  computed: {
    countryOptions: function () {
      return this.hiddenChildAccounts.map((childAccount) => {
        childAccount.countryName = this.$t(
          'countryName.' + childAccount.isoCountryCode
        );
        return childAccount;
      });
    },
    isModified: function () {
      return !isEqual(this.newChildAccount, this.initialChildAccount);
    },
    isSubmitDisabled: function () {
      return this.isVerifying || !this.formValid || !this.isModified;
    },
    ...mapGetters('companyBillingInfo', [
      'hiddenChildAccounts',
      'getCountryTaxDisplayType',
    ]),
  },
  methods: {
    async updateChanges() {
      const isValid = await this.validateAddress();
      if (isValid) {
        this.updateChildAccount(this.newChildAccount);
        this.closeDialog();
      }
    },
    async validateAddress() {
      if (this.newChildAccount.physicalPresenceIndicator === 'true') {
        this.isVerifying = true;
        let addressToValidate = {
          postCode: this.newChildAccount.zipCode,
          country: this.newChildAccount.countryCode,
          addressLine1: this.newChildAccount.address1,
          city: this.newChildAccount.city,
          state: this.newChildAccount.state,
        };
        try {
          const result = await InvoiceService.validateBillingAddress(
            addressToValidate
          );
          this.isVerifying = false;
          if (result.data) {
            return result.data;
          }
        } catch (error) {
          this.isVerifying = false;
        }
        this.addErrorNotification(
          this.$t(
            'productUpgradeStepper.snackBarMessage.addressValidationFailure'
          )
        );
        return false;
      } else {
        return true;
      }
    },
    init() {
      this.newChildAccount = {
        physicalPresenceIndicator: '',
        address1: '',
        address2: '',
        city: '',
        countryCode: '',
        state: '',
        zipCode: '',
        taxId: '',
        childIndex: '',
      };
      this.initialChildAccount = {
        physicalPresenceIndicator: '',
        address1: '',
        address2: '',
        city: '',
        countryCode: '',
        state: '',
        zipCode: '',
        taxId: '',
        childIndex: '',
      };
      this.disableAddress = true;
      this.selectedChildAccount = {};
      this.$refs.editChildAccountForm.resetValidation();
      if (this.childAccount) {
        this.setFromChildAccount(this.childAccount);
      }
    },
    isPopulated() {
      if (this.newChildAccount.physicalPresenceIndicator === true) {
        return (
          this.newChildAccount.address1 !== '' &&
          this.newChildAccount.address2 !== '' &&
          this.newChildAccount.city !== '' &&
          this.newChildAccount.state !== '' &&
          this.newChildAccount.taxId !== '' &&
          this.newChildAccount.zipCode !== ''
        );
      }
      return false;
    },
    setFromChildAccount(childAccount) {
      this.countries = csc
        .getAllCountries()
        .find((country) => country.iso3 === childAccount.isoCountryCode);
      this.populateChildAccount(childAccount);
      this.determineStateOptions();
      this.disableAddress = false;
    },
    populateChildAccount(childAccount) {
      if (childAccount.physicalPresenceIndicator === 'true') {
        this.newChildAccount.address1 = childAccount.billToContact.address1;
        this.newChildAccount.address2 = childAccount.billToContact.address2;
        this.newChildAccount.state = childAccount.billToContact.state;
        this.newChildAccount.city = childAccount.billToContact.city;
        this.newChildAccount.zipCode = childAccount.billToContact.zipCode;
      } else {
        this.newChildAccount.address1 = '';
        this.newChildAccount.address2 = '';
        this.newChildAccount.state = '';
        this.newChildAccount.city = '';
        this.newChildAccount.zipCode = '';
      }
      this.newChildAccount.physicalPresenceIndicator =
        childAccount.physicalPresenceIndicator;
      let userEnteredTaxId = this.newChildAccount.taxId;
      this.newChildAccount.taxId = childAccount.customerTaxId;
      this.newChildAccount.childIndex = childAccount.index;
      this.newChildAccount.countryCode = childAccount.isoCountryCode;
      this.populateInitialChildAccount();
      if (this.isAdd) {
        this.newChildAccount.taxId = userEnteredTaxId;
      }
    },
    populateInitialChildAccount() {
      this.initialChildAccount = cloneDeep(this.newChildAccount);
    },
    determineStateOptions() {
      const country = this.countries;
      if (!country || country.region === 'Europe') {
        this.useStateSelection = false;
        return;
      }
      this.useStateSelection = true;
      this.states = csc.getStatesOfCountry(country.id);
    },
    resetChildAccountData() {
      this.newChildAccount.address1 = '';
      this.newChildAccount.address2 = '';
      this.newChildAccount.state = '';
      this.newChildAccount.city = '';
      this.newChildAccount.zipCode = '';
    },
    closeDialog() {
      this.init();
      this.showDialog = false;
    },
    ...mapActions('companyBillingInfo', ['updateChildAccount']),
    ...mapActions('notification', ['addErrorNotification']),
  },
  props: {
    childAccount: Object,
    isAdd: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
