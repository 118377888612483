import { isEqual } from 'lodash';

const getters = {
  userFilter: (state) => {
    return state.userFilter;
  },
  query: (state) => {
    return {
      ...state.paginationQuery,
      query: state.searchQuery,
      filter: JSON.stringify(state.userFilter),
    };
  },
  selected: (state) => {
    return state.selected;
  },
  isSelected: (state) => (id) => {
    return state.selected[id] !== undefined;
  },
  selectedLength: (state) => {
    return Object.keys(state.selected).length;
  },
  isSelectedModified: (state) => {
    return !isEqual(
      Object.keys(state.selected).sort(),
      Object.keys(state.initialSelected).sort()
    );
  },
  areFiltersOrSearchApplied: (state) => {
    return state.searchQuery !== '' && state.searchQuery !== null;
  },
  paginationQuery: (state) => {
    return state.paginationQuery;
  },
  userGridColumns: (state) => {
    return state.userGridColumns;
  },
  hasColumn: (state) => (column) => {
    return state.userGridColumns.map((v) => v.code).includes(column);
  },
  totalUsers: (state) => {
    return state.totalUsers;
  },
  isShowReimbursableAddressDialog: (state) => {
    return state.showReimbursableAddressDialog;
  },
  isShowAddressVerificationDialog: (state) => {
    return state.showAddressVerificationDialog;
  },
  reimbursableAddress: (state) => {
    return state.reimbursableAddress;
  },
  viewAddress: (state) => {
    return state.viewAddress;
  },
  getNotificationPreferences: (state) => {
    return state.notificationPreferences;
  },
  isShowChargingStationDialog: (state) => {
    return state.showChargingStationDialog;
  },
  chargeStationErrors: (state) => {
    return state.chargeStationErrors;
  },
  chargeStationRule: (state) => {
    return state.chargeStationRule;
  },
};

export { getters };
