import { make } from 'vuex-pathify';
import { state as termsAndConditions } from './state';
const mutations = {
  ...make.mutations(termsAndConditions),

  HAS_SIGNED_FFM_TERMS_AND_CONDITIONS: (state, payload) => {
    state.hasSignedFFMTermsAndConditions = payload;
  },
  HAS_SIGNED_PRODUCT_TERMS_AND_CONDITIONS: (state, payload) => {
    state.hasSignedProductTermsAndConditions = payload;
  },
  HAS_FETCHED_TERMS_AND_CONDITIONS: (state, payload) => {
    state.hasFetchedTermsAndConditions = payload;
  },
  CAN_VIEW_ONBOARD_PAGE: (state, payload) => {
    state.canViewOnboardPage = payload;
  },
  CHECKED_AGREE_TERMS_AND_CONDITIONS: (state, payload) => {
    state.checkedAgreeTermsAndConditions = payload;
  },
  SET_PRODUCT_TERMS_AND_CONDITIONS_MAP: (state, payload) => {
    state.productTermsAndConditionsMap = payload;
  },
  SET_LATEST_TERMS_AND_CONDITIONS: (state, payload) => {
    state.latestTermsAndConditions = payload;
  },
};

export { mutations };
