import AccountApiProxyService from '@/services/AccountApiProxyService';
import { AccountConstants } from '@/constants';

export default {
  getCompanyInformation(companyId) {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH + '/company/' + companyId
    );
  },
  getCompanyProducts(productLevel, allProductLevels) {
    if (productLevel) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          `/company/products?productLevel=${productLevel}&filter-hidden-products=true`
      );
    } else if (allProductLevels) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/company/products?allProductLevels=true&filter-hidden-products=true'
      );
    } else {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/company/products?filter-hidden-products=true'
      );
    }
  },
  getProductLevels() {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH + '/company/products/levels'
    );
  },
  getMarketableProductFamilies(language) {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH +
        `/company/product-families/marketable?language=${language}`
    );
  },
  getProductReplacementRules(replacedProductFamilyId) {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH +
        `/company/product/replacement-rules?replaced-product-family-id=${replacedProductFamilyId}`
    );
  },
  addCompanyProduct(companyId, body) {
    return AccountApiProxyService.post(
      AccountConstants.ACCOUNT_API_PATH + '/company/' + companyId + '/product',
      body
    );
  },
  getCompanyComplimentaryProducts() {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH + '/company/product/complementary'
    );
  },
  getCompanyLeads() {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH + '/company/leads'
    );
  },
  getCompanyProductSkus() {
    return AccountApiProxyService.get(
      '/pro/dm-company-api/v1/company/product-skus'
    );
  },
};
