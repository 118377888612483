<template>
  <ConfirmationDialog
    :title="$t('termsAndConditions.fordPro.title')"
    :message="latestTermsAndConditions.legalLanguage"
    :cancellation-text="$t('termsAndConditions.download')"
    :enable-dialog="dialog"
    data-qa-key="download-terms-and-conditions"
    :display-confirm="false"
    :display-divider="false"
    @cancel="handleDownload"
    max-width="800px"
    height="500px"
  >
    <template v-slot:activator="{ on }">
      <a class="hyperlink" data-qa="ford-pro-t-and-c" v-on="on">{{
        hyperlinkText
      }}</a>
    </template>
  </ConfirmationDialog>
</template>

<script>
import ConfirmationDialog from '@/components/utility-components/dialogs/ConfirmationDialog.vue';
import { mapActions } from 'vuex';
import { handleTermsAndConditionsDownload } from '@/services/Downloads';

export default {
  name: 'LatestTermsAndConditionsDownloadDialog',
  components: {
    ConfirmationDialog,
  },
  props: {
    hyperlinkText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      latestTermsAndConditions: {},
    };
  },
  async mounted() {
    await this.fetchLatestTermsAndConditions().then((res) => {
      this.latestTermsAndConditions = res;
    });
  },
  methods: {
    ...mapActions('termsAndConditions', ['fetchLatestTermsAndConditions']),
    handleDownload() {
      handleTermsAndConditionsDownload(
        this.latestTermsAndConditions.legalLanguageByLlidId
      );
    },
  },
};
</script>

<style scoped lang="scss">
.hyperlink {
  color: var(--v-secondary-base);
  text-decoration: underline;
  text-align: center;
  font-size: 16px;
  padding-right: 5px;
  margin-bottom: 0;
}
</style>
