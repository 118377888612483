const actions = {
  addSuccessNotification({ commit }, payload) {
    commit(
      'ADD_NOTIFICATION',
      typeof payload === 'string'
        ? { text: payload, type: 'success' }
        : { ...payload, type: 'success' }
    );
  },
  addInformationNotification({ commit }, payload) {
    commit(
      'ADD_NOTIFICATION',
      typeof payload === 'string'
        ? { text: payload, type: 'information' }
        : { ...payload, type: 'information' }
    );
  },
  addErrorNotification({ commit }, payload) {
    commit(
      'ADD_NOTIFICATION',
      typeof payload === 'string'
        ? { text: payload, type: 'error' }
        : { ...payload, type: 'error' }
    );
  },
  removeNotification({ commit }, payload) {
    commit('REMOVE_NOTIFICATION', payload);
  },
};

export { actions };
