import { state } from '@/store/linkAssetVehicle/state';
import { getters } from '@/store/linkAssetVehicle/getters';
import { actions } from '@/store/linkAssetVehicle/actions';
import { mutations } from '@/store/linkAssetVehicle/mutations';

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export { state, getters, mutations, actions };
