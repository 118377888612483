<template>
  <v-progress-circular
    indeterminate
    :color="color"
    :size="size"
    :width="width"
    class="my-4"
    :aria-label="$t('common.loading')"
    role="status"
    data-qa="loading-spinner"
  />
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    size: { type: Number, default: 50 },
    color: { type: String, default: 'primary' },
    width: { type: Number, required: false },
  },
};
</script>
