const actions = {
  addSelected: ({ commit }, payload) => {
    commit('ADD_SELECTED', { data: payload });
    commit('SELECTED_CHANGED');
  },
  removeSelected: ({ commit }, payload) => {
    commit('REMOVE_SELECTED', payload);
    commit('SELECTED_CHANGED');
  },
  clearSelected: ({ commit }, payload) => {
    commit('CLEAR_SELECTED', payload);
    commit('SELECTED_CHANGED');
  },
  setProductFamilies: ({ commit }, payload) => {
    commit('SET_PRODUCT_FAMILIES', payload);
  },
};

export { actions };
