<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-btn
    :flat="flat"
    :color="color"
    @click="action"
    :data-qa="`${dataQaPrefix}${dashify(this.item[this.rowKey])}`"
  >
    {{ buttonText }}
  </v-btn>
</template>

<script>
import dashify from 'dashify';

export default {
  name: 'DataGridActionButton',
  props: {
    flat: Boolean,
    color: String,
    buttonText: String,
    dataQaPrefix: String,
    storeModule: { type: String, default: null },
    storeAction: { type: String, default: null },
    item: Object,
    rowKey: String,
  },
  methods: {
    dashify,
    action() {
      if (this.storeModule !== null && this.storeAction !== null) {
        this.$store.dispatch(
          `${this.storeModule}/${this.storeAction}`,
          this.item[this.rowKey]
        );
      }
    },
  },
};
</script>

<style scoped></style>
