export default {
  name: 'ResolveVehicleName',
  methods: {
    hasSufficientInfo(property) {
      return property && property !== '';
    },
    getVehicleName(name, make, model) {
      if (this.hasSufficientInfo(name)) {
        return name;
      } else if (
        !this.hasSufficientInfo(name) &&
        this.hasSufficientInfo(make) &&
        this.hasSufficientInfo(model)
      ) {
        return `(${this.$tf(
          'generic.untitled',
          '(Untitled)'
        )} ${make} ${model})`;
      } else if (
        !this.hasSufficientInfo(name) &&
        !this.hasSufficientInfo(make) &&
        this.hasSufficientInfo(model)
      ) {
        return `(${this.$tf('generic.untitled', '(Untitled)')} ${model})`;
      } else if (
        !this.hasSufficientInfo(name) &&
        this.hasSufficientInfo(make) &&
        !this.hasSufficientInfo(model)
      ) {
        return `(${this.$tf('generic.untitled', '(Untitled)')} ${make})`;
      } else {
        return `(${this.$t('equipment.associationPopup.untitledVehicle')})`;
      }
    },
  },
};
