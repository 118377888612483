import Vue from 'vue';

export default (headerText, headerKey, options) => {
  if (options === undefined || options === null) {
    options = {};
  }
  return {
    headerText: headerText,
    headerKey: headerKey,
    sortable: options.sortable || false,
    hideItem: options.hideItem || false,
    displayField:
      options.displayField !== undefined ? options.displayField : true,
    hasToolTip: options.hasToolTip || false,
    isDateField: options.isDateField || false,
    isBillingDateField: options.isBillingDateField || false,
    internationalize: options.internationalize || false,
    internationalizeRoot: options.internationalizeRoot || '',
    isModifiedDateField: options.isModifiedDateField || false,
    isStatusLabel: options.isStatusLabel || false,
    isApplicationList: options.isApplicationList || false,
    pageSort: options.pageSort || headerKey,
    headerClasses: options.headerClasses || undefined,
    itemClasses: options.itemClasses || undefined,
    itemsExtractor: options.itemsExtractor || undefined,
    itemActionRender:
      options.itemActionRender !== undefined
        ? Vue.component('grid-item-action', {
            render: options.itemActionRender,
          })
        : undefined,
    headerFilterRender:
      options.headerFilterRender !== undefined
        ? Vue.component('grid-header-filter', {
            render: options.headerFilterRender,
          })
        : undefined,
    currency: options.currency || '',
  };
};
