import Vue from 'vue';

const mutations = {
  SET_FILTER: (state, payload) => {
    if (payload.selected.length === 0) {
      Vue.delete(state.otaFilter, payload.name);
    } else {
      Vue.set(state.otaFilter, payload.name, payload.selected);
    }
  },
  SET_SEARCH_QUERY: (state, payload) => {
    Vue.set(state, 'searchQuery', payload.data);
  },
  CLEAR_FILTER_AND_SEARCH: (state) => {
    Vue.set(state, 'otaFilter', {});
    Vue.set(state, 'searchQuery', '');
  },
  SET_TOTAL_OTAS: (state, payload) => {
    Vue.set(state, 'totalOTAs', payload);
  },
  SET_TOTAL_FILTERED_OTAS: (state, payload) => {
    Vue.set(state, 'totalFilteredOTAs', payload);
  },
  SET_PAGINATION_QUERY: (state, payload) => {
    Vue.set(state, 'paginationQuery', payload.data);
  },
  REFRESH_TABLE: (state) => {
    return state;
  },
  ADD_SELECTED: (state, payload) => {
    for (let userId of Object.keys(payload.data)) {
      Vue.set(state.selected, userId, payload.data[userId]);
    }
  },
  REMOVE_SELECTED: (state, payload) => {
    for (let userId of Object.keys(payload.data)) {
      Vue.delete(state.selected, payload.data[userId]);
    }
  },
  CLEAR_SELECTED: (state) => {
    state.selected = {};
  },
  SET_VEHICLES: (state, payload) => {
    Vue.set(state, 'vehicles', payload);
  },
  SET_DISPLAY_NAME: (state, payload) => {
    Vue.set(state, 'displayName', payload);
  },
  PERFORM_BLOCK_OTA: () => {},
  PERFORM_UNBLOCK_OTA: () => {},
};

export { mutations };
