import { AccountConstants } from '@/constants';
import CookieUtil from '@/services/CookieUtil';

const oneTrustService = {
  generateOneTrustScript() {
    const dataDomainScript = AccountConstants.ONE_TRUST.DATA_DOMAIN_SCRIPT;

    const script = document.createElement('script');
    script.id = AccountConstants.ONE_TRUST.SCRIPT_ATTR.MAIN_ID;
    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script.type = 'text/javascript';
    script.setAttribute('data-domain-script', dataDomainScript);
    script.setAttribute('data-document-language', 'true');
    document.head.appendChild(script);

    script.onerror = function () {
      const fcsClientLoadedEvent = document.createEvent('Event');
      fcsClientLoadedEvent.initEvent('FCSClientLoaded', true, true);
      window.document.dispatchEvent(fcsClientLoadedEvent);
    };

    const optanonWrapperScript = document.createElement('script');
    optanonWrapperScript.id = AccountConstants.ONE_TRUST.SCRIPT_ATTR.OPTANON_ID;
    optanonWrapperScript.innerText =
      'function OptanonWrapper() {' +
      '  const fcsClientLoadedEvent = document.createEvent("Event");' +
      '  fcsClientLoadedEvent.initEvent("FCSClientLoaded", true, true);' +
      '  window.document.dispatchEvent(fcsClientLoadedEvent);' +
      '}';
    document.head.appendChild(optanonWrapperScript);
  },

  isLoaded() {
    return !!window.OneTrust;
  },

  isPerformanceCookiesAllowed() {
    const cookiePreferences =
      CookieUtil.getCookie(
        AccountConstants.ONE_TRUST.COOKIE_KEYS.COOKIE_PREFERENCES
      ) || {};
    return cookiePreferences[
      AccountConstants.ONE_TRUST.COOKIE_GROUPS.PERFORMANCE
    ];
  },

  doGenerateOneTrust() {
    if (AccountConstants.ONE_TRUST.ENABLE) {
      this.generateOneTrustScript();
    }
  },
};

export default oneTrustService;
