import {
  checkAuthorityForPath,
  redirectIfCheckAuthorityForPathUnauthorized,
} from './routeUtils';

export function getProductsPageRoutes(
  store,
  getRedirectRoute,
  isTileMode = false
) {
  return {
    path: '/',
    name: 'products',
    component: () =>
      isTileMode
        ? import('@/views/MarketplaceView.vue')
        : import('@/views/ParentProductsView.vue'),
    children: [
      {
        path: '',
        name: 'myProducts',
        component: () => import('@/views/ProductsView.vue'),
        meta: { authorityPath: 'auth/canViewProducts' },
        beforeEnter(to, _from, next) {
          checkAuthorityForPath(to.meta.authorityPath, next, store);
        },
      },
      {
        path: 'explore-more',
        name: 'exploreMoreProducts',
        component: () => import('@/views/ProductExploreMore.vue'),
        meta: { authorityPath: 'auth/canViewProducts' },
        beforeEnter(to, from, next) {
          checkAuthorityForPath(to.meta.authorityPath, next, store);
        },
      },
      {
        path: 'products/explore-more',
        name: 'oldExploreMoreProducts',
        redirect: 'explore-more',
      },
    ],
    meta: { authorityPath: 'auth/canViewProducts' },
    beforeEnter(to, from, next) {
      redirectIfCheckAuthorityForPathUnauthorized(
        to,
        next,
        store,
        getRedirectRoute,
        true
      );
    },
  };
}
