import Vue from 'vue';
import AccountApiProxyService from '@/services/AccountApiProxyService';
import { AccountConstants } from '@/constants';

export default new Vue({
  methods: {
    createGroup(groupDetails) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/segment',
        groupDetails
      );
    },
    getGroups(queryString) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/segment/stats?' + queryString,
        {
          headers: {
            'x-token-force-refresh': 'true',
          },
        }
      );
    },
    getUnassigned() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/segment/stats/unassigned'
      );
    },
    getGroupDetails(segmentId) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/segment/' + segmentId + '/info'
      );
    },
    updateGroup(updateRequest) {
      return AccountApiProxyService.patch(
        AccountConstants.ACCOUNT_API_PATH + '/segment',
        updateRequest
      );
    },
    doesGroupExist(segmentName) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/segment/exists?name=' +
          segmentName
      );
    },
    deleteGroup(segmentIds) {
      return AccountApiProxyService.delete(
        AccountConstants.ACCOUNT_API_PATH + '/segment',
        { data: segmentIds }
      );
    },
    getBasicGroups() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/segment/basic'
      );
    },
    getVehicleBasicGroups(vehicleIds) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/segment/vehicles/basic',
        vehicleIds
      );
    },
    getSegmentInfo(segmentIds) {
      return AccountApiProxyService.post(
        '/pro/dm-segment-api/v1/info/segments',
        segmentIds
      );
    },
    bulkAddVehiclesToGroups(groupIds, vehicleIds) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + `/segment/vehicles/bulk/add`,
        {
          segmentIds: groupIds,
          vehicleIds: vehicleIds,
        }
      );
    },
    getUserBasicGroups(userIds) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + '/segment/users/basic',
        userIds
      );
    },
    addUsersToGroup(addRequest) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + `/segment/users/bulk-add`,
        addRequest
      );
    },
    removeUsersFromGroup(removeRequest) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + `/segment/users/bulk-remove`,
        removeRequest
      );
    },
    addVehiclesToGroup(groupId, vehicleIds) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH +
          `/segment/` +
          groupId +
          `/vehicles/add`,
        vehicleIds
      );
    },
    bulkRemoveVehiclesFromGroups(groupIds, vehicleIds) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + `/segment/vehicles/bulk/remove`,
        {
          segmentIds: groupIds,
          vehicleIds: vehicleIds,
        }
      );
    },
  },
});
