import Vue from 'vue';
import AccountApiProxyService from '@/services/AccountApiProxyService';
import axios from 'axios';
import { AccountConstants } from '@/constants';

export default new Vue({
  methods: {
    getAccountLimitsConfig() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/config/limits'
      );
    },
    getConfiguration(configName) {
      return axios.get(
        process.env.VUE_APP_CONFIG_URL + configName + `/${configName}.json`,
        {
          headers: {
            'Cache-Control': 'no-store, max-age=0',
          },
        }
      );
    },
    getConfigDetails(featureName) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + `/config/values/${featureName}`
      );
    },
  },
});
