import Vue from 'vue';
import { cloneDeep, isEqual } from 'lodash';
import { make } from 'vuex-pathify';
import { state as user } from '@/store/user';

const mutations = {
  ...make.mutations(user),
  SET_USER_FILTER: (state, payload) => {
    if (payload.selected.length === 0) {
      Vue.delete(state.userFilter, payload.name);
    } else {
      Vue.set(state.userFilter, payload.name, payload.selected);
    }
  },
  SET_SEARCH_QUERY: (state, payload) => {
    Vue.set(state, 'searchQuery', payload.data);
  },
  SET_PAGINATION_QUERY: (state, payload) => {
    Vue.set(state, 'paginationQuery', payload.data);
  },
  ADD_SELECTED: (state, payload) => {
    for (let userId of Object.keys(payload.data)) {
      Vue.set(state.selected, userId, payload.data[userId]);
    }
  },
  REMOVE_SELECTED: (state, payload) => {
    for (let userId of Object.keys(payload.data)) {
      Vue.delete(state.selected, payload.data[userId]);
    }
  },
  CLEAR_SELECTED: (state) => {
    state.selected = {};
  },
  SET_INITIAL_SELECTED: (state) => {
    state.initialSelected = cloneDeep(state.selected);
  },
  CLEAR_INITIAL_SELECTED: (state) => {
    state.initialSelected = {};
  },
  RESET_SELECTED: (state) => {
    state.selected = cloneDeep(state.initialSelected);
  },
  CLEAR_FILTER_AND_SEARCH: (state) => {
    Vue.set(state, 'searchQuery', '');
  },
  SET_USER_GRID_COLUMNS: (state, payload) => {
    state.userGridColumns = payload;
  },
  SET_TOTAL_USERS: (state, payload) => {
    state.totalUsers = payload;
  },
  SET_SHOW_REIMBURSABLE_ADDRESS_DIALOG: (state, payload) => {
    state.showReimbursableAddressDialog = payload;
  },
  SET_SHOW_ADDRESS_VERIFICATION_DIALOG: (state, payload) => {
    state.showAddressVerificationDialog = payload;
  },
  SET_REIMBURSABLE_ADDRESS: (state, payload) => {
    if (payload && !isEqual(payload, {})) {
      let tempAddress = {
        name: payload.placeName || payload.name,
        latitude: payload.latitude,
        longitude: payload.longitude,
        chargeStationId: payload.chargeStationId
          ? payload.chargeStationId
          : null,
      };
      if (payload.id) {
        tempAddress.id = payload.id;
      }
      Vue.set(state, 'reimbursableAddress', tempAddress);
    } else {
      Vue.set(state, 'reimbursableAddress', payload);
    }
  },
  CLEAR_REIMBURSABLE_ADDRESS: (state) => {
    state.reimbursableAddress = {};
    state.chargeStationErrors = [];
  },
  SET_VIEW_ADDRESS: (state, payload) => {
    state.viewAddress = payload;
  },
  SET_NOTIFICATION_PREFERENCE: (state, payload) => {
    let tempNotificationPreferences = cloneDeep(state.notificationPreferences);
    if (state.notificationPreferences.hasOwnProperty(payload.key)) {
      delete tempNotificationPreferences[payload.key];
    } else {
      tempNotificationPreferences[payload.key] = payload.value;
    }
    Vue.set(state, 'notificationPreferences', tempNotificationPreferences);
  },
  CLEAR_NOTIFICATION_PREFERENCES: (state) => {
    Vue.set(state, 'notificationPreferences', {});
  },
  SET_SHOW_CHARGING_STATION_DIALOG: (state, payload) => {
    state.showChargingStationDialog = payload;
  },
  SET_CHARGE_STATION_ERRORS: (state, payload) => {
    state.chargeStationErrors = payload;
  },
  VALIDATE_CHARGE_STATION_ID: (state, payload) => {
    if (payload.value.trim().length === 0) {
      Vue.set(state, 'chargeStationErrors', []);
      Vue.set(state, 'chargeStationRule', [false]);
    } else if (payload.value.length > 25) {
      Vue.set(state, 'chargeStationErrors', [payload.maxCharacters]);
      Vue.set(state, 'chargeStationRule', [true]);
    } else {
      Vue.set(state, 'chargeStationErrors', []);
      Vue.set(state, 'chargeStationRule', [false]);
    }
  },
};

export { mutations };
