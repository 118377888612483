<template>
  <!--eslint-disable vue/no-mutating-props -->
  <v-flex class="pl-0 vehicle-info-dialog">
    <v-layout row class="pb-3">
      <v-flex xs12 sm6>
        <TextFieldDialog
          :active="true"
          :field="vehicleDetails.name"
          :rules="idRules"
          :name="$t('vehicle.grid.headers.name')"
          :data-qa-key="$t('vehicle.grid.headers.name')"
          @value-changed="onNameChange((vehicleDetails.name = $event))"
        >
        </TextFieldDialog>
      </v-flex>
      <v-flex xs12 sm6>
        <TextFieldDialog
          :active="true"
          :field="vehicleDetails.licensePlate"
          :rules="licensePlateRules"
          :name="$t('vehicle.grid.headers.licensePlate')"
          :data-qa-key="$t('vehicle.grid.headers.licensePlate')"
          @value-changed="
            onLicensePlateChange((vehicleDetails.licensePlate = $event))
          "
        >
        </TextFieldDialog>
      </v-flex>
      <v-flex xs12 sm6>
        <TextFieldDialog
          :active="
            vehicleDetails.licensePlate &&
            vehicleDetails.licensePlate.length > 0
          "
          :field="vehicleDetails.licensePlateState"
          :rules="licensePlateStateRules"
          :name="$t('vehicle.grid.headers.licensePlateState')"
          :data-qa-key="$t('vehicle.grid.headers.licensePlateState')"
          @value-changed="
            onLicensePlateStateChange(
              (vehicleDetails.licensePlateState = $event)
            )
          "
        >
        </TextFieldDialog>
      </v-flex>
    </v-layout>
    <v-layout row mt-4>
      <v-flex xs12 sm6 pt-2 mr-5>
        <DatePicker
          :label="$t('vehicle.grid.headers.licensePlateExpirationDate')"
          v-model="vehicleDetails.formattedLicensePlateExpirationDate"
          data-qa="add-vehicle-license-plate-expiration-date"
          :outline="false"
          :disabled="
            vehicleDetails.licensePlate == null ||
            vehicleDetails.licensePlate.length <= 0
          "
          @input="
            onLicensePlateExpirationDateChange(
              (vehicleDetails.formattedLicensePlateExpirationDate = $event)
            )
          "
        >
        </DatePicker>
      </v-flex>
      <v-flex xs12 sm6>
        <TextFieldDialog
          :field="vehicleDetails.vin"
          :name="$t('vehicle.grid.headers.vin')"
          :data-qa-key="$t('vehicle.grid.headers.vin')"
          @value-changed="vehicleDetails.vin = $event"
        >
        </TextFieldDialog>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs6 sm3>
        <TextFieldDialog
          :field="vehicleDetails.make"
          :name="$t('vehicle.grid.headers.make')"
          :data-qa-key="$t('vehicle.grid.headers.make')"
          @value-changed="vehicleDetails.make = $event"
        >
        </TextFieldDialog>
      </v-flex>
      <v-flex xs6 sm3>
        <TextFieldDialog
          :field="vehicleDetails.model"
          :name="$t('vehicle.grid.headers.model')"
          :data-qa-key="$t('vehicle.grid.headers.model')"
          @value-changed="vehicleDetails.model = $event"
        >
        </TextFieldDialog>
      </v-flex>
      <v-flex xs6 sm3>
        <TextFieldDialog
          :field="vehicleDetails.year"
          :name="$t('vehicle.grid.headers.year')"
          :data-qa-key="$t('vehicle.grid.headers.year')"
          @value-changed="vehicleDetails.year = $event"
        >
        </TextFieldDialog>
      </v-flex>
      <v-flex xs6 sm3>
        <TextFieldDialog
          :field="vehicleDetails.fuelType"
          :name="$t('vehicle.grid.headers.fuelType')"
          :data-qa-key="$t('vehicle.grid.headers.fuelType')"
          @value-changed="vehicleDetails.fuelType = $event"
        >
        </TextFieldDialog>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import TextFieldDialog from '@/components/TextFieldDialog';
import { AccountConstants } from '@/constants';
import DatePicker from '@/components/DatePicker';

export default {
  name: 'VehicleInfoDialogue',
  components: {
    TextFieldDialog,
    DatePicker,
  },
  props: {
    vehicleDetails: Object,
  },
  methods: {
    onNameChange(event) {
      this.onChange(event, 'name');
    },
    onLicensePlateChange(event) {
      this.onChange(event, 'licensePlate');
    },
    onLicensePlateStateChange(event) {
      this.onChange(event, 'licensePlateState');
    },
    onLicensePlateExpirationDateChange(event) {
      this.onChange(event, 'licensePlateExpirationDate');
    },
    onChange(event, key) {
      this.$emit('vehicle-info-changed', {
        value: event,
        key: key,
      });
    },
  },
  computed: {
    idRules() {
      return [
        (v) =>
          (v && (v === '' || v.length <= AccountConstants.MAX_NAME_LENGTH)) ||
          this.$t('vehicle.editVehicle.rules.idRule'),
      ];
    },
    licensePlateRules() {
      return [
        (v) =>
          (v && (v === '' || v.length <= AccountConstants.MAX_NAME_LENGTH)) ||
          this.$t('vehicle.editVehicle.rules.licensePlateRule'),
      ];
    },
    licensePlateStateRules() {
      return [
        (v) =>
          (v && (v === '' || v.length <= AccountConstants.MAX_NAME_LENGTH)) ||
          this.$t('vehicle.editVehicle.rules.licensePlateStateRule'),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
[data-qa='info-dialogue'] {
  display: flex;
  flex-direction: column;
}

::v-deep .v-text-field > .v-input__control > .v-input__slot {
  border-style: none;
}

::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
  width: 0;

  .v-label {
    left: 0;
    right: auto;
    position: absolute;
    color: black;
    font-weight: bold;
    font-size: 22px;
  }
}

::v-deep .v-text-field > .v-input__control > .v-input__slot:after {
  width: 0;
}

::v-deep .v-label {
  left: 0;
  right: auto;
  position: absolute;
  color: black;
  font-weight: bold;
  font-size: 22px;
}

[data-qa='vehicle-cancel'] {
  text-decoration: underline;
}

[data-qa='vehicle-name'] {
  max-width: 200px;
  min-width: 50px;
  min-height: 20px;
}

::v-deep .v-small-dialog .v-menu__activator {
  height: 0;
  font-size: 16px;
}

.edit-label {
  * {
    display: inline;
  }

  .v-icon {
    vertical-align: middle;
    padding-bottom: 4px;
    padding-left: 0.5em;
  }

  padding-top: 4px;
}
</style>
