const FORD_PRO_GATEWAY_HOSTS = [
  'fpg-tile-registry-web.dev.avmobility.io',
  'fpg-web.dev.avmobility.io',
  'localhost:3000',
  'dev.fordpro.com',
  'stage.fordpro.com',
  'fordpro.com',
  'beta.fordpro.com',
  'www.fordpro.com',
  'fordpro.ca',
  'www.fordpro.ca',
  'dev.fordpro.ca',
  'qa.fordpro.ca',
  'stage.fordpro.ca',
  'beta.fordpro.ca ',
  'fordpro.co.uk',
  'www.fordpro.co.uk',
  'dev.fordpro.co.uk',
  'qa.fordpro.co.uk',
  'stage.fordpro.co.uk',
  'beta.fordpro.co.uk ',
  'fordpro.de',
  'www.fordpro.de',
  'dev.fordpro.de',
  'qa.fordpro.de',
  'stage.fordpro.de',
  'fordpro.fr',
  'www.fordpro.fr',
  'dev.fordpro.fr',
  'qa.fordpro.fr',
  'stage.fordpro.fr',
  'beta.fordpro.fr ',
  'fordpro.es',
  'www.fordpro.es',
  'dev.fordpro.es',
  'qa.fordpro.es',
  'stage.fordpro.es',
  'beta.fordpro.es ',
  'fordpro.it',
  'www.fordpro.it',
  'dev.fordpro.it',
  'qa.fordpro.it',
  'stage.fordpro.it',
  'beta.fordpro.it',
  'fordpro.nl',
  'www.fordpro.nl',
  'dev.fordpro.nl',
  'qa.fordpro.nl',
  'stage.fordpro.nl',
  'beta.fordpro.nl',
  'fordpro.at',
  'www.fordpro.at',
  'dev.fordpro.at',
  'qa.fordpro.at',
  'stage.fordpro.at',
  'beta.fordpro.at',
  'fordpro.ie',
  'www.fordpro.ie',
  'dev.fordpro.ie',
  'qa.fordpro.ie',
  'stage.fordpro.ie',
  'beta.fordpro.ie',
  'fordpro.no',
  'www.fordpro.no',
  'dev.fordpro.no',
  'qa.fordpro.no',
  'stage.fordpro.no',
  'fordpro.ch',
  'www.fordpro.ch',
  'dev.fordpro.ch',
  'qa.fordpro.ch',
  'stage.fordpro.ch',
  'fordpro.be',
  'www.fordpro.be',
  'dev.fordpro.be',
  'qa.fordpro.be',
  'stage.fordpro.be',
  'fordpro.pl',
  'www.fordpro.pl',
  'dev.fordpro.pl',
  'qa.fordpro.pl',
  'stage.fordpro.pl',
  'beta.fordpro.mx',
];

const isTileMode = FORD_PRO_GATEWAY_HOSTS.includes(location.host);
const lastReminderForFordProGatewayRedirect = parseInt(
  localStorage.getItem('lastReminderForFordProGatewayRedirect') || '0',
  10
);

export const state = {
  fordProGatewayHostName: '',
  isTileMode,
  supportedLocales: [],
  supportedCountries: [],
  lastReminderForFordProGatewayRedirect,
  navigate: () => {},
  language: '',
};
