import Vue from 'vue';

const mutations = {
  SET_ORDER_FILTER: (state, payload) => {
    if (payload.selected.length === 0) {
      Vue.delete(state.orderFilter, payload.name);
    } else {
      Vue.set(state.orderFilter, payload.name, payload.selected);
    }
  },
  SET_PAGINATION_QUERY: (state, payload) => {
    Vue.set(state, 'paginationQuery', payload.data);
  },
  SET_SEARCH_QUERY: (state, payload) => {
    Vue.set(state, 'searchQuery', payload.data);
  },
  CLEAR_FILTER_AND_SEARCH: (state) => {
    Vue.set(state, 'orderFilter', {});
    Vue.set(state, 'searchQuery', '');
  },
  ADD_SELECTED: (state, payload) => {
    for (let productName of Object.keys(payload.data)) {
      Vue.set(state.selected, productName, payload.data[productName]);
    }
  },
  REMOVE_SELECTED: (state, payload) => {
    for (let [productName] of Object.keys(payload.data)) {
      Vue.delete(state.selected, payload.data[productName]);
    }
  },
  CLEAR_SELECTED: (state) => {
    state.selected = {};
  },
  SET_ADDRESS: (state, payload) => {
    state.address = payload.data;
  },
  CLEAR_ADDRESS: (state) => {
    state.address = {};
  },
  SET_TAX_RESPONSE: (state, payload) => {
    state.taxResponse = payload.data;
  },
  CLEAR_TAX_RESPONSE: (state) => {
    state.taxResponse = {
      orderTotal: 0.0,
      subTotal: 0.0,
      taxAmount: 0.0,
    };
  },
  SET_SHIPPING_RESPONSE: (state, payload) => {
    state.shippingResponse = {
      amount: payload.data,
    };
  },
  CLEAR_SHIPPING_RESPONSE: (state) => {
    state.shippingResponse = {
      amount: 0.0,
    };
  },
  TOGGLE_DRIVER_APP: (state, payload) => {
    state.driverAppEnabled = payload;
  },
  SET_SHOW_UPGRADE_PRODUCT_DIALOG: (state, payload) => {
    Vue.set(state, 'showUpgradeProductDialog', payload);
  },
  REFRESH: (state) => {
    return state;
  },
  SET_PRODUCT_MAP: (state, payload) => {
    Vue.set(state, 'productMap', payload);
  },
};

export { mutations };
