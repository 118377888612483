import { state } from '@/store/fpgConfiguration/state';
import { actions } from '@/store/fpgConfiguration/actions';
import { mutations } from '@/store/fpgConfiguration/mutations';
import { getters } from '@/store/fpgConfiguration/getters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export { state, mutations, actions };
