import coinify from 'coinify';

export function upgradeableSkuToCurrency(skuToUpgrade) {
  return coinify.symbol(skuToUpgrade ? skuToUpgrade.currency : 'USD');
}

export function totalVehicleUpgradeCount(skusToUpgrade) {
  let total = 0;
  skusToUpgrade.forEach((sku) => {
    total += sku.vehicleCount;
  });
  return total;
}
