<template>
  <div>
    <v-bottom-sheet v-model="showBanner" persistent>
      <v-sheet class="cookie-banner" height="200px" data-qa="cookie-banner">
        <div class="subheading">{{ $t('cookieManagement.banner.info') }}</div>
        <div>
          <v-btn
            class="banner-btn"
            data-qa="manage-btn"
            @click="onManageCookies"
            >{{ $t('cookieManagement.banner.manage') }}</v-btn
          >
          <v-btn
            class="banner-btn"
            data-qa="agree-btn"
            @click="onAcceptCookies"
            >{{ $t('cookieManagement.banner.agree') }}</v-btn
          >
        </div>
        <div class="banner-manage-cookies-link subheading">
          <i18n
            path="cookieManagement.banner.manageCookies.info"
            tag="span"
            @click.stop="'';"
          >
            <router-link
              slot="link"
              data-qa="manage-cookie-link"
              to="/manage-cookies"
              @click.native="showBanner = false"
              >{{
                $t('cookieManagement.banner.manageCookies.link')
              }}</router-link
            >
          </i18n>
        </div>
        <div class="banner-privacy-policy-link subheading">
          <i18n
            path="cookieManagement.banner.privacyPolicy.info"
            tag="span"
            @click.stop="'';"
          >
            <router-link
              slot="link"
              data-qa="privacy-policy-link"
              @click.native="showBanner = false"
              to="/policy"
              >{{
                $t('cookieManagement.banner.cookiePolicy.link')
              }}</router-link
            >
          </i18n>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import CookieUtils from '@/services/CookieUtil';

export default {
  name: 'TheCookieBanner',
  data() {
    return {
      showBanner: true,
    };
  },
  methods: {
    onAcceptCookies() {
      CookieUtils.storeCookiePreferences(true, true, true);
      CookieUtils.createBannnerInteractedCookie();
      this.showBanner = false;
    },
    onManageCookies() {
      this.showBanner = false;
      this.$router.push('/manage-cookies');
    },
  },
};
</script>
<style scoped lang="scss">
.cookie-banner {
  color: #000000;
  text-align: center;
  padding: 34px;

  .banner-btn {
    min-width: 184px;
    height: 40px;
    border-radius: 4px;
    background-color: #102b4e !important;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
  }

  .banner-manage-cookies-link {
    margin-top: 1rem;

    a {
      text-decoration: none;
      color: var(--v-secondary-base) !important;
    }
  }
  .banner-privacy-policy-link {
    margin-top: 10px;
    a {
      text-decoration: none;
      color: var(--v-secondary-base) !important;
    }
  }
}
</style>
