<template>
  <th
    :id="`${uniqueId}-grid-header-${dashify(columnName)}`"
    :data-qa="`grid-header-${dashify(columnName)}`"
    :class="getClass"
  >
    <v-container>
      <button
        v-if="sortable"
        @click="$emit('toggle-sort-direction')"
        class="header-button"
        :aria-label="getSortAriaLabel"
      >
        <span><slot></slot></span>
        <span v-if="sortable" :data-qa="`${dashify(columnName)}-sort`">
          <v-icon small>arrow_upward</v-icon>
        </span>
        <span v-if="sortActive" role="status" class="sr-only">
          {{ getSortAriaStatus }}
        </span>
      </button>
      <template v-else>
        <span><slot></slot></span>
      </template>
      <span> <slot name="action"></slot> </span>
    </v-container>
  </th>
</template>

<script>
import dashify from 'dashify';

export default {
  name: 'DataGridHeader',
  props: {
    columnName: {
      type: String,
      required: true,
    },
    columnHeader: {
      type: String,
      required: true,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    classes: Array,
    pagination: Object,
    uniqueId: String,
  },
  computed: {
    getClass() {
      let classArray = ['column'];
      if (this.sortable) {
        classArray.push(
          ...[
            'sortable',
            this.isSortDescending ? 'desc' : 'asc',
            this.sortActive ? 'active' : '',
          ]
        );
      }
      if (this.classes) {
        classArray.push(...this.classes);
      } else {
        classArray.push('text-xs-left');
      }

      return classArray;
    },
    sortActive() {
      return this.columnName === this.pagination.sortBy;
    },
    isSortDescending() {
      return this.pagination.descending;
    },
    getSortAriaLabel() {
      if (this.sortable) {
        return this.$t('grid.header.sort.label', {
          columnName: this.columnHeader,
          direction: this.$t(
            this.isSortDescending
              ? 'grid.header.sort.descending'
              : 'grid.header.sort.ascending'
          ),
          active: this.sortActive ? this.$t('grid.header.sort.active') : '',
        });
      } else {
        return '';
      }
    },
    getSortAriaStatus() {
      if (this.sortable) {
        return this.$t('grid.header.sort.status', {
          columnName: this.columnHeader,
          direction: this.$t(
            this.isSortDescending
              ? 'grid.header.sort.descending'
              : 'grid.header.sort.ascending'
          ),
          active: this.sortActive ? this.$t('grid.header.sort.active') : '',
        });
      } else {
        return '';
      }
    },
  },
  methods: {
    dashify,
  },
};
</script>

<style scoped lang="scss">
span,
.header-button {
  height: 22px;
  color: #292e35;
  font-size: 15px;
  line-height: 22px;
}

.header-button {
  text-align: left;
  margin: 0;
  padding: 0 0 0 0.25rem;
  background-color: transparent;
}

button.header-button:focus-visible,
button.header-button:focus {
  outline: 1.5px solid var(--v-secondary-base);
  outline-offset: 3px;
  border-radius: 2px;
}
</style>
