const actions = {
  setOrderFilter: ({ commit }, payload) => {
    commit('SET_ORDER_FILTER', payload);
  },
  setSearchQuery: ({ commit }, payload) => {
    commit('SET_SEARCH_QUERY', payload);
  },
  clearFilterAndSearch: ({ commit }) => {
    commit('CLEAR_FILTER_AND_SEARCH');
  },
  setPaginationQuery({ commit }, payload) {
    commit('SET_PAGINATION_QUERY', payload);
  },
  addSelected: ({ commit }, payload) => {
    let newSelected = {};

    payload.data.forEach((product) => {
      newSelected[product[payload.key]] = {
        sku: product.sku,
        name: product.name,
        skuPrice: product.skuPrice,
        currencyCode: product.currencyCode,
        quantity: product.quantity,
        productFamilyId: product.productFamilyId,
      };
    });

    commit('ADD_SELECTED', { data: newSelected });
  },
  removeSelected: ({ commit }, payload) => {
    commit('REMOVE_SELECTED', payload);
  },
  clearSelected: ({ commit }, payload) => {
    commit('CLEAR_SELECTED', payload);
  },
  setAddress: ({ commit }, payload) => {
    commit('SET_ADDRESS', payload);
  },
  enableUpgradeProductDialog: ({ commit }) => {
    commit('SET_SHOW_UPGRADE_PRODUCT_DIALOG', true);
  },
  disableUpgradeProductDialog: ({ commit }) => {
    commit('SET_SHOW_UPGRADE_PRODUCT_DIALOG', false);
  },
  clearAddress: ({ commit }, payload) => {
    commit('CLEAR_ADDRESS', payload);
  },
  setTaxResponse: ({ commit }, payload) => {
    commit('SET_TAX_RESPONSE', payload);
  },
  clearTaxResponse: ({ commit }) => {
    commit('CLEAR_TAX_RESPONSE');
  },
  setShippingResponse: ({ commit }, payload) => {
    commit('SET_SHIPPING_RESPONSE', payload);
  },
  clearShippingResponse: ({ commit }) => {
    commit('CLEAR_SHIPPING_RESPONSE');
  },
  toggleDriverAppSetting: ({ commit }, payload) => {
    commit('TOGGLE_DRIVER_APP', payload);
  },
  refreshProduct: ({ commit }) => {
    commit('REFRESH');
  },
  setProductMap: ({ commit }, payload) => {
    commit('SET_PRODUCT_MAP', payload);
  },
};

export { actions };
