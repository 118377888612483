import { state } from '@/store/productUpgrade/state';
import { actions } from '@/store/productUpgrade/actions';
import { mutations } from '@/store/productUpgrade/mutations';
import { getters } from '@/store/productUpgrade/getters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export { state, mutations, actions, getters };
