const state = {
  linkAssetFilter: {},
  totalLinkedAsset: 0,
  searchQuery: '',
  selected: {},
  paginationQuery: {},
  totalFilteredLinkAsset: 0,
  existingEquipmentLinks: {},
};

export { state };
