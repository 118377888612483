import Vue from 'vue';

const mutations = {
  SET_ALL_COMPANY_INFO: (state, payload) => {
    state.companyName = payload.companyName;
    state.address1 = payload.address1;
    state.address2 = payload.address2;
    state.city = payload.city;
    state.countryCode = payload.country ? payload.country.code : null;
    state.state = payload.state;
    state.zipCode = payload.zipCode;
    state.taxId = payload.taxId;
    state.childAccounts = payload.childAccounts;
    state.initialBillingInfo = payload;
  },
  SET_COMPANY_NAME: (state, payload) => {
    state.companyName = payload;
    state.hasChanged = true;
  },
  SET_ADDRESS_LINE1: (state, payload) => {
    state.address1 = payload;
    state.hasChanged = true;
  },
  SET_ADDRESS_LINE2: (state, payload) => {
    state.address2 = payload;
    state.hasChanged = true;
  },
  SET_CITY: (state, payload) => {
    state.city = payload;
    state.hasChanged = true;
  },
  SET_COUNTRY_CODE: (state, payload) => {
    state.countryCode = payload;
    state.hasChanged = true;
  },
  SET_STATE: (state, payload) => {
    state.state = payload;
    state.hasChanged = true;
  },
  SET_INITIAL_STATE: (state, payload) => {
    state.state = payload;
  },
  SET_ZIP_CODE: (state, payload) => {
    state.zipCode = payload;
    state.hasChanged = true;
  },
  SET_TAX_ID: (state, payload) => {
    state.taxId = payload;
    state.hasChanged = true;
  },
  SET_IS_FETCHING_DATA: (state, payload) => {
    state.isFetchingData = payload;
  },
  SET_IS_FETCHING_CHILD_ACCOUNT_DATA: (state, payload) => {
    state.isFetchingChildAccountData = payload;
  },
  DELETE_CHILD_ACCOUNT: (state, payload) => {
    let childIndex = payload;
    let parentAccountAddress = {
      address1: state.address1,
      address2: state.address2,
      city: state.city,
      country: state.countryCode,
      state: state.state.name,
      zipCode: state.zipCode,
    };
    Vue.set(
      state.childAccounts[childIndex],
      'physicalPresenceIndicator',
      'false'
    );
    Vue.set(state.childAccounts[childIndex], 'customerTaxId', '');
    Vue.set(
      state.childAccounts[childIndex],
      'billToContact',
      parentAccountAddress
    );
    state.hasChanged = true;
  },
  UPDATE_CHILD_ACCOUNT: (state, payload) => {
    let childIndex = payload.childIndex;
    let physicalPresenceIndicator = payload.physicalPresenceIndicator;
    if (physicalPresenceIndicator === 'false') {
      let parentAccountAddress = {
        address1: state.address1,
        address2: state.address2,
        city: state.city,
        country: state.countryCode,
        state: state.state.name,
        zipCode: state.zipCode,
      };
      Vue.set(
        state.childAccounts[childIndex],
        'billToContact',
        parentAccountAddress
      );
    } else if (physicalPresenceIndicator === 'true') {
      let updatedChildAccount = {
        address1: payload.address1,
        address2: payload.address2,
        city: payload.city,
        country: payload.countryCode,
        state: payload.state,
        zipCode: payload.zipCode,
      };
      Vue.set(
        state.childAccounts[childIndex],
        'billToContact',
        updatedChildAccount
      );
    }
    Vue.set(
      state.childAccounts[childIndex],
      'physicalPresenceIndicator',
      physicalPresenceIndicator
    );
    Vue.set(state.childAccounts[childIndex], 'customerTaxId', payload.taxId);
    state.hasChanged = true;
  },
  UPDATE_CHILD_ACCOUNT_ADDRESS: (state) => {
    state.childAccounts.forEach((c) => {
      if (c.physicalPresenceIndicator === 'false') {
        let parentAccountAddress = {
          address1: state.address1,
          address2: state.address2,
          city: state.city,
          country: state.countryCode,
          state:
            state.state.state_code ||
            (state.state.hasOwnProperty('name')
              ? state.state.name
              : state.state),
          zipCode: state.zipCode,
          firstName: c.billToContact.firstName
            ? c.billToContact.firstName
            : null,
          lastName: c.billToContact.lastName ? c.billToContact.lastName : null,
        };
        Vue.set(c, 'billToContact', parentAccountAddress);
      }
    });
    state.hasChanged = true;
  },
  SET_CHILD_ACCOUNTS: (state, payload) => {
    state.childAccounts = payload;
    state.hasChanged = true;
  },
};

export { mutations };
