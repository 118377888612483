import Vue from 'vue';
import AccountApiProxyService from '@/services/AccountApiProxyService';
import store from '@/store';
import authService from '@/services/AuthService';
import { AccountConstants } from '@/constants';

export default new Vue({
  data: () => {
    return {
      interval: 1 * 60 * 1000,
      minusFromMinutes: 2 * 60 * 1000,
    };
  },
  methods: {
    async fetchNewToken() {
      try {
        let response = await AccountApiProxyService.get(
          AccountConstants.ACCOUNT_API_PATH +
            '/authorize/refresh?user-active=' +
            !store.state.idleVue.isIdle,
          {
            withCredentials: true,
          }
        );
        if (response.data.access_token !== undefined) {
          await authService.authenticateUser();
        }
        store.dispatch('auth/setTokenExpiry', response.data.expires_in);
      } catch (error) {
        console.log(error);
      }
    },
    isTokenExpired() {
      let currentDate = new Date().valueOf();
      let expiredTime = parseInt(
        store.state.auth['expires_in'] - this.minusFromMinutes
      );
      let delta = expiredTime < currentDate;
      return delta;
    },
    setJob() {
      setInterval(() => {
        if (this.isTokenExpired()) {
          console.log('Token Expired');
          this.fetchNewToken();
        }
      }, this.interval);
    },
  },
});
