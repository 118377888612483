const mutations = {
  PUSH_BREADCRUMBS: (state, breadcrumb) => {
    state.breadcrumbs.push(breadcrumb);
  },
  POP_BREADCRUMBS: (state) => {
    state.breadcrumbs.pop();
  },
  EMPTY_BREADCRUMBS: (state) => {
    state.breadcrumbs = [];
  },
  SET_BREADCRUMBS: (state, index) => {
    state.breadcrumbs = state.breadcrumbs.slice(0, index + 1);
  },
};

export { mutations };
