export const HeaderHeight = {
  data() {
    return {
      headerHeight: 50,
      observer: new ResizeObserver(this.setHeaderHeight),
    };
  },
  methods: {
    setHeaderHeight() {
      this.headerHeight = this.headerElements
        .map((el) => el.offsetHeight)
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        );
    },
  },
  computed: {
    headerElements: function () {
      return Array.from(
        document.querySelectorAll(
          'header.cxc-header, main nav[class*="fpg-web--Submenu-module"], #global-topbar nav, header[class*="fpg-web--AuthHeader-module--auth-header"], header[class*="fpg-web--Header-module--header"]'
        )
      );
    },
  },
  destroyed() {
    this.observer.disconnect();
  },
  created() {
    this.headerElements.forEach((el) => this.observer.observe(el));
  },
};
