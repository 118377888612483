<template>
  <div class="filter-totals mr-2">
    <span data-qa="total"
      >{{ $t('vehicle.filter.total') }} {{ this.totalVehicles }}</span
    >
    <v-fade-transition>
      <span v-if="areFiltersOrSearchApplied" data-qa="filtered-total">
        | {{ $t('vehicle.filter.filteredTotal') }}&nbsp;
        <span class="qa-filtered-total-count">{{
          this.totalFilteredVehicles
        }}</span>
      </span>
    </v-fade-transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'VehicleFilterTotal',
  computed: {
    ...mapState('vehicle', {
      totalVehicles: (state) => state.totalVehicles,
      totalFilteredVehicles: (state) => state.totalFilteredVehicles,
    }),
    ...mapGetters('vehicle', ['areFiltersOrSearchApplied']),
  },
};
</script>

<style scoped lang="scss">
.filter-totals {
  font-size: 16px;
}
</style>
