<template>
  <v-layout row wrap>
    <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      lazy
      transition="scale-transition"
      offset-y
      full-width
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :label="label"
          prepend-icon="event"
          :outline="outline"
          clearable
          readonly
          placeholder="YYYY-MM-DD"
          v-model="dateVal"
          v-on="on"
          :disabled="disabled"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="dateVal"
        no-title
        :reactive="true"
        @input="dateMenu = false"
        :disabled="disabled"
      ></v-date-picker>
    </v-menu>
  </v-layout>
</template>
<script>
export default {
  name: 'DatePicker',
  props: {
    label: String,
    value: String,
    outline: {
      type: Boolean,
      default: () => true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      dateMenu: false,
    };
  },
  computed: {
    dateVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
