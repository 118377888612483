import { make } from 'vuex-pathify';
import { state as userEditDetails } from '@/store/userEditDetails/state';

const actions = {
  ...make.actions(userEditDetails),
  clearCurrentReimbursableAddress: ({ commit }) => {
    commit('CLEAR_CURRENT_REIMBURSABLE_ADDRESS');
  },
};

export { actions };
