import { make } from 'vuex-pathify';
import { isEqual } from 'lodash';
import moment from 'moment';
import { state as userEditDetails } from '@/store/userEditDetails/state';
import { AccountConstants } from '@/constants';
import { i18n } from '@/i18n';

const getters = {
  ...make.getters(userEditDetails),
  userFullName: (state) => {
    return `${state.userDetails.firstName} ${state.userDetails.lastName}`;
  },
  isModified: (state) => {
    return (
      !isEqual(state.userDetails, state.currentData) ||
      state.rolesToAdd.length > 0 ||
      state.rolesToRemove.length > 0
    );
  },
  userInviteStatusMessage: (state) => {
    switch (state.userDetails.inviteStatus) {
      case AccountConstants.inviteStatus.NOT_INVITED:
        return i18n.t('user.notInvited');
      case AccountConstants.inviteStatus.INVITED:
        return `${i18n.t('user.invited')}: ${i18nMoment(
          state.userDetails.inviteTime
        )}`;
      case AccountConstants.inviteStatus.ACTIVE:
        return `${i18n.t('user.active')}: ${i18nMoment(
          state.userDetails.lastLoginTime
        )}`;
      case AccountConstants.inviteStatus.INACTIVE:
        return `${i18n.t('user.inactive')}: ${i18nMoment(
          state.userDetails.lastLoginTime
        )}`;
    }
  },
};

export { getters };

function i18nMoment(date) {
  moment.locale(i18n.locale);
  if (!date) return i18n.t('user.never');
  return moment(new Date(date)).calendar();
}
