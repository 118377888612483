<!--suppress CssInvalidPseudoSelector -->
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog v-model="dialog" scrollable>
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <ErrorCard
      :error="error"
      @close="hideDialog"
      :primaryHeader="primaryHeader"
    />
  </v-dialog>
</template>

<script>
import ErrorCard from '@/components/utility-components/dialogs/ErrorCard';

export default {
  name: 'ErrorDialog',
  components: { ErrorCard },
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    error: {
      title: String,
      description: String,
      action: String,
    },
    showDialog: Boolean,
    primaryHeader: Boolean,
  },
  methods: {
    hideDialog() {
      this.dialog = false;
      this.$emit('close-dialog');
    },
    runShowDialog() {
      this.dialog = this.showDialog;
    },
  },
  watch: {
    showDialog() {
      this.runShowDialog();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  max-width: 480px;
}

.v-dialog__container {
  display: none !important;
}
</style>
