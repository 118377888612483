import VehicleService from './VehicleService';
import GroupService from './GroupService';
import CompanyService from './CompanyService';
import CatManService from './CatManService';

export default {
  async getFleetTableFilters() {
    let [vehicleFilters, productNames] = await Promise.all([
      this.getVehicleAndSegmentFilters(),
      this.getProductFilters(),
    ]);
    return { data: { ...vehicleFilters, products: productNames } };
  },
  async getVehicleAndSegmentFilters() {
    let vehicleFilters = {
      makes: [],
      models: [],
      years: [],
      groups: [],
      fuelTypes: [],
      hardwareCategories: [],
      licensePlateStates: [],
    };

    try {
      let vehicleResponse = await VehicleService.getConsentedVehicleFilters();
      vehicleFilters.makes = vehicleResponse.data.makes;
      vehicleFilters.models = vehicleResponse.data.models;
      vehicleFilters.years = vehicleResponse.data.years;
      vehicleFilters.fuelTypes = vehicleResponse.data.fuelTypes;
      vehicleFilters.hardwareCategories =
        vehicleResponse.data.hardwareCategories;
      vehicleFilters.licensePlateStates =
        vehicleResponse.data.licensePlateStates;

      try {
        let segmentResponse = await GroupService.getSegmentInfo(
          vehicleResponse.data.segmentIds
        );
        vehicleFilters.groups = segmentResponse.data.map((info) => {
          return { id: info.id, name: info.name };
        });
      } catch (e) {
        console.error('failed to retrieve segment filters', e);
      }
    } catch (e) {
      console.error('failed to retrieve vehicle filters', e);
    }

    return vehicleFilters;
  },
  async getProductFilters() {
    try {
      let companySkusResponse = await CompanyService.getCompanyProductSkus();
      let catManResponse = await CatManService.getProductsInfo(
        companySkusResponse.data
      );

      return catManResponse.data
        .filter(
          (product) =>
            product.subscribable && product.productLevel === 'Vehicle'
        )
        .map((product) => product.productName);
    } catch (e) {
      console.error('failed to retrieve product filters', e);
      return [];
    }
  },
};
