import router from '@/router/router';
import store from '@/store';
import AccountApiProxyService from '@/services/AccountApiProxyService';
import SettingService from '@/services/AccountApiSettingsService';
import {
  loadLanguageAsync,
  loadLanguageBasedOnBrowserPreference,
} from '@/i18n';
import AccountStaticService from '@/services/AccountStaticService';
import { AccountConstants } from '@/constants';
import {
  getStaticAssetsBaseUrl,
  replaceFordProUrlDomainBasedOnCountry,
} from '@/urlUtils';
import fpgConfiguration from '@/store/fpgConfiguration';
import { removeLeadingSlash, removeTrailingSlash } from '@/utils';

const authService = {
  performAuthenticationLoginAction(action) {
    return store
      .set('configuration/fetch!', 'loginUrl')
      .then((loginUrl) => action(loginUrl));
  },
  performAuthenticationLogoutAction(action) {
    return store
      .set('configuration/fetch!', 'logoutUrl')
      .then((logoutUrl) => action(logoutUrl));
  },
  goToLogin(returnPage) {
    if (!this.isAuthenticated()) {
      localStorage.setItem('dmShowVehicleOwnershipConfirmationDialog', 'true');
      return this.performAuthenticationLoginAction((loginUrl) => {
        window.location.assign(loginUrl.replace('{redirect}', '/'));
        let redirect = '/';
        let modifiedReturnPage = returnPage;
        if (fpgConfiguration.state.isTileMode) {
          modifiedReturnPage = `${removeTrailingSlash(
            window.location.origin
          )}/${removeLeadingSlash(returnPage)}`;
        }
        if (returnPage) {
          if (returnPage.length > 1) {
            localStorage.setItem('firstEvent', false);
            redirect = modifiedReturnPage;
          }
        }
        window.location.assign(loginUrl.replace('{redirect}', redirect));
      });
    } else {
      return router.push('/');
    }
  },

  goToLogout() {
    localStorage.clear();
    sessionStorage.clear();
    return this.performAuthenticationLogoutAction((logoutUrl) => {
      window.location.assign(logoutUrl);
    });
  },

  getUserInfo() {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH + '/authorize/userinfo',
      {
        withCredentials: true,
      }
    );
  },
  getPartnerTokenToRedirect(applicationId, companyCountryCode, state) {
    const stateParam = state ? `&state=${btoa(JSON.stringify(state))}` : '';
    return AccountApiProxyService.get(
      '/pro/auth/v1/partner-app?redirect=false&app-id=' +
        applicationId +
        stateParam,
      { withCredentials: true }
    ).then((res) => {
      res.data = replaceFordProUrlDomainBasedOnCountry(
        res.data,
        companyCountryCode
      );
      return res;
    });
  },

  async authenticateUser() {
    function loadLanguage(userLanguage) {
      loadLanguageAsync(userLanguage).catch((err) => {
        console.log(err);
      });
    }

    try {
      let response = await this.getUserInfo();
      if (response.status === 200) {
        await store.dispatch('auth/setUserInfo', response.data);
        let userLanguage;
        const languageSettingResponse = await SettingService.getUserLanguage(
          response.data.userId
        );
        if (languageSettingResponse.status === 200) {
          userLanguage = languageSettingResponse.data;
          await store.dispatch('auth/setLanguage', userLanguage);
        } else {
          userLanguage = store.state.auth['language'];
        }
        loadLanguage(userLanguage);
        let languageCheck = '/getstarted/' + userLanguage + '.pdf';
        let pdfUrl =
          getStaticAssetsBaseUrl() +
          '/getstarted/' +
          AccountConstants.DEFAULT_LANGUAGE +
          '.pdf';

        await AccountStaticService.get(languageCheck)
          .then(() => {
            pdfUrl =
              getStaticAssetsBaseUrl() + '/getstarted/' + userLanguage + '.pdf';
          })
          .catch((err) => {
            console.log(
              'Unable to fetch pdf for language ' +
                userLanguage +
                ' fetching default pdf. Error: ',
              err
            );
          });

        store.dispatch('auth/setIsLoggedIn', true);
        store.dispatch('auth/setGetStartedPdfUrl', pdfUrl);
        if (
          response.data.authorities.indexOf('TERMS_AND_CONDITIONS_SIGN') !==
            -1 &&
          !store.getters['termsAndConditions/hasFetchedTermsAndConditions']
        ) {
          const TCResponse = await this.getHasSignedTermsAndConditions();
          await store.dispatch(
            'termsAndConditions/setSignedTermsAndConditions',
            TCResponse.data
          );
          await store.dispatch(
            'termsAndConditions/setFetchedTermsAndConditions',
            true
          );
          await store.dispatch(
            'termsAndConditions/setCanViewOnboardPage',
            true
          );
          await router.push('/onboard');
        }
      } else {
        loadLanguageBasedOnBrowserPreference().catch((err) => console.log(err));
        console.log('User info not found:');
        console.log(response);
        store.dispatch('auth/setIsLoggedIn', false);
      }
    } catch (error) {
      loadLanguageBasedOnBrowserPreference().catch((err) => console.log(err));
      console.log('Error retrieving user info:');
      console.log(JSON.stringify(error));
      store.dispatch('auth/setIsLoggedIn', false);
    }
  },

  isAuthenticated() {
    return store.state.auth['isLoggedIn'];
  },

  async getHasSignedTermsAndConditions() {
    return AccountApiProxyService.get(
      AccountConstants.ACCOUNT_API_PATH + '/v1/policy/termsAndConditions/agreed'
    );
  },
};

export default authService;
