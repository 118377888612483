var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TemplateApp',{attrs:{"sidebar-items":_vm.sidebarItems},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.getIsLoggedIn || _vm.isRegistrationForm)?_c('GlobalTopBar',{attrs:{"data-qa":"top-bar"}}):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [(_vm.getIsLoggedIn)?_c('TheFooter'):_vm._e()]},proxy:true},{key:"dialogs",fn:function(ref){
var canUseFFMApplication = ref.canUseFFMApplication;
return [_c('FordProGatewayRedirectDialog'),(
        _vm.getIsLoggedIn &&
        canUseFFMApplication &&
        _vm.isOneTrustEnabled &&
        !_vm.isCookieBannerInteracted
      )?_c('TheCookieBanner'):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }