import { render, staticRenderFns } from "./MultiProductSelection.vue?vue&type=template&id=39d51db7&scoped=true&"
import script from "./MultiProductSelection.vue?vue&type=script&lang=js&"
export * from "./MultiProductSelection.vue?vue&type=script&lang=js&"
import style0 from "./MultiProductSelection.vue?vue&type=style&index=0&id=39d51db7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39d51db7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCheckbox,VChip,VContainer,VFlex,VImg,VLayout})
