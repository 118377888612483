import Vue from 'vue';

const mutations = {
  SET_EQUIPMENT_FILTER: (state, payload) => {
    if (payload.selected.length === 0) {
      Vue.delete(state.equipmentFilter, payload.name);
    } else {
      Vue.set(state.equipmentFilter, payload.name, payload.selected);
    }
  },
  SET_TOTAL_EQUIPMENT: (state, payload) => {
    Vue.set(state, 'totalEquipment', payload);
  },
  SET_SEARCH_QUERY: (state, payload) => {
    Vue.set(state, 'searchQuery', payload.data);
  },
  SET_PAGINATION_QUERY: (state, payload) => {
    Vue.set(state, 'paginationQuery', payload.data);
  },
  REFRESH_TABLE: (state) => {
    return state;
  },
  SET_TOTAL_FILTERED_EQUIPMENT: (state, payload) => {
    Vue.set(state, 'totalFilteredEquipment', payload);
  },
  CLEAR_FILTER_AND_SEARCH: (state) => {
    Vue.set(state, 'searchQuery', '');
    Vue.set(state, 'equipmentFilter', {});
  },
  ADD_SELECTED: (state, payload) => {
    for (let equipmentId of Object.keys(payload.data)) {
      Vue.set(state.selected, equipmentId, payload.data[equipmentId]);
    }
  },
  REMOVE_SELECTED: (state, payload) => {
    for (let equipmentId of Object.keys(payload.data)) {
      Vue.delete(state.selected, payload.data[equipmentId]);
    }
  },
  CLEAR_SELECTED: (state) => {
    state.selected = {};
  },
};

export { mutations };
