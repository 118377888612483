<template>
  <v-checkbox
    v-if="true"
    v-model="isNegativeOptionChecked"
    :label="$t('subscriptionConfirmation.checkboxMessage')"
    color="primary"
    data-qa="negative-option-checkbox"
    required
  ></v-checkbox>
</template>

<script>
export default {
  name: 'NegativeOptionCheckbox',
  data() {
    return {
      confirmDisabled: true,
      isNegativeOptionChecked: false,
    };
  },
  watch: {
    isNegativeOptionChecked() {
      this.confirmDisabled = !this.isNegativeOptionChecked;
    },
    confirmDisabled() {
      this.$emit('confirmDisable', {
        confirmDisabled: this.confirmDisabled,
      });
    },
  },
};
</script>
<style scoped>
.v-input--checkbox::v-deep {
  .v-label {
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
