<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <span class="filter">
    <v-menu
      :close-on-content-click="false"
      v-model="filterActive"
      :nudgeBottom="20"
      :offsetY="true"
      ref="menu"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          data-qa="grid-columns-customize-button"
          outline
          class="secondary--text text-none export-button"
          v-on="on"
          @click.native.stop
        >
          <span class="pr-2"><v-icon small>ic-cog</v-icon></span>
          <span>{{ label }}</span>
          <span
            ><v-icon
              data-qa="grid-column-customize-dropdown"
              class="secondary--text pl-1 align-start"
              size="23"
              >ic-down</v-icon
            ></span
          >
        </v-btn>
      </template>
      <v-combobox
        :value="getSelected()"
        :items="filterItems"
        :menu-props="{ maxWidth: '350px' }"
        item-value="code"
        item-text="name"
        multiple
        chips
        deletable-chips
        class="filter-options"
        data-qa="grid-column-filter"
        type="button"
        ref="comboChild"
        v-on:change="saveChanges"
      />
    </v-menu>
  </span>
</template>

<script>
import SettingService from '@/services/AccountApiSettingsService';

export default {
  name: 'DataGridColumnSelector',
  props: {
    filterItems: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    storeModule: {
      type: String,
      required: true,
    },
    storeAction: {
      type: String,
      required: true,
    },
    storeGetter: {
      type: String,
      required: true,
    },
    payloadUrl: {
      type: String,
      required: false,
    },
  },
  methods: {
    saveChanges(payload) {
      this.$store.dispatch(`${this.storeModule}/${this.storeAction}`, payload);
      if (this.payloadUrl) {
        SettingService.updateSettingValue(
          this.payloadUrl,
          payload.map((v) => v.code)
        ).catch((err) => {
          console.log(err);
        });
      }
    },
    getSelected() {
      return this.$store.getters[`${this.storeModule}/${this.storeGetter}`];
    },
  },
  mounted() {
    this.$watch('$refs.menu.hasJustFocused', (isVisible) => {
      if (isVisible) {
        this.$refs.comboChild.focus();
        this.$refs.comboChild.activateMenu();
      }
    });
  },
  data: () => {
    return {
      filterActive: false,
    };
  },
};
</script>

<style scoped lang="scss">
::v-deep .filter-options {
  .v-input__slot {
    height: auto !important;
    padding-left: 4px;
  }
}

.v-autocomplete {
  width: 350px;
}

.filter-options {
  background-color: white;
  padding-top: 0;
  margin-top: 0;
}

.v-menu__content {
  &.menuable__content__active [role='list'] {
    transform: translateY(40px);
  }
}

[role='combobox'].v-text-field {
  margin: 0;
  padding: 0;

  .v-text-field__details {
    display: none;
  }
}

.export-button {
  margin: 10px 0 6px 0;
  border-color: #2861a4;
}

::v-deep .v-list__tile__action {
  .v-input--selection-controls {
    .v-input__slot {
      margin-bottom: 0px;
    }
  }
}
</style>
