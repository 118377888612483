const state = {
  companyName: '',
  address1: '',
  address2: '',
  city: '',
  countryCode: '',
  state: '',
  zipCode: '',
  taxId: '',
  isFetchingData: false,
  isFetchingChildAccountData: false,
  childAccounts: [],
  initialBillingInfo: {},
  hasChanged: false,
};

const mockState = function () {
  return {
    companyName: '',
    address1: '',
    address2: '',
    city: '',
    countryCode: '',
    state: '',
    zipCode: '',
    taxId: '',
    isFetchingData: false,
    childAccounts: [],
    initialBillingInfo: {},
    hasChanged: false,
  };
};
export { state, mockState };
