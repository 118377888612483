import Vue from 'vue';

const mutations = {
  SET_LINK_ASSET_FILTER: (state, payload) => {
    if (payload.selected.length === 0) {
      Vue.delete(state.linkAssetFilter, payload.name);
    } else {
      Vue.set(state.linkAssetFilter, payload.name, payload.selected);
    }
  },
  SET_TOTAL_LINK_ASSET: (state, payload) => {
    Vue.set(state, 'totalLinkedAsset', payload);
  },
  SET_SEARCH_QUERY: (state, payload) => {
    Vue.set(state, 'searchQuery', payload.data);
  },
  SET_PAGINATION_QUERY: (state, payload) => {
    Vue.set(state, 'paginationQuery', payload.data);
  },
  REFRESH_TABLE: (state) => {
    return state;
  },
  SET_TOTAL_FILTERED_LINK_ASSET: (state, payload) => {
    Vue.set(state, 'totalFilteredLinkAsset', payload);
  },
  CLEAR_FILTER_AND_SEARCH: (state) => {
    Vue.set(state, 'searchQuery', '');
    Vue.set(state, 'linkAssetFilter', {});
  },
  ADD_SELECTED: (state, payload) => {
    for (let equipmentId of Object.keys(payload.data)) {
      Vue.set(state.selected, equipmentId, payload.data[equipmentId]);
    }
  },
  REMOVE_SELECTED: (state, payload) => {
    for (let equipmentId of Object.keys(payload.data)) {
      Vue.delete(state.selected, payload.data[equipmentId]);
    }
  },
  CLEAR_SELECTED: (state) => {
    state.selected = {};
  },
  CLOSE_LINKED_ASSETS_DIALOG: () => {},
  RESET_STATE: (state) => {
    state.linkAssetFilter = {};
    state.totalLinkedAsset = 0;
    state.searchQuery = '';
    state.selected = {};
    state.paginationQuery = {};
    state.totalFilteredLinkAsset = 0;
  },
  SET_EXISTING_EQUIPMENT_LINKS: (state, payload) => {
    Vue.set(state, 'existingEquipmentLinks', payload);
  },
};

export { mutations };
