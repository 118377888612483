import { make } from 'vuex-pathify';
import { state as fpgConfiguration } from '@/store/fpgConfiguration';

const actions = {
  ...make.actions(fpgConfiguration),
  setLastReminderForFordProGatewayRedirect: ({ commit }) => {
    const now = Date.now();
    localStorage.setItem(
      'lastReminderForFordProGatewayRedirect',
      now.toString()
    );
    commit('SET_LAST_REMINDER_FOR_FORD_PRO_GATEWAY_REDIRECT', now);
  },
};

export { actions };
