<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    block
    large
    :class="buttonClass"
    :data-qa="dataQa"
    :color="color"
    :flat="color === undefined"
  >
    <slot>{{ $t('confirmationDialog.cancel') }}</slot>
  </v-btn>
</template>

<script>
export default {
  name: 'CancelButton',
  props: {
    dataQa: String,
    color: { type: String, required: false },
  },
  computed: {
    buttonClass() {
      let className = 'text-none';
      if (this.color !== 'primary') {
        className += ' primary--text';
      }
      return className;
    },
  },
};
</script>
