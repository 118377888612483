<template>
  <div>
    <div>
      <h2
        data-qa="terms-and-conditions-title"
        class="headline primary--text terms-and-conditions-title"
        >{{ title }}</h2
      >
    </div>
    <div class="terms-and-conditions-description">
      <p class="content-font" data-qa="terms-and-conditions-description">{{
        description
      }}</p>
    </div>
    <v-layout justify-center row wrap xs12>
      <v-flex xs12 class="download">
        <section class="content">
          <p
            data-qa="terms-and-conditions-text"
            class="content-font"
            v-html="termsAndConditions"
            ref="termsAndConditionsElement"
          ></p>
        </section>
      </v-flex>
    </v-layout>
    <section class="action-item">
      <v-layout justify-center row wrap xs12>
        <v-flex xs3 class="download">
          <v-icon
            class="icon"
            data-qa="terms-and-conditions-download"
            color="secondary"
            small
            @click="onDownload"
            >fal fa-download
          </v-icon>
          <a @click="onDownload" data-qa="download-ffm-terms-and-conditions">{{
            $t('termsAndConditions.download')
          }}</a>
        </v-flex>
        <v-flex xs12>
          <span class="check-read">
            <v-checkbox
              :label="$t('termsAndConditions.AgreementMessage')"
              class="content-font agree-checkbox"
              color="primary"
              data-qa="agree-terms-and-conditions-checkbox"
              :input-value="checkedAgreeTermsAndConditions"
              :disabled="!termsAndConditions || disableAgree"
              @change="setCheckedAgreeTermsAndConditions($event)"
            />
          </span>
          <p
            data-qa="terms-and-conditions-failure"
            class="disable-message"
            v-if="!termsAndConditions || disableAgree"
          >
            {{ displayErrorMessage }}
          </p>
        </v-flex>
      </v-layout>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { handleTermsAndConditionsDownload } from '@/services/Downloads';

export default {
  name: 'AcceptTermsAndConditions',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    termsAndConditions: {
      type: String,
    },
    legalLanguageByLlidId: {
      type: String,
    },
    productName: {
      type: String,
      required: false,
    },
    disableAgree: {
      type: Boolean,
      default: false,
    },
    disableAgreeMessage: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    onDownload() {
      handleTermsAndConditionsDownload(
        this.legalLanguageByLlidId,
        this.productName
      );
    },
    ...mapActions('termsAndConditions', ['setCheckedAgreeTermsAndConditions']),
    ...mapActions('notification', ['addErrorNotification']),
  },
  computed: {
    ...mapGetters('termsAndConditions', ['checkedAgreeTermsAndConditions']),
    displayErrorMessage() {
      if (!this.termsAndConditions) {
        return this.$t('termsAndConditions.failedToRetrieve');
      } else if (this.disableAgreeMessage) {
        return this.disableAgreeMessage;
      }
      return undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.agree-checkbox {
  justify-content: center;
}

.terms-and-conditions-title {
  margin-top: 15px;
  margin-bottom: 25px;
  text-align: center;
  font-size: 24px !important;
  color: #292e35 !important;
}

.terms-and-conditions-description {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.content {
  overflow: auto;
  height: 478px;
  padding: 1%;
}

.content-font {
  font-size: 16px;
}

.action-item {
  margin-top: 20px;

  .disable-message {
    margin-bottom: 15px;
    color: #d92e14;
    text-align: center;
  }

  .check-read {
    margin-bottom: 10px;
  }

  .download {
    cursor: pointer;
    font-size: 16px;
    line-height: 19px;
    text-decoration: underline;
    padding-top: 5px;
    text-align: center;

    .icon {
      padding-bottom: 2px;
      margin-right: 5px;
    }

    a {
      color: #2762a4;
    }
  }
}
</style>
