<template>
  <v-dialog
    v-model="dialog"
    persistent
    lazy
    @keydown.esc="closeDialog"
    max-width="600px"
    data-app
  >
    <v-card flat class="dialog-card" data-qa="contact-us-dialog">
      <v-card-title>
        <span class="dialog-title">{{ $t('contactUs.popup.header') }}</span>
      </v-card-title>
      <v-card-text>
        <div class="card-text">
          <p class="card-text-paragraph">{{
            $t('contactUs.popup.body.line1')
          }}</p>
          <p class="card-text-paragraph">{{
            $t('contactUs.popup.body.line2', { phoneNumber })
          }}</p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          block
          data-qa="contact-us-dialog-cancel-button"
          flat
          large
          class="primary--text text-none button-text"
          @click="closeDialog"
        >
          {{ $t('contactUs.popup.buttonCancel') }}
        </v-btn>
        <v-btn
          block
          data-qa="contact-us-dialog-email-button"
          large
          color="primary"
          class="text-none button-text"
          :href="$t('contactUs.popup.contactEmail', { email })"
          @click="closeDialog"
        >
          {{ $t('contactUs.popup.buttonContact') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ContactUsDialog',
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    showDialog: Boolean,
  },
  computed: {
    ...mapGetters('contact', ['getContact']),
    phoneNumber() {
      if (this.getContact?.phone === undefined) {
        return '';
      } else {
        return this.getContact.phone;
      }
    },
    email() {
      if (this.getContact?.email === undefined) {
        return '';
      } else {
        return this.getContact.email;
      }
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit('close-dialog');
    },
  },
  watch: {
    showDialog() {
      this.dialog = this.showDialog;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  max-width: 30%;
}

.v-dialog__container {
  display: none !important;
}

.dialog-title {
  font-size: 34px;
  line-height: 41px;
}

.card-text {
  color: #292e35;
  font-size: 16px;
  line-height: 19px;
}

.button-text {
  font-size: 16px;
  line-height: 19px;
}

.dialog-card {
  padding: 2vh 2.75vh;
}

.card-text-paragraph {
  margin-bottom: 32px;
}
</style>
