<template>
  <v-btn
    :data-qa="`filter-button-${this.name}`"
    :class="['filter-button', this.selected ? 'selected' : 'unselected']"
    @click="$emit('click')"
  >
    <slot name="icon"></slot>
    <span class="filter-label">
      {{ this.label }}
    </span>
    <span class="filter-info primary">
      {{ this.info }}
    </span>
  </v-btn>
</template>

<script>
export default {
  name: 'FilterButton',
  props: ['name', 'selected', 'label', 'info'],
};
</script>

<style lang="scss" scoped>
.filter-label {
  margin: 0 8px;
}

.filter-info {
  color: white;
  padding: 0 8px;
  border-radius: 5px;
}

.filter-button.unselected.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: #fff !important;
  color: var(--v-primary-base);

  .filter-info {
    background-color: var(--v-primary-base) !important;
    color: #fff;
  }
}

.filter-button.selected.theme--light.v-btn:not(.v-btn--icon):not(.v-btn--flat) {
  background-color: var(--v-primary-base);
  color: #fff;

  .filter-info {
    background-color: #fff !important;
    color: var(--v-primary-base);
  }
}

#selected-count {
  ::v-deep .v-chip {
    margin: 6px 6px 6px 0;
  }

  .selected-count-text {
    font-size: 16px;
    vertical-align: middle;
  }
}
</style>
