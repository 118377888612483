const getters = {
  rfidFilter: (state) => {
    return state.rfidFilter;
  },
  areFiltersOrSearchApplied: (state) => {
    let keys = Object.keys(state.rfidFilter);
    return (
      keys.length !== 0 ||
      (state.searchQuery !== '' && state.searchQuery !== null)
    );
  },
  paginationQuery: (state) => {
    return state.paginationQuery;
  },
  searchQuery: (state) => {
    return state.searchQuery;
  },
  totalRFIDs: (state) => {
    return state.totalRFIDs;
  },
  totalFilteredRFIDs: (state) => {
    return state.totalFilteredRFIDs;
  },
};

export { getters };
