const actions = {
  selectVehicles({ commit }, payload) {
    commit('ADD_SELECTED_VEHICLES', payload);
  },
  deselectVehicles({ commit }, payload) {
    commit('REMOVE_SELECTED_VEHICLES', payload);
  },
  deselectAllVehicles({ commit }) {
    commit('REMOVE_ALL_SELECTED_VEHICLES');
  },
};

export { actions };
