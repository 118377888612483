const state = {
  paginationQuery: {},
  dateToViewInvoice: '',
  statementNumber: '',
  dialogBox: {},
  billingActionInProgress: false,
  paymentClientSecret: '',
  paymentElementLoaded: false,
  paymentEnabledCurrencies: [],
};

export { state };
