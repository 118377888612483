import { state } from '@/store/billing/state';
import { actions } from '@/store/billing/actions';
import { mutations } from '@/store/billing/mutations';
import { getters } from '@/store/billing/getters';

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export { state, mutations, actions, getters };
