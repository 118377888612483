const state = {
  queue: [],
};

const mockState = function () {
  return {
    queue: [],
  };
};

export { state, mockState };
