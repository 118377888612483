import Vue from 'vue';

const mutations = {
  ADD_SELECTED_VEHICLES(state, newVehicles) {
    for (let vehicleId of Object.keys(newVehicles)) {
      Vue.set(state.selectedVehicles, vehicleId, newVehicles[vehicleId]); // instead of storing vehicle name, we will store vehicle object
    }
    state.selectedVehiclesCount = 0;
    for (let i of Object.keys(state.selectedVehicles)) {
      if (state.selectedVehicles.hasOwnProperty(i))
        ++state.selectedVehiclesCount;
    }
  },
  REMOVE_SELECTED_VEHICLES(state, vehicleIdsToRemove) {
    for (let i of Object.keys(vehicleIdsToRemove)) {
      delete state.selectedVehicles[vehicleIdsToRemove[i]];
    }
    state.selectedVehiclesCount = 0;
    for (let i of Object.keys(state.selectedVehicles)) {
      if (state.selectedVehicles.hasOwnProperty(i))
        ++state.selectedVehiclesCount;
    }
  },
  REMOVE_ALL_SELECTED_VEHICLES(state) {
    state.selectedVehicles = {};
    state.selectedVehiclesCount = 0;
  },
};

export { mutations };
